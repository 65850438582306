/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { TabContext, TabList } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, {
    FunctionComponent,
    SyntheticEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import CountryList from '../../../assets/data/country-dial-codes.json';
import { ReactComponent as AdvancedSignatureIcon } from '../../../assets/images/advanced-signature.svg';
import { ReactComponent as CMDLogo } from '../../../assets/images/cmd-logo-short.svg';
import { ReactComponent as QESVideoSignatureIcon } from '../../../assets/images/qes-video-siganture.svg';
import {
    AddSignerType,
    IdDocumentType,
    SignatureType,
    Signer,
    SignerAsOrganizationMemberPayload,
    SignerFieldsName,
    SignerPayload,
} from '../../../types/contracts';
import { ButtonVariant, OptionRadioField } from '../../../types/general';
import { FormValidationError } from '../../../utils/validations';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { Drawer } from '../Drawer';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import { languageDropdownOptions } from '../LanguageSelector';
import { LoadingCircles } from '../LoadingCircles';
import { PhoneInput } from '../PhoneInput';
import { RadioGroupWithSearch } from '../RadioGroupWithSearch';
import { RadioSelect } from '../RadioSelect';
import { SignatureAvailabilityList } from '../SignatureAvailabilityList';
import { useContractContext } from './ContractContextProvider';

interface OwnProps extends TranslationContext, ContractsContext, OrganizationsContext {
    isOpen: boolean;
    defaultSignerFields?: SignerPayload;
    onClose: () => void;
    signers: Signer[];
}

const DefaultPhone = '+351 ';

const AddSignerDrawerBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        isOpen,
        defaultSignerFields,
        onClose,
        t,
        language,
        languages,
        checkSignatureAvailability,
        validateSignerForm,
        validateSignerAsOrganizationMemberForm,
        organizationSelected,
        getOrganizationMembers,
        signers,
    } = props;

    const {
        prepareToAddSigner,
        prepareToAddSignerAsOrganizationMember,
    } = useContractContext();
    
    const defaultValues: SignerPayload = {
        [SignerFieldsName.Name]: '',
        [SignerFieldsName.Email]: '',
        [SignerFieldsName.PhoneNumber]: '',
        [SignerFieldsName.DocumentCountry]: 'PRT',
        [SignerFieldsName.DocumentType]: IdDocumentType.IDCARD,
        [SignerFieldsName.DocumentNumber]: '',
        [SignerFieldsName.Signature]: SignatureType.QESCMD,
        [SignerFieldsName.Language]: language,
    };

    const [signatureOptions, setSignatureOptions] = useState([
        {
            value: SignatureType.QESCMD, label: t('signatureOptions.QESCMD'), icon: <CMDLogo />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'), extraClasses: 'filled',
        },
        {
            value: SignatureType.QESVIDEO, label: t('signatureOptions.QESVIDEO'), icon: <QESVideoSignatureIcon />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'),
        },
        {
            value: SignatureType.AES, label: t('signatureOptions.AES'), icon: <AdvancedSignatureIcon />, disabled: false, disableMessage: t('signatureAvailability.notAvailable'),
        },
    ]);
    const [newSignerType, setNewSignerType] = useState<AddSignerType>(AddSignerType.EXTERNAL);
    const [isLoadingMembers, setIsLoadingMembers] = useState(false);
    const [memberOptions, setMemberOptions] = useState<OptionRadioField<string>[]>([]);
    const [selectedOrganizationMemberId, setSelectedOrganizationMemberId] = useState<string>('');
    const [signerFields, setSignerFields] = useState<SignerPayload>(defaultSignerFields ?? defaultValues);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [isOpenAvailabilityDrawer, setIsOpenAvailabilityDrawer] = useState(false);
    const [isCheckingSignatureTypes, setIsCheckingSignatureTypes] = useState(true);

    const documentTypeOptions = [
        { value: IdDocumentType.IDCARD, label: t('documentTypeOptions.IDCARD') },
        { value: IdDocumentType.PASSPORT, label: t('documentTypeOptions.PASSPORT') },
        { value: IdDocumentType.DRIVERLICENSE, label: t('documentTypeOptions.DRIVERLICENSE') },
        { value: IdDocumentType.UNKNOWN, label: t('documentTypeOptions.UNKNOWN') },
    ];

    useEffect(() => {
        prepareOrganizationMembers();
    }, [signers]);

    useEffect(() => {
        getSignatureAvailability();
    }, [signerFields.documentCountry, signerFields.documentType]);

    /**
     * Map Countries
     *
     * @remarks
     * Map Countries for label/value needed for the select box
     * The usage of filter function in due the list having multiple phone dial codes, but we only need the unique country for this purpose.
     */
    const mappedCountries = useMemo(() => {
        return CountryList
            .filter((country, index, self) => index === self.findIndex((c) => c.alpha3 === country.alpha3))
            .map((country) => ({
                value: country.alpha3,
                label: country.country,
            }));
    }, [CountryList]);
    
    /**
     * Get organization members
     *
     */
    const prepareOrganizationMembers = async (search: string = '') => {
        if (!organizationSelected?.organization?.id) return;
        setIsLoadingMembers(true);

        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id, search);
        
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => {
                const isDisabled = signers?.some((p) => p.signerUserId === option.userId);
                const disabledText = isDisabled ? ` ${t('contractCreate.signerAlreadyInContract')}` : '';

                return {
                    id: option.userId,
                    value: option.userId,
                    label: `${option.firstName} ${option.lastName}${disabledText}`,
                    disabled: isDisabled,

                };
            });
            setMemberOptions(membersTransformedOptions);
        }
        setIsLoadingMembers(false);
    };

    /**
     * Request signatures type availability
     *
     * @remarks
     * Request signature availability for each signature type,
     * validate if its available for user country and document type
     * and selects the first signature type radio available
     */
    const getSignatureAvailability = async () => {
        const signaturesAvailability = await Promise.all(
            signatureOptions.map((signatureOption) => {
                const payload = {
                    [SignerFieldsName.DocumentCountry]: signerFields.documentCountry,
                    [SignerFieldsName.DocumentType]: signerFields.documentType,
                    [SignerFieldsName.Signature]: signatureOption.value,
                };
                return checkSignatureAvailability(payload);
            }),
        );

        setIsCheckingSignatureTypes(false);

        const updatedSignatureAvailability = signatureOptions.map((option, index) => (
            {
                ...option,
                disabled: !signaturesAvailability[index],
            }
        ));
        setSignatureOptions(updatedSignatureAvailability);

        const firstSignatureTypeAvailable = updatedSignatureAvailability.find((option) => option.disabled === false);
        if (firstSignatureTypeAvailable) onFieldsChange(SignerFieldsName.Signature, firstSignatureTypeAvailable.value);
    };

    /**
     * Update the signer field change
     *
     * @remarks
     * Verify the field changed and update in signerFields state
     */
    const onFieldsChange = (name: string, value: string | unknown) => {
        setSignerFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /**
     * Update the signer filed on phone change
     *
     * @remarks
     * Verify the field changed and update in signerFields state
     */
    const onPhoneChange = (code: string, number: string, value: string) => {
        setSignerFields((prevFields) => ({
            ...prevFields,
            [SignerFieldsName.PhoneNumber]: value,
        }));
    };

    /**
     * Submit Add New Signer Form
     *
     * @remarks
     * Validate and submit fields
     */
    const onFormSubmitForNewSigner = (e: React.FormEvent) => {
        e.preventDefault();
        const fieldsTransformed: SignerPayload = {
            ...signerFields,
        };

        const errors = validateSignerForm({ ...signerFields });
        setErrorsForm(errors);
        if (errors?.fields) return onFailure(t('errors.fieldsError'));

        if (!errors?.fields) {
            prepareToAddSigner(fieldsTransformed, onSuccess, onFailure);
        }
    };

    /**
     * Submit Add New Signer as an Organization Member Form
     *
     * @remarks
     * Validate and submit fields
     */
    const onFormSubmitForNewSignerAsOrganizationMember = (e: React.FormEvent) => {
        e.preventDefault();
        const fieldsTransformed: SignerAsOrganizationMemberPayload = {
            userId: selectedOrganizationMemberId,
            phoneNumber: signerFields[SignerFieldsName.PhoneNumber],
            documentCountry: signerFields[SignerFieldsName.DocumentCountry],
            documentType: signerFields[SignerFieldsName.DocumentType],
            documentNumber: signerFields[SignerFieldsName.DocumentNumber],
            signatureType: signerFields[SignerFieldsName.Signature],
            language: signerFields[SignerFieldsName.Language],
        };

        const errors = validateSignerAsOrganizationMemberForm({ ...fieldsTransformed });
        setErrorsForm(errors);
        if (errors?.fields) return onFailure(t('errors.fieldsError'));

        if (!errors?.fields) {
            prepareToAddSignerAsOrganizationMember(fieldsTransformed, onSuccess, onFailure);
        }
    };

    /**
     * onSuccess callback function
     *
     * @remarks
     * Update state (and other tasks if needed) after successful post response
     */
    const onSuccess = () => {
        handleClose();
    };

    /**
     * onFailure callback function
     *
     * @remarks
     * Show toast with generic failure message
     */
    const onFailure = (errorMessage: string = t('errors.general')) => {
        toast.error(errorMessage);
    };

    /**
     * Close drawer function
     *
     * Reset signer fields with default value
     * and execute onClose parent function
     *
     */
    const handleClose = () => {
        setSignerFields(defaultValues);
        onClose();
    };

    const handleTabChange = (_: SyntheticEvent, newValue: AddSignerType) => {
        setNewSignerType(newValue);
        setErrorsForm(null);
    };

    return (
        <>
            <Drawer
                title={t('contractCreate.newSigner')}
                open={isOpen}
                handleClose={handleClose}
            >
                <TabContext value={newSignerType}>
                    <Box>
                        <TabList onChange={handleTabChange}>
                            <Tab label={t('contractCreate.externalSigner')} value={AddSignerType.EXTERNAL} />
                            <Tab label={isLoadingMembers ? <LoadingCircles size="xs" variant="secondary" /> : t('contractCreate.organizationMember')} value={AddSignerType.CURRENT_ORGANIZATION} disabled={isLoadingMembers} />
                        </TabList>
                    </Box>
                </TabContext>
                <form
                    className="form"
                    onSubmit={newSignerType === AddSignerType.CURRENT_ORGANIZATION ? onFormSubmitForNewSignerAsOrganizationMember : onFormSubmitForNewSigner}
                    data-testid="add-signer-drawer-form"
                    autoComplete="off" // Prevent browsers from saving information on this form as it contains sensitive information
                >
                    <div className="form__fields">
                        {newSignerType === AddSignerType.EXTERNAL && (
                            <>
                                <FormField
                                    name={SignerFieldsName.Name}
                                    value={signerFields[SignerFieldsName.Name]}
                                    onChange={onFieldsChange}
                                    maxLength={250}
                                    label={t('contractCreate.signer.name')}
                                    placeholder={t('contractCreate.signerPlaceholders.name')}
                                    errors={errorsForm}
                                />
                                <FormField
                                    name={SignerFieldsName.Email}
                                    value={signerFields[SignerFieldsName.Email]}
                                    onChange={onFieldsChange}
                                    maxLength={250}
                                    label={t('contractCreate.signer.email')}
                                    placeholder={t('contractCreate.signerPlaceholders.email')}
                                    errors={errorsForm}
                                />
                            </>
                        )}
                        {newSignerType === AddSignerType.CURRENT_ORGANIZATION && (
                            <RadioGroupWithSearch
                                options={memberOptions}
                                name="organization-members"
                                onChange={(_, value) => setSelectedOrganizationMemberId(value as string)}
                                value={selectedOrganizationMemberId}
                                onSearch={(search) => prepareOrganizationMembers(search)}
                            />
                        )}
                        <div data-testid="field-phone-input">
                            <label>
                                {t('contractCreate.signer.phone')}
                            </label>
                            <PhoneInput
                                name="phoneNumber"
                                id="phoneNumber"
                                onChange={onPhoneChange}
                                errors={errorsForm}
                                testId="phone-input"
                                initialValue={DefaultPhone}
                            />
                        </div>
                        <div data-testid="field-language">
                            <FormSelectField
                                name={SignerFieldsName.Language}
                                options={languageDropdownOptions({
                                    t,
                                    languages,
                                })}
                                onChange={onFieldsChange}
                                label={t('contractCreate.signer.language')}
                                errors={errorsForm}
                                value={signerFields[SignerFieldsName.Language]}
                            />
                        </div>
                        <FormSelectField
                            name={SignerFieldsName.DocumentCountry}
                            options={mappedCountries}
                            onChange={onFieldsChange}
                            label={t('contractCreate.signer.country')}
                            errors={errorsForm}
                            value={signerFields[SignerFieldsName.DocumentCountry]}
                        />
                        <FormSelectField
                            name={SignerFieldsName.DocumentType}
                            options={documentTypeOptions}
                            onChange={onFieldsChange}
                            label={t('contractCreate.signer.documentType')}
                            errors={errorsForm}
                            value={signerFields[SignerFieldsName.DocumentType]}
                        />
                        <FormField
                            name={SignerFieldsName.DocumentNumber}
                            value={signerFields[SignerFieldsName.DocumentNumber]}
                            onChange={onFieldsChange}
                            maxLength={50}
                            label={t('contractCreate.signer.documentNumber')}
                            errors={errorsForm}
                        />
                        {/* Signature Types */}
                        {isCheckingSignatureTypes && (<LoadingCircles size="xs" variant="primary" />)}
                        {!isCheckingSignatureTypes && (
                            <div data-testid="field-signature-select">
                                <RadioSelect
                                    name={SignerFieldsName.Signature}
                                    label={t('contractCreate.signer.signatureType')}
                                    extraTitleElement={<Button onClick={() => setIsOpenAvailabilityDrawer(true)}>{t('contractCreate.signer.seeAvailability')}</Button>}
                                    options={signatureOptions}
                                    value={signerFields[SignerFieldsName.Signature]}
                                    onChange={onFieldsChange}
                                />
                            </div>
                        )}
                    </div>
                    <div className="form__bottom">
                        <Button
                            extraClasses="full-width"
                            isSubmit
                            variant={ButtonVariant.Curved}
                            testId="save-btn"
                        >
                            {t('contractCreate.placeSignature')}
                        </Button>
                    </div>
                </form>
            </Drawer>
            <Drawer
                title={t('signatureAvailability.title')}
                open={isOpenAvailabilityDrawer}
                handleClose={() => setIsOpenAvailabilityDrawer(false)}
                extraClasses="full-height"
            >
                <SignatureAvailabilityList />
            </Drawer>
        </>
    );
};

export const AddSignerDrawer = withOrganizationsContext(withTranslationContext(withContractsContext(AddSignerDrawerBase)));
