/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Link } from 'react-router-dom';
import { Wallet } from '../../../types/billing';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { UsageChart } from './UsageChart';

type WalletCreditsProps = TranslationContext & {
    name: string;
    title: string;
    wallet: Wallet;
    addCreditsUrl: string;
}

export const WalletUsageCard = withTranslationContext((props: WalletCreditsProps) => {
    const {
        t,
        name,
        title,
        wallet,
        addCreditsUrl,
    } = props;

    const totalValue = wallet.creditsBalance?.amount + (wallet.captiveCreditsBalance?.amount ?? 0);
    const availableValue = wallet.creditsBalance?.amount;
    const captiveValue = wallet.captiveCreditsBalance?.amount ?? 0;

    return (
        <div className="usage-card" data-testid={`usage-card-${name}`}>
            <h2>{title}</h2>
            <div className="usage-card__info">
                <div className="usage-card__info__current-available" data-testid={`usage-card-${name}-current-available`}>
                    <span>{availableValue}</span>
                </div>
                <div className="usage-card__info__details">
                    <div className="usage-card__info__details__available">
                        {`${t('billingDashboard.available')}: ${availableValue}`}
                    </div>
                    <div className="usage-card__info__details__captive">
                        {`${t('billingDashboard.captive')}: ${captiveValue}`}
                    </div>
                </div>
            </div>
            <UsageChart total={totalValue} available={availableValue} captive={captiveValue} />
            <Link to={addCreditsUrl}>
                {t('billingDashboard.wallet.addCreditsBtn')}
            </Link>
        </div>
    );
});
