/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, ReactNode, createContext, useContext, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { LIST_LIMIT } from '../../../constants/workflows';
import { ErrorResponse } from '../../../types/errors';
import {
    WorkflowDocument,
} from '../../../types/workflows';
import { WorkflowContext as WorkflowsContext, withWorkflowContext } from '../../controllers/WorkflowContext';

export interface WorkflowContextProviderInterface {
    documents: WorkflowDocument[];
    fetchDocuments: (reset?: boolean, limit?: string) => Promise<ErrorResponse | null>;
    totalAmount?: number;
}

export const workflowContextProviderDefaultValue: WorkflowContextProviderInterface = {
    documents: [],
    fetchDocuments: async () => null,
};

export const WorkflowContext = createContext(workflowContextProviderDefaultValue);

interface OwnProps extends WorkflowsContext {
    children: ReactNode;
}

const WorkflowContextProviderComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        children,
        getDocuments,
    } = props;

    const { workflowId = '' } = useParams();

    const [documents, setDocuments] = useState<WorkflowDocument[]>([]);
    const [documentsCursor, setDocumentsCursor] = useState('');
    const [totalAmount, setTotalAmount] = useState<number>();

    const fetchDocuments = async (reset?: boolean, limit = LIST_LIMIT) => {
        const [documentList, documentListError] = await getDocuments(workflowId, reset ? '' : documentsCursor, limit);
        if (documentList) {
            setDocuments(documentList.results);
            setTotalAmount(documentList.totalAmount);
            setDocumentsCursor(documentList.cursor);
        }

        return documentListError;
    };

    const value = useMemo(() => ({
        documents,
        fetchDocuments,
        totalAmount,
    }), [documents]);

    return (
        <WorkflowContext.Provider
            value={value}
        >
            {children}
        </WorkflowContext.Provider>
    );
};

export const WorkflowContextProvider = withWorkflowContext(WorkflowContextProviderComponent);

export const useWorkflowContext = () => {
    return useContext(WorkflowContext);
};
