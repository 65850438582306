/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { ButtonVariant, KeyedObject } from '../../../types/general';
import {
    FormValidationError, FormValidatorError, validateForm, ValidationType,
} from '../../../utils/validations';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { FormField } from '../FormField';
import Modal from '../Modal';
import { UploadDocumentCard } from '../UploadDocumentCard';

interface OwnProps extends TranslationContext {
    open: boolean;
    onModalClose: () => void;
    onSubmit: (file: File, notes?: string, name?: string) => void;
}

const AddDocumentModalComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, open, onModalClose, onSubmit,
    } = props;

    const [notes, setNotes] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [nameErrors, setNameErrors] = useState<FormValidationError | null>(null);
    const [documentFile, setDocumentFile] = useState<File | null>(null);

    useEffect(() => {
        if (!open) {
            setDocumentFile(null);
            setNotes('');
        }
    }, [open]);

    const handleFileSelected = (file: File | undefined) => {
        if (!file) return;
        setDocumentFile(file);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!documentFile) return;
        
        const uploadFormErrors: KeyedObject<FormValidatorError[]> | null = validateForm({ name }, { name: { validations: [ValidationType.NotBlank] } });

        if (uploadFormErrors) {
            setNameErrors({ fields: uploadFormErrors });
            return;
        }

        onSubmit(documentFile, notes, name);
    };

    return (
        <Modal
            open={open}
            handleClose={onModalClose}
            title={t('workflows.documents.documentUpload')}
        >
            <form className="add-document-modal" onSubmit={handleSubmit}>
                <FormField
                    name="name"
                    label={`${t('workflows.documents.name')} *`}
                    onChange={(_, nameValue) => setName(nameValue)}
                    value={name}
                    errors={nameErrors}
                />
                <UploadDocumentCard
                    document={{
                        id: 1,
                        title: documentFile ? documentFile.name : t('workflows.documents.documentUploadInputTitle'),
                        description: documentFile ? t('workflows.documents.documentUploadSelected') : t('workflows.documents.documentUploadInputDescription'),
                    }}
                    onUploadFileCallback={async (file) => handleFileSelected(file)}
                    accept="application/pdf"
                />
                <p className="add-document-modal__warning">{t('workflows.documents.documentUploadWarning')}</p>
                <FormField
                    name="notes"
                    label={t('workflows.documents.notes')}
                    onChange={(_, notesValue) => setNotes(notesValue)}
                    value={notes}
                />
                <div className="form__bottom">
                    <Button
                        variant={ButtonVariant.Curved}
                        extraClasses="primary"
                        isSubmit
                        disabled={!documentFile}
                    >
                        {t('workflows.documents.uploadAction')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export const AddDocumentModal = withTranslationContext(AddDocumentModalComponent);
