/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { AppLanguage } from '../../types/preferences';
import {
    TranslationContext,
    withTranslationContext,
} from '../controllers/TranslationContext';
import { ReactComponent as GlobeIcon } from '../../assets/images/globe.svg';
import { ReactComponent as UkFlagIcon } from '../../assets/images/uk-flag.svg';
import { ReactComponent as PtFlagIcon } from '../../assets/images/pt-flag.svg';
import { RadioSelect } from './RadioSelect';

interface OwnProps extends TranslationContext { }

const renderCountryFlag = (l: AppLanguage) => {
    switch (l) {
        case AppLanguage.en:
            return <UkFlagIcon />;
        case AppLanguage.pt:
            return <PtFlagIcon />;
        default:
            return <GlobeIcon />;
    }
};

const LanguageSelectorComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        languages,
        language: languageSelected,
        changeLanguageSelected,
    } = props;

    return (
        <div className="language-selector">
            <RadioSelect
                name="select-language"
                label={t('languageAndCurrencyPreferences.selectLanguage')}
                options={languages.map((l) => ({ value: l, label: t(`language.${l}`), icon: renderCountryFlag(l) }))}
                value={languageSelected}
                onChange={(_name, value) => changeLanguageSelected(value as AppLanguage)}
            />
        </div>
    );
};

export const LanguageSelector = withTranslationContext(LanguageSelectorComponent);

type LanguageOptionsProps = Pick<TranslationContext, 't'|'languages'>;

export const languageDropdownOptions = (props: LanguageOptionsProps) => {
    const {
        t,
        languages,
    } = props;
    return languages.map((l) => ({
        label: t(`languageOptions.${l}`),
        value: l,
    }));
};
