/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export type WebsocketToken = {
    webSocketToken: string;
}

export enum ContractSocketEventType {
    ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_REJECTED = 'Esignatures.QesVideo.IdentificationVerification.Rejected',
    ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_COMPLETED = 'Esignatures.QesVideo.IdentificationVerification.Completed',
    ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_EXCEPTION = 'Esignatures.QesVideo.IdentificationVerification.Exception',
    ESIGNATURES_QESVIDEO_DOCUMENT_SIGNED = 'Esignatures.QesVideo.Document.Signed',
    ESIGNATURES_QESVIDEO_DOCUMENT_EXCEPTION = 'Esignatures.QesVideo.Document.Exception',
    ESIGNATURES_LOCK_LOCKED = 'Esignatures.Lock.Locked',
    ESIGNATURES_LOCK_GRANTED = 'Esignatures.Lock.Granted',
    ESIGNATURES_LOCK_REFRESH = 'Esignatures.Lock.Refresh',
    ESIGNATURES_LOCK_FREE = 'Esignatures.Lock.Free',
    ESIGNATURES_LOCK_REQUEST = 'Esignatures.Lock.Request',
}

export type ContractSignatureUpdate = {
    type: ContractSocketEventType;
    payload: unknown;
};
