/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    Suspense,
    useEffect,
    useState,
} from 'react';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import HasPermission from '../../elements/HasPermission';
import { Permissions } from '../../../types/permissions';
import { useContractContext } from '../../elements/contracts/ContractContextProvider';
import { withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { LoadingScreen } from '../LoadingScreen';
import { ReactComponent as ContractIcon } from '../../../assets/images/businesses.svg';
import { ErrorResponse } from '../../../types/errors';
import { FormSelectField } from '../../elements/FormSelectField';
import { UploadDocumentCard } from '../../elements/UploadDocumentCard';

interface OwnProps extends TranslationContext {
    onFileSelected: (documentFile: File | undefined, contractTypeId: number) => void;
    isSubmitting: boolean;
}

const UploadContractScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        onFileSelected,
        isSubmitting,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(null);
    const [selectedContractType, setSelectedContractType] = useState<number | undefined>();

    const { contractTypes, getContractTypesList } = useContractContext();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (contractTypes.length > 0) setSelectedContractType(contractTypes[0].id);
    }, [contractTypes]);

    useEffect(() => {
        if (errorResponse) throw errorResponse;
    }, [errorResponse]);
    
    const init = async () => {
        const contractTypesResponse = await getContractTypesList();
        if (contractTypesResponse) {
            setErrorResponse(contractTypesResponse);
            setIsLoading(false);
            return;
        }

        setErrorResponse(null);
        setIsLoading(false);
    };

    /**
     * Map Contract Types
     *
     * @remarks
     * Map Contract types for label/value needed for the select box
     */
    const mappedContractTypes = () => {
        return contractTypes.map((item) => ({
            value: item.id,
            label: item.name,
        }));
    };
    return (
        <div data-testid="contracts-create-content">
            <Suspense fallback={<LoadingScreen />}>
                <HasPermission permissions={[Permissions.MANAGE_CONTRACT, Permissions.MANAGE_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]}>
                    <DefaultLayout>
                        <div className="contract-screen contract-screen--create light-gradient-bg information-page">
                            {(isLoading || isSubmitting) && (
                                <LoadingScreen />
                            )}
                            {!isSubmitting && selectedContractType && (
                                <div className="information-page__content-wrap">
                                    <div className="information-page__content content-center">
                                        <div className="icon-wrap">
                                            <ContractIcon />
                                        </div>
                                        <h2>{t('contractCreate.fileUploadTitle')}</h2>
                                        <FormSelectField
                                            name="contractTypeId"
                                            options={mappedContractTypes() ?? []}
                                            label={t('contractCreate.contractType')}
                                            onChange={(_, value) => setSelectedContractType(Number(value))}
                                            value={selectedContractType}
                                        />
                                        <UploadDocumentCard
                                            document={{
                                                id: 1,
                                                title: t('contractCreate.fileUploadAreaTitle'),
                                                description: t('contractCreate.fileUploadAreaDescription'),
                                            }}
                                            onUploadFileCallback={async (documentFile) => onFileSelected(documentFile, selectedContractType)}
                                            accept="application/pdf"
                                        />
                                        <p>{t('contractCreate.fileUploadNote')}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </DefaultLayout>
                </HasPermission>
            </Suspense>
        </div>
    );
};

export const UploadContractScreen = withTranslationContext(withOrganizationsContext(UploadContractScreenComponent));
