/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { debounce, isNumber } from 'lodash';
import {
    FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../constants/routes';
import { CreditAmount } from '../../../types/billing';
import { ErrorResponse } from '../../../types/errors';
import { ButtonVariant, FormFieldType } from '../../../types/general';
import { FormValidationError } from '../../../utils/validations';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { CartSummary } from '../../elements/billing/CartSummary';
import Button from '../../elements/Button';
import { FormField } from '../../elements/FormField';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = TranslationContext & OrganizationsContext & BillingContext;

const PurchaseCreditsScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        tWithJSX,
        setWalletInfo,
        billingInfo,
        validateCreditsInput,
        changeCreditsToPurchase,
        updateBasket,
        createNewBasket,
        getUsersWallet,
    } = props;

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingWallet, setIsLoadingWallet] = useState(false);
    const [error, setError] = useState<ErrorResponse | null>(null);
    const [creditInputError, setCreditInputError] = useState<FormValidationError | null>(null);

    useEffect(() => {
        document.title = preparePageTitle(t('purchaseCredits.title'));
        init();
    }, []);

    useEffect(() => {
        if (error) throw error;
    }, [error]);

    useEffect(() => {
        if (isNumber(billingInfo.credits) && !creditInputError && billingInfo.basket?.credits?.amount !== billingInfo.credits) {
            debounceUpdateCredits(billingInfo.credits);
        }
    }, [billingInfo.credits]);

    const debounceUpdateCredits = useCallback(debounce((value: number) => {
        if (!billingInfo.wallet) {
            return;
        }

        setIsLoading(true);
        updateBasket(String(billingInfo.wallet.id), { creditAmount: Number(value) }).finally(() => {
            setIsLoading(false);
        });
    }, 500), []);

    const init = async () => {
        setIsLoadingWallet(true);
        const [walletData, walletError] = await getUsersWallet();
        setIsLoadingWallet(false);

        if (walletData) {
            setWalletInfo(walletData);
            createNewBasket(String(walletData.id));
        }

        if (walletError) {
            setError(walletError);
        }
    };

    const handleCreditInput = (value: string) => {
        const numberValue = Math.round(Number(value));

        if (value === '' || numberValue === 0) {
            setCreditInputError(null);
        } else {
            setCreditInputError(validateCreditsInput(numberValue));
        }
        changeCreditsToPurchase(numberValue);
    };

    const renderGoBackButton = (
        <Button
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(AppRoute.AccountWallet)}
        >
            {t('purchaseCredits.goBack')}
        </Button>
    );

    const renderNextStepButton = (
        <Button
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            onClick={() => navigate(AppRoute.PurchaseCreditsBillingInformation)}
        >
            {t('purchaseCredits.nextStep')}
        </Button>
    );
    
    return (
        <DefaultLayout>
            {!isLoadingWallet && (
                <div className="purchase-credits-screen">
                    <div className="purchase-credits-screen__container">
                        <div className="purchase-credits-screen__container__input-form-group">
                            <h1>{t('purchaseCredits.title')}</h1>
                            <p>{tWithJSX('purchaseCredits.descriptionPersonalWallet')}</p>
                            <div className="purchase-credits-screen__container__input-form-group">
                                <FormField
                                    name="credits"
                                    value={billingInfo.credits}
                                    placeholder={t('subscribeFlow.addCreditsStep.creditsPlaceholder')}
                                    onChange={(_, v) => handleCreditInput(v)}
                                    type={FormFieldType.Number}
                                    errors={creditInputError}
                                />
                                <div className="buttons-container">
                                    {Object.values(CreditAmount).map((c) => (
                                        <Button key={c} onClick={() => handleCreditInput(c)}>{c}</Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <CartSummary isLoading={isLoading} />
                    <footer>
                        {renderGoBackButton}
                        {renderNextStepButton}
                    </footer>
                </div>
            )}
        </DefaultLayout>
    );
};

export const PurchaseCreditsScreen = withBillingContext(withOrganizationsContext(withTranslationContext(PurchaseCreditsScreenComponent)));
