/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/closeBtn.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';
import { BannerType } from '../../types/general';

interface OwnProps {
    type: BannerType;
    title?: string;
    children: string | ReactNode;
    onClose?: () => void;
}

type Props = OwnProps;

const Banner: FunctionComponent<Props> = (props: Props) => {
    const {
        type,
        title,
        children,
        onClose,
    } = props;

    return (
        <div
            className={classNames('banner',
                { 'banner--info': type === BannerType.INFO })
            }
            data-testid="banner"
        >
            <div className="banner__header">
                <div className="banner__header__icon">
                    {type === BannerType.INFO && (<InfoIcon />)}
                </div>
                <div className="banner__header__content">
                    <h3 className="banner__header__content__title">{title}</h3>
                    {onClose && (
                        <button
                            type="button"
                            className="banner__header__content__close-btn"
                            data-testid="banner-close-btn"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </button>
                    )}
                </div>
            </div>
            <div className="banner__content">
                {children}
            </div>
        </div>
    );
};

export { Banner };
