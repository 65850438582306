/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Chip } from '@mui/material';
import { isEqual, uniqBy } from 'lodash';
import {
    FunctionComponent,
    ReactNode,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ReactComponent as RemoveIcon } from '../../../assets/images/closeBtn.svg';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';
import { AppRoute } from '../../../constants/routes';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import {
    Contract, ContractsFilterParams, ContractState, ContractType,
} from '../../../types/contracts';
import {
    ButtonVariant, ListResponse, OptionCheckboxGroupField, OrderOptions,
} from '../../../types/general';
import { Permissions } from '../../../types/permissions';
import { objectToParams, paramsToObject } from '../../../utils/misc';
import { preparePageTitle } from '../../../utils/route';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Button } from '../../elements/Button';
import ContractCard from '../../elements/contracts/ContractCard';
import { ContractsFiltersForm } from '../../elements/contracts/ContractsFiltersForm';
import { Drawer } from '../../elements/Drawer';
import { FloatingAddAction } from '../../elements/FloatingAddAction';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { Member } from '../../../types/members';

type OwnProps = TranslationContext & ContractsContext & OrganizationsContext;

const defaultFilters: ContractsFilterParams = {
    _cursor: '',
    _limit: '',
    _sort: undefined,
    externalId: '',
    contractStatus: [],
    contractTypeIds: [],
    signerName: '',
    pendingSignature: undefined,
    ownerIds: [],
};

const initialFilters: ContractsFilterParams = {
    ...defaultFilters,
    _sort: OrderOptions.DESCENDING,
    contractStatus: [ContractState.DRAFT, ContractState.SIGNING],
};

export const ContractsScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
        getContracts,
        getContractTypes,
        getContractType,
        getOrganizationMembers,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();
    
    const userHasPermissions = useUserHasPermission([Permissions.VIEW_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ORGANIZATION_CONTRACTS, Permissions.VIEW_ALL_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]);

    const [contracts, setContracts] = useState<Contract[]>([]);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState<ContractsFilterParams>(initialFilters);
    const [contractTypesOptions, setContractTypesOptions] = useState<ListResponse<ContractType>>({ results: [], cursor: '' });
    const [isLoadingMembers, setIsLoadingMembers] = useState(true);
    const [memberOptions, setMemberOptions] = useState<OptionCheckboxGroupField[]>([]);
    const [organizationMembersToTranslateChips, setOrganizationMembersToTranslateChips] = useState<Member[]>([]);

    useEffect(() => {
        document.title = preparePageTitle(t('contracts.title'));
        
        const filtersFromParams: Partial<ContractsFilterParams> = {
            ...defaultFilters,
            ...paramsToObject(searchParams, defaultFilters),
        };
        
        // check if the sort option is valid
        if (!Object.values(OrderOptions).includes(filtersFromParams._sort as OrderOptions)) {
            filtersFromParams._sort = initialFilters._sort;
        }

        // check if contractStatus is valid
        if (filtersFromParams.contractStatus && Array.isArray(filtersFromParams.contractStatus)) {
            if (filtersFromParams.contractStatus.length === 0) {
                filtersFromParams.contractStatus = initialFilters.contractStatus;
            } else {
                filtersFromParams.contractStatus = filtersFromParams.contractStatus.filter((status) => Object.values(ContractState).includes(status));
            }
        }
        setFilters((prev) => ({ ...prev, ...filtersFromParams }));
        getContractsList(filtersFromParams);
        prepareOrganizationMembers();
        prepareContractTypes();
    }, []);

    useEffect(() => {
        const { _cursor, ...filtersWithoutCursor } = filters;

        setSearchParams(objectToParams(filtersWithoutCursor));
    }, [filters]);

    useEffect(() => {
        if (filters.contractTypeIds && filters.contractTypeIds.length > 0 && contractTypesOptions.results.length > 0) {
            // check if there are contract types that are in the URL but aren't in the list
            const unmatchedContractTypes = filters.contractTypeIds.filter((typeId) => !contractTypesOptions.results.find((type) => String(type.id) === typeId));

            Promise.allSettled(unmatchedContractTypes.map((typeId) => fetchUnmatchedContractType(typeId)));
        }
    }, [filters, contractTypesOptions]);

    useEffect(() => {
        if (filters.ownerIds && filters.ownerIds.length > 0 && organizationMembersToTranslateChips.length > 0) {
            // check if there are organizationMemberIds in the URL but aren't in the list
            const unmatchedOrganizationMembers = filters.ownerIds.filter((id) => !organizationMembersToTranslateChips.find((item) => item.userId === id));
            
            if (unmatchedOrganizationMembers.length > 0) {
                // filter out the members in the URL that aren't in the list
                setFilters((prev) => ({
                    ...prev,
                    ownerIds: prev.ownerIds?.filter((id) => !unmatchedOrganizationMembers.includes(id)) ?? [],
                }));
            }
        }
    }, [filters.ownerIds, organizationMembersToTranslateChips]);
    
    const appliedFilters: string[] = useMemo(() => {
        return Object.keys(filters)
            .filter((key) => !key.startsWith('_')
        && filters[key as keyof ContractsFilterParams] !== ''
        && filters[key as keyof ContractsFilterParams] !== undefined);
    }, [filters]);
    
    const prepareContractTypes = async () => {
        const contractTypesData = await getContractTypes(contractTypesOptions.cursor);

        setContractTypesOptions({
            results: uniqBy([...contractTypesOptions.results, ...contractTypesData.results], 'id'),
            cursor: contractTypesData.cursor,
        });
    };

    const numberOfAppliedFilters: number = useMemo(() => {
        let filtersCount = 0;

        Object.keys(filters).forEach((key) => {
            if (key.startsWith('_') || filters[key as keyof ContractsFilterParams] === ''
             || (Array.isArray(filters[key as keyof ContractsFilterParams]) && (filters[key as keyof ContractsFilterParams] as Array<unknown>).length === 0)) {
                return;
            }

            if (Array.isArray(filters[key as keyof ContractsFilterParams])) {
                filtersCount += (filters[key as keyof ContractsFilterParams] as Array<unknown>).length ?? 0;
            } else {
                filtersCount++;
            }
        });

        return filtersCount;
    }, [appliedFilters]);

    const fetchUnmatchedContractType = async (typeId: string) => {
        const numberTypeId = Number(typeId);

        if (!isNaN(numberTypeId)) {
            // try to find unmatched contract-type by id
            const [contractType] = await getContractType(numberTypeId);

            if (contractType) {
                setContractTypesOptions((prev) => ({
                    results: uniqBy([...prev.results, contractType], 'id'),
                    cursor: prev.cursor,
                }));
                return;
            }
        }

        // remove unmatched contract-type from the list
        setFilters((prev) => ({
            ...prev,
            contractTypeIds: prev.contractTypeIds?.filter((id) => id !== typeId) ?? [],
        }));
    };

    const getAppliedChipDisplayValue = (key: keyof ContractsFilterParams, displayValue?: string | ReactNode) => {
        let filterChipLabel = '';
        const filterChipValue = displayValue ?? filters[key];

        switch (key) {
            case 'contractTypeIds':
                filterChipLabel = t('contracts.list.filters.contractType');
                break;
            case 'pendingSignature':
                filterChipLabel = t('contracts.list.filters.requiresMySignature');
                break;
            default:
                filterChipLabel = t(`contracts.list.filters.${key}`);
        }

        if (key === 'pendingSignature') {
            return filterChipLabel;
        }

        return <>{filterChipLabel}: {filterChipValue}</>;
    };

    /**
     * Get organization members
     *
     */
    const prepareOrganizationMembers = async (search: string = '') => {
        if (!organizationSelected?.organization?.id) return;
        setIsLoadingMembers(true);

        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id, search);
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => (
                {
                    id: option.userId,
                    value: false,
                    label: `${option.firstName} ${option.lastName}`,

                }));
            setMemberOptions(membersTransformedOptions);
            if (search === '') {
                setOrganizationMembersToTranslateChips(membersData);
            }
        }
        setIsLoadingMembers(false);
    };

    const getContractsList = async (submittedFilters: ContractsFilterParams) => {
        setIsLoading(true);

        const { _cursor, ...appliedFiltersWithoutCursor } = submittedFilters;
        const { _cursor: _c, ...existingFiltersWithoutCursor } = filters;

        const filtersToApply = isEqual(appliedFiltersWithoutCursor, existingFiltersWithoutCursor) ? submittedFilters : { ...submittedFilters, _cursor: '' };

        const contractsData = await getContracts(filtersToApply);

        setContracts((prev) => (filtersToApply._cursor ? [...prev, ...contractsData.results] : [...contractsData.results]));
        setFilters({
            ...filtersToApply,
            _cursor: contractsData.cursor,
        });

        setIsLoading(false);
    };

    const applyFilters = (filtersToApply: ContractsFilterParams) => {
        setIsLoading(true);
        setContracts([]);
        setIsOpenFilters(false);
        getContractsList({ ...filtersToApply, _cursor: '' });
    };

    const cleanAllFilters = () => {
        setIsOpenFilters(false);
        setFilters(defaultFilters);
        getContractsList(defaultFilters);
    };
    
    const removeFilter = (keyToRemove: keyof ContractsFilterParams) => {
        const newFilters = {
            ...filters,
            [keyToRemove]: defaultFilters[keyToRemove],
        };
        setFilters({ ...newFilters });
        getContractsList(newFilters);
    };

    const removeContractStateFilter = (state: ContractState) => {
        const newFilters = {
            ...filters,
            contractStatus: filters.contractStatus?.filter((status) => status !== state) ?? [],
        };
        setFilters({ ...newFilters });
        getContractsList(newFilters);
    };

    const removeContractTypeFilter = (id: number | string) => {
        const newFilters = {
            ...filters,
            contractTypeIds: filters.contractTypeIds?.filter((type) => type !== id) ?? [],
        };
        setFilters({ ...newFilters });
        getContractsList(newFilters);
    };

    const removeOwnerIdsFilter = (idToRemove: string) => {
        const newFilters: ContractsFilterParams = {
            ...filters,
            ownerIds: filters.ownerIds?.filter((id) => id !== idToRemove) ?? [],
        };
        setFilters(newFilters);
        getContractsList(newFilters);
    };
    return (
        <DefaultLayout>
            <div className="contract-screen contract-screen--list" data-testid="contracts-screen">
                <div className="contract-screen__header">
                    <h1>{t('contracts.title')}</h1>
                    {userHasPermissions && (
                        <Link to={AppRoute.CreateContract} state={{ from: AppRoute.Contracts }}>
                            <Button
                                variant={ButtonVariant.Curved}
                                extraClasses="large-add-btn shorter-btn"
                                testId="large-add-btn"
                            >
                                {t('contracts.createBtn')}
                            </Button>
                        </Link>
                    )}
                    <Button
                        extraClasses="circle-btn filters-trigger-btn"
                        onClick={() => setIsOpenFilters(true)}
                        testId="mobile-filters-trigger-btn"
                    >
                        <FiltersIcon />
                    </Button>
                </div>
                {appliedFilters.length > 0 && (
                    <div className="contract-screen__applied-filters" data-testid="applied-filters">
                        <div className="contract-screen__applied-filters__header">
                            {`${numberOfAppliedFilters} ${t(`contracts.list.filters.${numberOfAppliedFilters === 1 ? 'filterApplied' : 'filtersApplied'}`)}:`}
                            <Button
                                onClick={cleanAllFilters}
                                testId="large-clean-btn"
                            >
                                {t('contracts.list.filters.cleanAll')}
                            </Button>
                        </div>
                        <div className="contract-screen__applied-filters__list" data-testid="applied-filters-list">
                            {appliedFilters.map((appliedFilter) => {
                                if (appliedFilter === 'contractStatus') {
                                    return filters?.contractStatus?.map((state) => (
                                        <Chip
                                            key={`${appliedFilter}-${state}`}
                                            className="contract-screen__applied-filters__list__item"
                                            label={getAppliedChipDisplayValue(appliedFilter as keyof ContractsFilterParams, t(`contracts.list.filters.${appliedFilter}Options.${state}`))}
                                            onDelete={() => removeContractStateFilter(state)}
                                            deleteIcon={<RemoveIcon />}
                                        />
                                    )) ?? null;
                                }

                                if (appliedFilter === 'contractTypeIds') {
                                    return filters?.contractTypeIds?.map((typeId) => {
                                        const contractType = contractTypesOptions.results.find((type) => String(type.id) === typeId);

                                        return (
                                            <Chip
                                                key={`${appliedFilter}-${typeId}`}
                                                className="contract-screen__applied-filters__list__item"
                                                label={getAppliedChipDisplayValue(appliedFilter as keyof ContractsFilterParams, contractType?.name ?? <LoadingCircles size="xs" variant="secondary" />)}
                                                onDelete={() => removeContractTypeFilter(typeId)}
                                                deleteIcon={<RemoveIcon />}
                                            />
                                        );
                                    }) ?? null;
                                }
                                if (appliedFilter === 'ownerIds') {
                                    return filters.ownerIds?.map((id) => {
                                        const member = organizationMembersToTranslateChips.find((item) => item.userId === id);

                                        return (
                                            <Chip
                                                key={`${appliedFilter}-${id}`}
                                                className="contract-screen__applied-filters__list__item"
                                                label={getAppliedChipDisplayValue(appliedFilter as keyof ContractsFilterParams, member?.fullName ?? <LoadingCircles size="xs" variant="secondary" />)}
                                                onDelete={() => removeOwnerIdsFilter(id)}
                                                deleteIcon={<RemoveIcon />}
                                            />
                                        );
                                    });
                                }

                                return (
                                    <Chip
                                        key={appliedFilter}
                                        className="contract-screen__applied-filters__list__item"
                                        label={getAppliedChipDisplayValue(appliedFilter as keyof ContractsFilterParams)}
                                        onDelete={() => removeFilter(appliedFilter as keyof ContractsFilterParams)}
                                        deleteIcon={<RemoveIcon />}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className="contract-screen__content">
                    <div className="contract-screen__content__large-filters" data-testid="filters-sidebar">
                        <ContractsFiltersForm
                            currentFilters={filters}
                            contractTypeOptionsData={contractTypesOptions}
                            filterMemberOptions={prepareOrganizationMembers}
                            filteredMemberOptions={memberOptions}
                            isLoadingMembers={isLoadingMembers}
                            loadContractTypes={prepareContractTypes}
                            onSubmit={applyFilters}
                            onClean={cleanAllFilters}
                        />
                    </div>
                    <div className="contract-screen__content__list">
                        {!isLoading && contracts.length === 0 && (
                            <p className="contract-screen__content__list__empty-list">{t('contracts.noResults')}</p>
                        )}
                        {isLoading && <LoadingCircles size="m" variant="primary" />}
                        <InfiniteScrollWrapper
                            hasMore={!!filters._cursor}
                            requestMore={() => getContractsList(filters)}
                        >
                            {contracts.map((contract) => (
                                <ContractCard key={contract.id} contract={contract} />
                            ))}
                        </InfiniteScrollWrapper>
                    </div>
                    {userHasPermissions && (
                        <FloatingAddAction type="link" to={AppRoute.CreateContract} state={{ from: AppRoute.Contracts }} testId="add-btn" />
                    )}
                </div>
            </div>
            <Drawer
                open={isOpenFilters}
                handleClose={() => setIsOpenFilters(false)}
                title={t('general.filter')}
                extraClasses="filters-drawer"
                testId="filters-drawer"
            >
                <ContractsFiltersForm
                    currentFilters={filters}
                    contractTypeOptionsData={contractTypesOptions}
                    filterMemberOptions={prepareOrganizationMembers}
                    filteredMemberOptions={memberOptions}
                    isLoadingMembers={isLoadingMembers}
                    loadContractTypes={prepareContractTypes}
                    onSubmit={applyFilters}
                    onClean={cleanAllFilters}
                />
            </Drawer>
        </DefaultLayout>
    );
};

export const ContractsScreen = withTranslationContext(withOrganizationsContext((withContractsContext(ContractsScreenComponent))));
