/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Checkbox,
    FormControlLabel,
    FormGroup,
} from '@mui/material';

import { ReactNode } from 'react';
import classNames from 'classnames';
import { OptionCheckboxGroupField } from '../../types/general';
import { LoadingCircles } from './LoadingCircles';
import { FormField } from './FormField';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

type CheckboxAttributes = Partial<Pick<HTMLInputElement, 'required'>>

interface OwnProps extends CheckboxAttributes, TranslationContext {
    name: string;
    extraTitleElement?: ReactNode;
    label?: string;
    options: OptionCheckboxGroupField[];
    onChange: (id: number | string, checked: boolean) => void;
    onSearch: (key: string) => void;
    disabled?: boolean;
    disabledText?: string;
    isLoading?: boolean;
    searchPlaceholder?: string;
}

const CheckboxGroupWithSearchBase = (props: OwnProps) => {
    const {
        t,
        name,
        extraTitleElement,
        label,
        options,
        onChange,
        onSearch,
        required,
        disabled = false,
        disabledText,
        isLoading = false,
        searchPlaceholder,
    } = props;
    return (
        <div className="checkbox-group" data-testid={`checkbox-group-${name}-container`}>
            <div className="checkbox-group__top" data-testid={`checkbox-group-${name}-top`}>
                {label && (
                    <label htmlFor={name} className={`checkbox-group-label ${required && 'required'}`}>
                        {label}
                    </label>
                )}
                {extraTitleElement}
            </div>
            <FormField
                name="checkbox-group__search"
                placeholder={searchPlaceholder ?? t('general.search')}
                onChange={(_, value) => onSearch(value)}
                extraClasses="checkbox-group__search bg-gray-filled"
            />
            {isLoading && <LoadingCircles size="xs" variant="primary" />}
            {!isLoading && (
                <>
                    <FormGroup data-testid={`checkbox-group-${name}`}>
                        {options.map((option) => (
                            <div
                                key={option.id}
                                className={classNames(`checkbox-group__item ${option.extraClasses}`)}
                            >
                                <FormControlLabel
                                    value={option.disabled || disabled ? 'disabled' : option.id}
                                    control={(
                                        <Checkbox
                                            data-testid={`checkbox-${option.label}`}
                                            value={option.value}
                                            checked={option.value}
                                            onChange={() => onChange(option.id, !option.value)}
                                            name={option.label}
                                        />
                                    )}
                                    label={option.label}
                                    disabled={option.disabled || disabled}
                                />
                            </div>
                        ))}
                    </FormGroup>
                    {disabled && disabledText && (<span className="disable-warning">{disabledText}</span>)}
                </>
            )}
        </div>
    );
};

export const CheckboxGroupWithSearch = withTranslationContext(CheckboxGroupWithSearchBase);
