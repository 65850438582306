/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import {
    ReactComponent as HelpIcon,
} from '../../../assets/images/help_navbar.svg';
import { MenuListOptions } from '../../../types/general';
import { ButtonWithMenuList } from '../ButtonWithMenuList';
import { AppRoute } from '../../../constants/routes';
import { Tooltip } from '../Tooltip';
import { TUTORIALS_URL } from '../../../settings';

interface OwnProps extends TranslationContext { }

const HelpNavbarElementBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t } = props;
    const navigate = useNavigate();

    const options: MenuListOptions[] = [
        {
            action: () => window.open(TUTORIALS_URL, '_blank', 'noreferrer noopener')?.focus(),
            value: t('help.options.tutorials'),
            testId: 'tutorials-item',
        },
        {
            action: () => navigate(AppRoute.NewSupportTicket, {
                state: {
                    referrer: window.location.href,
                    returnTo: window.location.pathname,
                },
            }),
            value: t('help.options.newSupportTicket'),
            testId: 'new-support-ticket-item',
        },
    ];

    return (
        <ButtonWithMenuList
            options={options}
            icon={(
                <Tooltip title={t('help.navbarIconTitle')} placement="bottom">
                    <HelpIcon />
                </Tooltip>
            )}
            testId="help-btn"
            title={t('help.navbarIconTitle')}
        />
    );
};

export const HelpNavbarElement = withTranslationContext(HelpNavbarElementBase);
