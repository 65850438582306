/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import axios, { AxiosError } from 'axios';
import { isArray, isError } from 'lodash';
import {
    ApiFieldErrors,
    ErrorCode,
    ErrorFormat,
    ErrorResponse,
    FunctionalError,
} from '../types/errors';

export const httpErrorHandler = (error: unknown): ErrorResponse => {
    const errors: FunctionalError[] = [];
    if (error === null || !isError(error)) {
        errors.push(new FunctionalError('Not an Error Object', ErrorCode.GENERAL));

        return {
            errors,
        };
    }
    
    if (!axios.isAxiosError(error)) {
        errors.push(new FunctionalError(error.message, ErrorCode.GENERAL));

        return {
            errors,
        };
    }

    const { response } = error;

    // handle network errors
    if (error.code === AxiosError.ERR_NETWORK) {
        errors.push(new FunctionalError(error.message, ErrorCode.ERR_NETWORK));

        return {
            errors,
        };
    }
    
    if (error.code === AxiosError.ERR_CANCELED) {
        errors.push(new FunctionalError(error.message, ErrorCode.ERR_CANCELED));

        return {
            errors,
        };
    }

    if (!response) {
        return {
            errors,
        };
    }
    const statusCode = mapHttpResponseCodeToLocalErrorCode(response.status);

    // Bad request with fields with errors
    if ((error.code === AxiosError.ERR_BAD_REQUEST || statusCode === ErrorCode.BAD_REQUEST) && response.data?.fields) {
        const fieldsWithErrors = response.data as ApiFieldErrors;
        return {
            errors,
            fields: fieldsWithErrors.fields,
        };
    }

    if (response.data?.errors && isArray(response.data.errors) && response.data.errors.length > 0) {
        // handle custom errors from the API
        response.data.errors.forEach((e: ErrorFormat) => {
            if (e.errorCode !== null) {
                errors.push(new FunctionalError(e.message ?? error.message, e.errorCode));
            }
        });
        return {
            errors,
        };
    }

    // handle generic errors based on the status code
    errors.push(new FunctionalError(error.message, statusCode));

    return {
        errors,
    };
};

export const mapHttpResponseCodeToLocalErrorCode = (statusCode: string | number) => {
    const code = String(statusCode);

    switch (code) {
        case '400':
            return ErrorCode.BAD_REQUEST;
        case '401':
            return ErrorCode.UNAUTHORIZED;
        case '403':
            return ErrorCode.FORBIDDEN;
        case '404':
            return ErrorCode.ENTITY_NOT_FOUND;
        case '413':
            return ErrorCode.PAYLOAD_TOO_LARGE;
        case '429':
            return ErrorCode.TOO_MANY_REQUESTS;
        case '500':
            return ErrorCode.INTERNAL_SERVER_ERROR;
        default:
            return ErrorCode.GENERAL;
    }
};
