/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import { DataOrError, ErrorResponse } from '../../types/errors';
import {
    GroupedRolePermissions,
    Role,
    RoleMappingPayload,
    RolePayload,
    UpdateRolePayload,
} from '../../types/roles';

import { FormValidationError } from '../../utils/validations';
import { ListResponse } from '../../types/general';
import { RolesController } from './RolesController';

export interface RolesContext {
    submitNewOrganizationRole(payload: RolePayload): Promise<DataOrError<Role, ErrorResponse>>;
    assignRoleMapping(roleId: number, payload: RoleMappingPayload): Promise<DataOrError<undefined, ErrorResponse>>;
    getRole(roleId: number): Promise<Role | null>;
    getRoles(organizationId: number): Promise<ListResponse<Role>>;
    deleteRole(roleId: number): Promise<undefined | ErrorResponse>;
    updateRole(roleId: number, payload: UpdateRolePayload): Promise<DataOrError<Role, ErrorResponse>>;
    validateCreateOrUpdateRoleForm(fields: UpdateRolePayload): FormValidationError | null;
    getGroupedPermissions(): Promise<DataOrError<GroupedRolePermissions, ErrorResponse>>;
}

export const rolesContextDefaultValue: RolesContext = {
    submitNewOrganizationRole: async () => Promise.resolve([Object.create({}), null]),
    assignRoleMapping: async () => [undefined, null],
    getRole: async () => null,
    getRoles: async () => ({ cursor: '', results: [] }),
    deleteRole: async () => undefined,
    updateRole: async () => [Object.create({}), null],
    getGroupedPermissions: async () => [[], null],
    validateCreateOrUpdateRoleForm: () => null,
};

const rolesContextInstance = createContext<RolesContext>(rolesContextDefaultValue);

export const RolesContextProvider = rolesContextInstance.Provider;
export const RolesContextConsumer = rolesContextInstance.Consumer;

export const withRolesContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof RolesContext>> => (props) => (
    <RolesController>
        <RolesContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </RolesContextConsumer>
    </RolesController>
);
