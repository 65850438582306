/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNumber } from 'lodash';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { ButtonVariant } from '../../../types/general';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import Button from '../Button';
import {
    Participant, ParticipantMemberPayload, ParticipantPayload, ParticipantPermissionsList, ResourcePermissionsType, WorkflowParticipantRole,
} from '../../../types/workflows';
import { ParticipantCard } from './ParticipantCard';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { AddParticipantModal } from './AddParticipantModal';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';
import { LoadingCircles } from '../LoadingCircles';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { Permissions } from '../../../types/permissions';
import { Tooltip } from '../Tooltip';

type OwnProps = TranslationContext & WorkflowContext

const ParticipantListComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getWorkflowParticipants,
        addNewParticipant,
        addOrganizationMemberAsParticipant,
        getWorkflowParticipantPermissions,
    } = props;

    const { workflowId = '' } = useParams();
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const canManage = useUserHasPermission([Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]);

    const [participants, setParticipants] = useState<Participant[]>([]);
    const [cursor, setCursor] = useState('');
    const [isModalAddingNewParticipantOpen, setIsModalAddingNewParticipantOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [participantPermissions, setParticipantPermissions] = useState<ParticipantPermissionsList | null>(null);

    const canManageParticipant = useMemo(() => {
        if (canManage) {
            return true;
        }

        if (participantPermissions?.transactionPermissions.some((e) => e === ResourcePermissionsType.MANAGE)) {
            return true;
        }

        return false;
    }, [canManage, participantPermissions]);

    const canTransferOwner = useMemo(() => {
        const ownerParticipant = participants.find((p) => p.participantRole === WorkflowParticipantRole.TRANSACTION_OWNER);

        if (ownerParticipant?.userId === user?.id) return true;

        return false;
    }, [user, participants]);

    useEffect(() => {
        fetchParticipants(true);
    }, []);

    const fetchParticipants = async (reset?: boolean) => {
        setIsLoading(true);
        const [participantsData] = await getWorkflowParticipants(workflowId, reset ? '' : cursor, '5');
        if (participantsData) {
            if (reset) {
                setParticipants([...participantsData.results || []]);
            } else {
                setParticipants((prev) => [...prev, ...participantsData.results || []]);
            }

            setCursor(participantsData.cursor);
        }

        getWorkflowParticipantPermissions(workflowId).then((permissionsResponse) => {
            const [permissions] = permissionsResponse;

            if (!permissions) {
                return;
            }

            setParticipantPermissions(permissions);
        });
        setIsLoading(false);
    };

    const handleRemoveParticipant = (participantId: number) => {
        const participantToRemove = participants.find((p) => p.id === participantId);

        if (!participantToRemove) return;

        setParticipants((prev) => [...prev].filter((p) => p.id !== participantId));
    };

    const handleUpdateParticipant = (updatedParticipant: Participant) => {
        setParticipants((prev) => [...prev].map((p) => {
            if (p.id !== updatedParticipant.id) return p;

            return {
                ...updatedParticipant,
            };
        }));
    };

    const handleTransferOwnership = (newOwnerId: string) => {
        setParticipants((prev) => [...prev].map((p) => {
            if (!p.userId) return p;

            if (p.userId === newOwnerId) {
                return {
                    ...p,
                    participantRole: WorkflowParticipantRole.TRANSACTION_OWNER,
                };
            }

            if (p.userId === user?.id) {
                return {
                    ...p,
                    participantRole: WorkflowParticipantRole.ADMIN,
                };
            }

            return p;
        }));
    };

    /**
     * Handles new participant submission
     *
     * Calls controller method and tries to add a new participant
     *
     * @param newParticipant
     * @returns
     */
    const handleSubmitParticipant = async (newParticipant: Partial<ParticipantPayload>) => {
        const { name, email, participantRole } = newParticipant;

        const workflowIdValue = Number(workflowId);

        if (!name || !email || !participantRole || !isNumber(workflowIdValue)) {
            return;
        }

        const [addedParticipant, error] = await addNewParticipant(workflowId, {
            name,
            email,
            participantRole,
        });

        if (addedParticipant) {
            setIsModalAddingNewParticipantOpen(false);
            fetchParticipants(true);
            toast.success(t('workflows.participants.participantSuccessfullyAdded'));
        }

        if (error) {
            toast.error(error.errors[0].getMessageTranslated(t));
        }
    };

    /**
     * Handles new participant organization member submission
     *
     * Calls controller method and tries to add a new participant
     *
     * @param newParticipant
     * @returns
     */
    const handleSubmitParticipantMember = async (newParticipant: ParticipantMemberPayload) => {
        const { userId, participantRole } = newParticipant;
        
        const workflowIdValue = Number(workflowId);
        
        if (!userId || !participantRole || !isNumber(workflowIdValue)) {
            return;
        }
        
        const [addedParticipant, error] = await addOrganizationMemberAsParticipant(workflowId, {
            userId,
            participantRole,
        });
        
        if (addedParticipant) {
            setIsModalAddingNewParticipantOpen(false);
            fetchParticipants(true);
            toast.success(t('workflows.participants.participantSuccessfullyAdded'));
        }
        
        if (error) {
            toast.error(error.errors[0].getMessageTranslated(t));
        }
    };
    return (
        <div className="workflow-screen--view__section-elements__participants">
            <div className="workflow-screen--view__section-elements__participants__header">
                <div className="workflow-screen--view__section-elements__participants__header__title">
                    {t('workflows.view.participants')}
                    <span className="count" />
                </div>
                {canManageParticipant && (
                    <Button
                        variant={ButtonVariant.Circle}
                        extraClasses="primary"
                        testId="add-participant-btn"
                        onClick={() => setIsModalAddingNewParticipantOpen(true)}
                    >
                        <Tooltip title={t('workflows.participants.addParticipant')} placement="bottom">
                            <PlusIcon />
                        </Tooltip>
                    </Button>
                )}
            </div>
            {isLoading && <LoadingCircles size="s" variant="primary" />}
            {!isLoading && participants.length > 0 && (
                <>
                    <div className="participant-card-list">
                        {participants.map((participant) => (
                            <ParticipantCard
                                key={participant.id}
                                participant={participant}
                                canTransferOwner={canTransferOwner}
                                canManagePermissions={canManageParticipant}
                                onRemoveParticipant={handleRemoveParticipant}
                                onUpdateParticipant={handleUpdateParticipant}
                                onTransferOwnership={handleTransferOwnership}
                            />
                        ))}
                    </div>
                    <Button
                        variant={ButtonVariant.RectangularStroked}
                        extraClasses="full-width"
                        onClick={() => navigate(buildUrl(AppRoute.WorkflowParticipants, { workflowId }))}
                    >
                        {t('workflows.view.viewAll')}
                    </Button>
                </>
            )}
            <AddParticipantModal
                onModalClose={() => setIsModalAddingNewParticipantOpen(false)}
                open={isModalAddingNewParticipantOpen}
                onSubmit={handleSubmitParticipant}
                onMemberSubmit={handleSubmitParticipantMember}
                participants={participants}
            />
        </div>
    );
};

export const ParticipantList = withWorkflowContext(withTranslationContext(ParticipantListComponent));
