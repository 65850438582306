/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../../constants/routes';
import { OnboardingFlowStep } from '../../../types/billing';
import { preparePageTitle } from '../../../utils/route';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { IsolateFlowLayout } from '../../elements/layouts/IsolateFlowLayout';

type OwnProps = TranslationContext & BillingContext;

const BillingCompleteScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, clearCheckoutCart,
    } = props;

    useEffect(() => {
        document.title = preparePageTitle(t('subscribeFlow.title'));
        clearCheckoutCart();
    }, []);

    const OnboardingSteps = [
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ORGANIZATION}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PLAN_REVIEW}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ADD_CREDITS}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.BILLING}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PAYMENT}`),
    ];

    return (
        <IsolateFlowLayout title={t('subscribeFlow.title')}>
            <div className="complete-checkout-screen step-content">
                <HorizontalStepper steps={OnboardingSteps} activeStep={5} />
                <div className="step-content__screen">
                    <div className="complete-checkout-screen__container">
                        <h1>{t('subscribeFlow.purchaseCompleted.title')}</h1>
                        <h2>{t('subscribeFlow.purchaseCompleted.description')}</h2>
                        <h4>{t('subscribeFlow.purchaseCompleted.whatToDoNext')}</h4>
                        <div className="complete-checkout-screen__container__links">
                            <Link to={AppRoute.Index}>{t('subscribeFlow.purchaseCompleted.goHome')}</Link>
                            <Link to={AppRoute.Contracts}>{t('subscribeFlow.purchaseCompleted.goContracts')}</Link>
                            <Link to={AppRoute.OrganizationWorkflows}>{t('subscribeFlow.purchaseCompleted.goWorkflows')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </IsolateFlowLayout>
    );
};

export const BillingCompleteScreen = withBillingContext(withTranslationContext(BillingCompleteScreenComponent));
