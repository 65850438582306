/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BillingContext } from '../../controllers/BillingContext';
import { TranslationContext } from '../../controllers/TranslationContext';
import { PlanFeatureType, PlanPricingType, Subscription } from '../../../types/billing';
import { DataOrError, ErrorResponse } from '../../../types/errors';
import { Drawer } from '../Drawer';
import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import { AppRoute } from '../../../constants/routes';
import { LoadingCircles } from '../LoadingCircles';

type OwnProps = Pick<TranslationContext, 't'> & Pick<BillingContext, 'getSubscriptionDetails'|'organizationWallet'>

export interface UseBillingFeatureRef {
    consumeFeature: (feat: PlanFeatureType, onSuccess: () => void) => void;
}

type ModalAttributes = {
    testId: string;
    title: string;
    body: ReactNode;
    actions: ReactNode[];
}

export const useBillingFeature = (props: OwnProps) => {
    const {
        t,
        organizationWallet,
        getSubscriptionDetails,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState<DataOrError<Subscription, ErrorResponse>>();
    const [modalAttributes, setModalAttributes] = useState<ModalAttributes>({
        actions: [],
        body: <LoadingCircles size="xl" variant="primary" />,
        testId: 'useBillingFeature-loading',
        title: 'Loading',
    });
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    
    const consumeFeature: UseBillingFeatureRef['consumeFeature'] = (feat, onSuccess) => {
        if (!subscriptionDetails) {
            setModalAttributes(NoSubscriptionAttributes);
            setIsOpen(true);
            return;
        }

        const [data, error] = subscriptionDetails;

        // On error assuming user has no subscription
        if (error) {
            setModalAttributes(NoSubscriptionAttributes);
            setIsOpen(true);
            return;
        }

        if (data.expired) {
            setModalAttributes(SubscriptionExpiredAttributes);
            setIsOpen(true);
            return;
        }
        
        const foundItem = data?.items.find((item) => item.featureType === feat);

        // Assuming there is no billing restriction for this feature
        if (!foundItem) {
            return onSuccess();
        }

        // Has NOT enough quota and must upgrade
        switch (foundItem.pricingType) {
            case PlanPricingType.UNLIMITED_QUOTA:
                return onSuccess();
            case PlanPricingType.LIMITED_QUOTA:
                if (foundItem.unitsAvailable <= 0 && !foundItem?.unlockableWithCredits) {
                    setModalAttributes(SubscriptionItemQuotaExhaustedRequestUpgradeAttributes);
                    setIsOpen(true);
                    return;
                }
                // can pay with credits
                if (foundItem.unitsAvailable <= 0 && foundItem?.unlockableWithCredits) {
                    setModalAttributes(SubscriptionItemUnlockableWithCreditsAttributes);
                    setIsOpen(true);
                    return;
                }
                break;
            case PlanPricingType.PAY_PER_USE:
                // To be Refactored
                setModalAttributes(SubscriptionItemUnlockableWithCreditsAttributes);
                setIsOpen(true);
                return;
            default:
                break;
        }

        return onSuccess();
    };
    
    useEffect(() => {
        if (organizationWallet?.id) {
            setIsLoading(true);
            getSubscriptionDetails(String(organizationWallet?.id)).then((val) => {
                setSubscriptionDetails(val);
                setIsLoading(false);
            });
        }
        return () => {
        };
    }, [organizationWallet?.id]);

    const navigateToPlans = () => {
        navigate(AppRoute.BillingPlans);
    };

    const close = () => {
        setIsOpen(false);
    };

    const NoSubscriptionAttributes: ModalAttributes = {
        actions: [
            <Button key="no-subscription" variant={ButtonVariant.Curved} onClick={navigateToPlans}>
                {t('billing.featureQuotaReachedRequestUpgrade.noSubscription.subscribe')}
            </Button>,
        ],
        body: (
            <p>
                {t('billing.featureQuotaReachedRequestUpgrade.noSubscription.body')}
            </p>
        ),
        testId: 'no-subscription-modal',
        title: t('billing.featureQuotaReachedRequestUpgrade.noSubscription.title'),
    };

    const SubscriptionExpiredAttributes: ModalAttributes = {
        actions: [
            <Button key="subscription-expired-renew" variant={ButtonVariant.Curved} onClick={navigateToPlans}>
                {t('billing.featureQuotaReachedRequestUpgrade.subscriptionExpired.renew')}
            </Button>,
        ],
        body: (
            <p>
                {t('billing.featureQuotaReachedRequestUpgrade.subscriptionExpired.body')}
            </p>
        ),
        testId: 'subscription-expired-modal',
        title: t('billing.featureQuotaReachedRequestUpgrade.subscriptionExpired.title'),
    };

    const SubscriptionItemQuotaExhaustedRequestUpgradeAttributes: ModalAttributes = {
        actions: [
            <Button key="subscriptionItemQuotaExhaustedRequestUpgrade-upgrade" variant={ButtonVariant.Curved} onClick={navigateToPlans}>
                {t('billing.featureQuotaReachedRequestUpgrade.subscriptionItemQuotaExhaustedRequestUpgrade.upgrade')}
            </Button>,
        ],
        body: (
            <p>
                {t('billing.featureQuotaReachedRequestUpgrade.subscriptionItemQuotaExhaustedRequestUpgrade.body')}
            </p>
        ),
        testId: 'quota-exhausted-request-upgrade-modal',
        title: t('billing.featureQuotaReachedRequestUpgrade.subscriptionItemQuotaExhaustedRequestUpgrade.title'),
    };

    const SubscriptionItemUnlockableWithCreditsAttributes: ModalAttributes = {
        actions: [
            <Button key="subscriptionItemQuotaExhaustedPayWithCredits-upgrade" variant={ButtonVariant.Curved} onClick={navigateToPlans}>
                {t('billing.featureQuotaReachedRequestUpgrade.subscriptionItemQuotaExhaustedPayWithCredits.upgrade')}
            </Button>,
        ],
        body: (
            <p>
                {t('billing.featureQuotaReachedRequestUpgrade.subscriptionItemQuotaExhaustedPayWithCredits.body')}
            </p>
        ),
        testId: 'quota-exhausted-unlockable-with-credits-modal',
        title: t('billing.featureQuotaReachedRequestUpgrade.subscriptionItemQuotaExhaustedPayWithCredits.title'),
    };

    return {
        consumeFeature,
        isLoading,
        billingModal: (
            <Drawer open={isOpen} handleClose={close} testId={modalAttributes.testId} title={modalAttributes.title}>
                {modalAttributes.body}
                <div className="form__bottom">
                    {modalAttributes.actions}
                </div>
            </Drawer>
        ),
    };
};
