/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/closeBtn.svg';
import { ReactComponent as PersonIcon } from '../../../assets/images/profile.svg';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Tooltip } from '../Tooltip';
import { useContractContext } from './ContractContextProvider';

type OwnProps = TranslationContext;

const SignerActionBarComponent: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;

    const { pendingPlaceholder, updatePendingPlaceholder, addNewPlaceholder } = useContractContext();

    if (!pendingPlaceholder) return null;

    const removePlaceholder = () => {
        updatePendingPlaceholder(null);
    };

    const failToSubmitPlaceholder = (message: string) => {
        toast.error(message);
    };

    return (
        <div className="signer-action-bar">
            <button className="signer-action-bar__cancel" type="button" data-testid="modal-close-btn" onClick={removePlaceholder}>
                <Tooltip title={t('signerActions.tooltips.cancelPlaceholder')} placement="top">
                    <CloseIcon />
                </Tooltip>
            </button>
            <div className="signer-action-bar__name-container">
                <PersonIcon />
                <h2>{pendingPlaceholder.signer.name}</h2>
            </div>
            <button className="signer-action-bar__success" type="button" data-testid="modal-close-btn" onClick={() => addNewPlaceholder(pendingPlaceholder, failToSubmitPlaceholder)}>
                <Tooltip title={t('signerActions.tooltips.confirmPlaceholder')} placement="top">
                    <CheckIcon />
                </Tooltip>
            </button>
        </div>
    );
};

export const SignerActionBar = withTranslationContext(SignerActionBarComponent);
