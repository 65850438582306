/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoWithText } from '../../../../assets/images/logo-with-text.svg';
import { ReactComponent as SignIcon } from '../../../../assets/images/pen.svg';
import { AppRoute } from '../../../../constants/routes';

interface OwnProps {
    children: ReactNode;
    primaryBtn?: ReactNode;
    secondaryBtn?: ReactNode;
}

const EsignaturesLayout: FunctionComponent<OwnProps> = (props) => {
    const {
        children,
        primaryBtn,
        secondaryBtn,
    } = props;
    return (
        <>
            <div className="esignatures-layout" data-testid="esignatures-layout">
                <header className="esignatures-layout__header" data-testid="header">
                    <Link to={AppRoute.Index}>
                        <div className="esignatures-layout__header__logo-wrap">
                            <LogoWithText />
                        </div>
                    </Link>
                    <div className="esignatures-layout__header__icon-wrap">
                        <SignIcon />
                    </div>
          
                </header>
                <div className="esignatures-layout__content">
                    {children}
                </div>
                {(secondaryBtn || primaryBtn) && (
                    <footer className="esignatures-layout__footer" data-testid="footer">
                        <div className="esignatures-layout__footer__btns-wrap">
                            {secondaryBtn}
                            {primaryBtn}
                        </div>
                    </footer>
                )}
            </div>
        </>
    );
};

export { EsignaturesLayout };
