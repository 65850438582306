/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg';
import { AppRoute } from '../../../../constants/routes';
import { ButtonVariant } from '../../../../types/general';
import { Workflow, WorkflowDocument } from '../../../../types/workflows';
import { buildUrl } from '../../../../utils/navigation';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../../controllers/WorkflowContext';
import Button from '../../Button';
import Modal from '../../Modal';
import { Tooltip } from '../../Tooltip';

interface OwnProps extends TranslationContext, WorkflowContext {
    document: WorkflowDocument;
    workflow: Workflow;
    testId?: string;
}

const DeleteDocumentOptionComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, deleteDocument, document, testId, workflow,
    } = props;
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();

    const isDocumentsApprovalStatus = workflow.status === 'RENTAL_DOCUMENTS_APPROVAL' || workflow.status === 'SELL_DOCUMENTS_APPROVAL';

    const onOpenConfirmationModal = () => {
        setIsOpenModal(true);
    };

    const onDeleteSuccess = () => {
        navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId: String(document.transactionId) }));
    };

    const onConfirmDelete = () => {
        setIsDeleting(true);
        deleteDocument(String(document.transactionId), String(document.id)).then((responseError) => {
            setIsDeleting(false);

            // If no error detected succeed
            if (responseError) {
                toast.error(responseError.errors[0].getMessageTranslated(t));
                return;
            }
            
            onDeleteSuccess();
        });
    };

    const onCancel = () => {
        setIsOpenModal(false);
    };

    return (
        <>
            <Tooltip title={isDocumentsApprovalStatus ? t('workflows.documents.workflowDocumentsInReviewWarning') : ''} placement="left">
                <span> {/* this span is for the Tooltip only. Since when the button is disabled any pointer events are nullified and the children will not trigger hover effects */}
                    <Button
                        variant={ButtonVariant.IconBtn}
                        extraClasses={classNames('danger',
                            { active: isOpenModal })
                    }
                        disabled={isDocumentsApprovalStatus}
                        onClick={onOpenConfirmationModal}
                        testId={testId}
                    >
                        <Tooltip title={t('workflows.documents.deleteDocumentTitle')} placement="left">
                            <DeleteIcon className="stroked" />
                        </Tooltip>
                    </Button>
                </span>
            </Tooltip>
            <Modal
                open={isOpenModal}
                title={t('workflows.documents.deleteDocumentTitle')}
                handleClose={onCancel}
            >
                <div className="content-wrap">
                    <p>{t('workflows.documents.deleteDocumentDescription')}</p>
                    <p className="warning-msg">{t('workflows.documents.deleteDocumentWarning')}</p>
                    {!isDeleting && (
                        <>
                            <Button
                                onClick={onCancel}
                                variant={ButtonVariant.Curved}
                                extraClasses="secondary"
                                testId="cancel-btn"
                            >
                                {t('workflows.documents.deleteDocumentCancel')}
                            </Button>
                            <Button
                                onClick={onConfirmDelete}
                                variant={ButtonVariant.Curved}
                                extraClasses="danger"
                                testId="confirm-btn"
                            >
                                {t('workflows.documents.deleteDocumentSubmit')}
                            </Button>
                        </>
                    )}
                    {isDeleting && (
                        <CircularProgress color="primary" data-testid="loading" />
                    )}
                </div>
            </Modal>
        </>
    );
};

export const DeleteDocumentOption = withWorkflowContext(withTranslationContext(DeleteDocumentOptionComponent));
