/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { Chip } from '@mui/material';
import { isEqual } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';
import {
    RentalWorkflowStateEnum, SellWorkflowStateEnum, Workflow, WorkflowsFilterParams, WorkflowType,
} from '../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import Button from '../../elements/Button';
import { Drawer } from '../../elements/Drawer';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { WorkflowsFiltersForm } from '../../elements/workflows/WorkflowsFiltersForm';
import { WorkflowCard } from '../../elements/workflows/WorkflowCard';
import { ReactComponent as RemoveIcon } from '../../../assets/images/closeBtn.svg';
import { formatToLocalDate } from '../../../utils/date';
import { OrderOptions } from '../../../types/general';
import { preparePageTitle } from '../../../utils/route';
import { objectToParams, paramsToObject } from '../../../utils/misc';

type OwnProps = TranslationContext & WorkflowContext;

const defaultFilters: WorkflowsFilterParams = {
    _cursor: '',
    _limit: '',
    _sort: '',
    email: '',
    externalId: '',
    from: '',
    to: '',
    transactionType: undefined,
    transactionStatus: undefined,
};

const initialFilters: WorkflowsFilterParams = {
    ...defaultFilters,
    _sort: OrderOptions.DESCENDING,
    transactionType: WorkflowType.SELL,
};

const WorkflowsScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getParticipantWorkflows,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [filters, setFilters] = useState<WorkflowsFilterParams>(initialFilters);

    useEffect(() => {
        document.title = preparePageTitle(t('workflows.list.title'));

        const filtersFromParams: Partial<WorkflowsFilterParams> = {
            ...defaultFilters,
            ...paramsToObject(searchParams, defaultFilters),
        };
        
        // check if the sort option is valid
        if (!Object.values(OrderOptions).includes(filtersFromParams._sort as OrderOptions)) {
            filtersFromParams._sort = initialFilters._sort;
        }

        // check if transactionType is valid
        if (!Object.values(WorkflowType).includes(filtersFromParams.transactionType as WorkflowType)) {
            filtersFromParams.transactionType = initialFilters.transactionType;
        }

        // check if transactionStatus is valid
        if (!Object.values(SellWorkflowStateEnum).includes(filtersFromParams.transactionStatus as SellWorkflowStateEnum) && !Object.values(RentalWorkflowStateEnum).includes(filtersFromParams.transactionStatus as RentalWorkflowStateEnum)) {
            filtersFromParams.transactionStatus = defaultFilters.transactionStatus;
        }
            
        setFilters((prev) => ({ ...prev, ...filtersFromParams }));
        getWorkflowsList(filtersFromParams);
    }, []);

    useEffect(() => {
        const { _cursor, ...filtersWithoutCursor } = filters;

        setSearchParams(objectToParams(filtersWithoutCursor));
    }, [filters]);

    const appliedFilters: string[] = useMemo(() => {
        return Object.keys(filters)
            .filter((key) => !key.startsWith('_')
                && filters[key as keyof WorkflowsFilterParams] !== ''
                && filters[key as keyof WorkflowsFilterParams] !== undefined);
    }, [filters]);

    const getWorkflowsList = async (submittedFilters: WorkflowsFilterParams) => {
        setIsLoading(true);
        
        const { _cursor, ...appliedFiltersWithoutCursor } = submittedFilters;
        const { _cursor: _c, ...existingFiltersWithoutCursor } = filters;

        const filtersToApply = isEqual(appliedFiltersWithoutCursor, existingFiltersWithoutCursor) ? submittedFilters : { ...submittedFilters, _cursor: '' };

        const [workflowsData] = await getParticipantWorkflows(filtersToApply);
        
        if (workflowsData) {
            setWorkflows((prev) => (filtersToApply._cursor ? [...prev, ...workflowsData.results] : [...workflowsData.results]));
            setFilters({
                ...filtersToApply,
                _cursor: workflowsData.cursor,
            });
        }
        setIsLoading(false);
    };

    const applyFilters = (filtersToApply: WorkflowsFilterParams) => {
        setIsLoading(true);
        setWorkflows([]);
        setIsOpenFilters(false);
        getWorkflowsList({ ...filtersToApply, _cursor: '' });
    };

    const getAppliedFilterDisplayValue = (key: keyof WorkflowsFilterParams) => {
        switch (key) {
            case 'transactionType':
            case 'transactionStatus':
                return t(`workflows.list.filters.${key}Options.${filters[key]}`);
            case 'from':
            case 'to':
                return formatToLocalDate(filters[key]);
            default:
                return filters[key];
        }
    };

    const removeFilter = (keyToRemove: keyof WorkflowsFilterParams) => {
        const newFilters: WorkflowsFilterParams = {
            ...filters,
            [keyToRemove]: defaultFilters[keyToRemove],
        };
        setFilters(newFilters);
        getWorkflowsList(newFilters);
    };

    const cleanAllFilters = () => {
        setIsOpenFilters(false);
        setFilters(defaultFilters);
        getWorkflowsList(defaultFilters);
    };
    return (
        <DefaultLayout>
            <div className="workflows-screen" data-testid="workflows-screen">
                <div className="workflows-screen__header">
                    <h1>{t('workflows.list.title')}</h1>
                    <Button
                        extraClasses="circle-btn filters-trigger-btn"
                        onClick={() => setIsOpenFilters(true)}
                        testId="mobile-filters-trigger-btn"
                    >
                        <FiltersIcon />
                    </Button>
                </div>
                {appliedFilters.length > 0 && (
                    <div className="workflows-screen__applied-filters" data-testid="applied-filters">
                        <div className="workflows-screen__applied-filters__header">
                            {`${appliedFilters.length} ${t(`workflows.list.filters.${appliedFilters.length === 1 ? 'filterApplied' : 'filtersApplied'}`)}:`}
                            <Button
                                onClick={cleanAllFilters}
                                testId="large-clean-btn"
                            >
                                {t('workflows.list.filters.cleanAll')}
                            </Button>
                        </div>
                        <div className="workflows-screen__applied-filters__list" data-testid="applied-filters-list">
                            {appliedFilters.map((appliedFilter) => {
                                return (
                                    <Chip
                                        key={appliedFilter}
                                        className="workflows-screen__applied-filters__list__item"
                                        label={`${t(`workflows.list.filters.${appliedFilter}`)}: ${getAppliedFilterDisplayValue(appliedFilter as keyof WorkflowsFilterParams)}`}
                                        onDelete={() => removeFilter(appliedFilter as keyof WorkflowsFilterParams)}
                                        deleteIcon={<RemoveIcon />}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className="workflows-screen__content">
                    <div className="workflows-screen__content__large-filters" data-testid="filters-sidebar">
                        <WorkflowsFiltersForm
                            currentFilters={filters}
                            filterMemberOptions={() => {}}
                            filteredMemberOptions={[]}
                            onSubmit={applyFilters}
                            onClean={cleanAllFilters}
                        />
                    </div>
                    <div className="workflows-screen__content__list">
                        {!isLoading && workflows.length === 0 && (
                            <p className="workflows-screen__content__list__empty-list">{t('workflows.list.noResults')}</p>
                        )}
                        {isLoading && <LoadingCircles size="m" variant="primary" />}
                        <InfiniteScrollWrapper
                            hasMore={!!filters._cursor}
                            requestMore={() => getWorkflowsList(filters)}
                        >
                            {workflows.map((workflow) => (
                                <WorkflowCard key={workflow.id} workflow={workflow} />
                            ))}
                        </InfiniteScrollWrapper>
                    </div>
                </div>
                <Drawer
                    open={isOpenFilters}
                    handleClose={() => setIsOpenFilters(false)}
                    title={t('general.filter')}
                    extraClasses="filters-drawer"
                    testId="filters-drawer"
                >
                    <WorkflowsFiltersForm
                        currentFilters={filters}
                        filterMemberOptions={() => {}}
                        filteredMemberOptions={[]}
                        onSubmit={applyFilters}
                        onClean={cleanAllFilters}
                    />
                </Drawer>
            </div>
        </DefaultLayout>
    );
};

export const WorkflowsScreen = withTranslationContext(withWorkflowContext(WorkflowsScreenBase));
