
/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../../constants/routes';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';

type OwnProps = TranslationContext & BillingContext;

const PurchaseCreditsCheckoutCompleteScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, clearCheckoutCart,
    } = props;

    useEffect(() => {
        clearCheckoutCart();
    }, []);

    return (
        <DefaultLayout>
            <div className="purchase-credits-screen">
                <div className="purchase-credits-screen__container">
                    <h1>{t('purchaseCredits.purchaseCompleted.title')}</h1>
                    <h2>{t('purchaseCredits.purchaseCompleted.description')}</h2>
                    <h4>{t('purchaseCredits.purchaseCompleted.whatToDoNext')}</h4>
                    <div className="purchase-credits-screen__container__links">
                        <Link to={AppRoute.Index}>{t('purchaseCredits.purchaseCompleted.goHome')}</Link>
                        <Link to={AppRoute.Contracts}>{t('purchaseCredits.purchaseCompleted.goContracts')}</Link>
                        <Link to={AppRoute.OrganizationWorkflows}>{t('purchaseCredits.purchaseCompleted.goWorkflows')}</Link>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export const PurchaseCreditsCheckoutCompleteScreen = withBillingContext(withTranslationContext(PurchaseCreditsCheckoutCompleteScreenComponent));
