/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { Wallet } from '../../../types/billing';
import { FormValidationError } from '../../../utils/validations';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { FormField } from '../FormField';
import { PhoneInput } from '../PhoneInput';

interface OwnProps extends TranslationContext {
    wallet: Wallet;
    title: string;
    personErrorsForm: FormValidationError | null;
    fiscalErrorsForm: FormValidationError | null;
    addressErrorsForm: FormValidationError | null;
}

const BillingInformationFormComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        wallet,
        title,
        personErrorsForm,
        fiscalErrorsForm,
        addressErrorsForm,
    } = props;

    const DefaultPhone = '+351 ';

    return (
        <div className="billing-information-form">
            <h2>{title}</h2>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormField
                        name="firstName"
                        label={t('subscribeFlow.billingStep.firstName')}
                        errors={personErrorsForm}
                        value={wallet.contactablePerson?.firstName}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        name="lastName"
                        label={t('subscribeFlow.billingStep.lastName')}
                        errors={personErrorsForm}
                        value={wallet.contactablePerson?.lastName}
                        required
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormField
                        name="email"
                        label={t('subscribeFlow.billingStep.email')}
                        errors={personErrorsForm}
                        value={wallet.contactablePerson?.email}
                        required
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div data-testid="field-phone-input">
                        <label>
                            {t('subscribeFlow.billingStep.phoneNumber')}
                        </label>
                        <PhoneInput
                            name="phoneNumber"
                            id="phoneNumber"
                            errors={personErrorsForm}
                            testId="phone-input"
                            initialValue={wallet.contactablePerson?.phoneNumber ?? DefaultPhone}
                            required
                        />
                    </div>
                </Grid>
            </Grid>
            <span className="separator" />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <FormField
                        name="fiscalName"
                        label={t('subscribeFlow.billingStep.companyName')}
                        placeholder={t('subscribeFlow.billingStep.companyName')}
                        errors={fiscalErrorsForm}
                        value={wallet.fiscalInfo?.fiscalName}
                        required
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormField
                        name="fiscalNumber"
                        label={t('subscribeFlow.billingStep.TIN')}
                        placeholder={t('subscribeFlow.billingStep.TINPlaceholder')}
                        errors={fiscalErrorsForm}
                        value={wallet.fiscalInfo?.fiscalNumber}
                        required
                    />
                </Grid>
            </Grid>
            <span className="separator" />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormField
                        name="addressLine1"
                        label={t('subscribeFlow.billingStep.address')}
                        placeholder={t('subscribeFlow.billingStep.addressLine1')}
                        errors={addressErrorsForm}
                        value={wallet.address?.addressLine1}
                        required
                    />
                    <FormField
                        extraClasses="address-line2"
                        name="addressLine2"
                        placeholder={t('subscribeFlow.billingStep.addressLine2')}
                        errors={addressErrorsForm}
                        value={wallet.address?.addressLine2}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        name="postalCode"
                        label={t('subscribeFlow.billingStep.postalCode')}
                        errors={addressErrorsForm}
                        value={wallet.address?.postalCode}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        name="city"
                        label={t('subscribeFlow.billingStep.city')}
                        errors={addressErrorsForm}
                        value={wallet.address?.city}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        name="state"
                        label={t('subscribeFlow.billingStep.state')}
                        errors={addressErrorsForm}
                        value={wallet.address?.state}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        name="country"
                        label={t('subscribeFlow.billingStep.country')}
                        errors={addressErrorsForm}
                        value={wallet.address?.country}
                        required
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export const BillingInformationForm = withTranslationContext(BillingInformationFormComponent);
