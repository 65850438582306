/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { SignerInvitationContext, SignerInvitationContextProvider } from './SignerInvitationContext';
import { TranslationContext, withTranslationContext } from './TranslationContext';
import { assignUserToSignerUrl, signerContractUrl } from '../../services/contracts';

import { ErrorResponse } from '../../types/errors';
import { useCornerstoneApi } from '../../api';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & TranslationContext;

const SignerInvitationControllerBase: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;
    const CornerstoneAPI = useCornerstoneApi();

    /**
     * Loads contract information for the signer based on the signer token
     */
    const loadContractInformation: SignerInvitationContext['loadContractInformation'] = async (signerToken) => {
        try {
            const { data } = await CornerstoneAPI.get(signerContractUrl(signerToken));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Calls API to attempt assigning the current user to the signer.
     * Returns error if user cannot be assigned.
     *
     * @param signerToken
     * @returns
     */
    const tryAssigningUserToSigner: SignerInvitationContext['tryAssigningUserToSigner'] = async (signerToken: string) => {
        try {
            const { data } = await CornerstoneAPI.put(assignUserToSignerUrl(signerToken));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Validates a PIN sent by email to confirm the email ownership of the signer invited.
     * Returns error if PIN is incorrect.
     */
    const validatePIN: SignerInvitationContext['validatePIN'] = async (signerToken, payload) => {
        try {
            const { data } = await CornerstoneAPI.post(assignUserToSignerUrl(signerToken), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };
    return (
        <SignerInvitationContextProvider
            value={{
                loadContractInformation,
                tryAssigningUserToSigner,
                validatePIN,
            }}
        >
            {children}
        </SignerInvitationContextProvider>
    );
};

export const SignerInvitationController = withTranslationContext(SignerInvitationControllerBase);
