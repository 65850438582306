/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export enum AppLanguage {
    en = 'en',
    pt = 'pt'
}

export const DEFAULT_LANGUAGE = AppLanguage.en;
