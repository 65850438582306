/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

/* Organization */
export const organizationsUrl = () => {
    return '/organizations';
};

export const organizationUrl = (organizationId: number) => {
    return `/organizations/${organizationId}`;
};

/* Role */
export const rolesUrl = () => {
    return '/roles';
};

export const roleUrl = (roleId: number) => {
    return `/roles/${roleId}`;
};

export const rolePermissionsUrl = (roleId: number) => {
    return `/roles/${roleId}/permissions`;
};

export const rolesListUrl = (organizationId: number) => {
    return `/organizations/${organizationId}/roles`;
};

/* Role permissions */
export const permissionsUrl = () => {
    return '/permissions';
};

export const groupedPermissionsUrl = () => {
    return '/permissions/values';
};

export const getUserOrganizationsAccessesUrl = (userId: string, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/users/${userId}/organization-accesses${queryStr}`;
};

export const organizationAccessRoles = (organizationAccessId: number) => {
    return `/organization-access/${organizationAccessId}/roles`;
};

/* Contracts */
export const organizationContractsUrl = (organizationId: number, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/organizations/${organizationId}/contracts${queryStr}`;
};

export const organizationWorkflowsUrl = (organizationId: number, params?: KeyedObject<unknown>) => {
    const queryStr = objectToParams(params);
    return `/organizations/${organizationId}/transactions${queryStr}`;
};
    
export const organizationLogoUrl = (organizationId: number) => {
    return `/organizations/${organizationId}/logo`;
};

export const organizationMembersUrl = (organizationId: number, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/organizations/${organizationId}/members/search${queryStr}`;
};
