/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';

interface OwnProps {
    /**
     * Bar progress percentage beteen 0 and 100
     */
    progress: number;
    /**
     * Bar variant influences color
     */
    variant: 'primary'|'secondary';
    /**
     * Progress bar size. Increases bar height
     */
    size: 'xs';
    /**
     * Progress bar test ID
     */
    testId?: string;
}

/**
 * Progress bar component render a full width rectangle progress bar based on progress
 * @param props
 * @returns
 */
export const ProgressBar: FunctionComponent<OwnProps> = (props) => {
    const {
        size, variant, progress, testId,
    } = props;

    /**
     * Validates progress number and applies a correction
     *
     * @returns
     */
    function validateProgress() {
        if (progress < 0) return 0;
        if (progress > 100) return 100;
        return progress;
    }

    const progressValidated = validateProgress();

    return (
        <div className={`progress-bar ${size}`} data-testid={testId ?? 'progress-bar'}>
            <div
                data-testid="progress-remaining"
                className={variant}
                style={{
                    width: `${progressValidated}%`,
                }}
            />
            <div
                data-testid="progress-passed"
                className={variant}
                style={{
                    width: `${100 - progressValidated}%`,
                }}
            />
        </div>
    );
};
