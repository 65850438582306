/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Elements,
} from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';

import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../constants/routes';
import { NewPaymentMethodResponse, OnboardingFlowStep } from '../../../types/billing';
import { ErrorResponse } from '../../../types/errors';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { PaymentMethodContext, withPaymentMethodContext } from '../../controllers/PaymentMethodContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { CartSummary } from '../../elements/billing/CartSummary';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { IsolateFlowLayout } from '../../elements/layouts/IsolateFlowLayout';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { NewPaymentMethodForm } from './NewPaymentMethodForm';
import { StripeAppearance } from '../../../constants/StripeAppearance';
import { preparePageTitle } from '../../../utils/route';

type NewCreditCardScreenProps = PaymentMethodContext & BillingContext & TranslationContext;

export const NewPaymentMethodScreenComponent: FunctionComponent<NewCreditCardScreenProps> = (props) => {
    const {
        t, createCreditCard, getStripeConfig, billingInfo,
    } = props;

    const navigate = useNavigate();

    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
    const [newCreditCard, setNewCreditCard] = useState<NewPaymentMethodResponse>();
    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState<ErrorResponse>();

    const redirectToRoute = location.origin + AppRoute.PaymentCheckout;

    const OnboardingSteps = [
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ORGANIZATION}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PLAN_REVIEW}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ADD_CREDITS}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.BILLING}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PAYMENT}`),
    ];

    function handleLoadNewCreditCard(walletId: number) {
        createCreditCard(walletId).then((res) => {
            const [_data, _error] = res;
            if (_error) {
                setError(_error);
                return;
            }
            setNewCreditCard(_data);
        });
    }

    useEffect(() => {
        document.title = preparePageTitle(t('subscribeFlow.title'));
        init();
    }, []);

    const init = async () => {
        const { wallet } = billingInfo;

        if (!wallet) {
            setIsLoading(false);
            navigate(AppRoute.BillingPlans);
            return;
        }

        handleLoadNewCreditCard(wallet.id);

        getStripeConfig().then((res) => {
            const [_data, _error] = res;
            if (_data) {
                setStripePromise(loadStripe(_data?.publishableKey));
            }
            if (_error) {
                setError(_error);
            }
            setIsLoading(false);
        });
    };

    return (
        <IsolateFlowLayout title={t('subscribeFlow.title')}>
            <div
                data-testid="new-credit-card-screen"
                className="new-credit-card-screen step-content"
            >
                <HorizontalStepper steps={OnboardingSteps} activeStep={5} />
                <div className="step-content__screen">
                    <div className="new-credit-card-screen__container">
                        <h2>{t('subscribeFlow.newCard.title')}</h2>
                        {stripePromise && newCreditCard && (
                            <Elements stripe={stripePromise} options={{ clientSecret: newCreditCard.clientSecret, appearance: StripeAppearance }}>
                                <NewPaymentMethodForm successRedirectTo={redirectToRoute} />
                            </Elements>
                        )}
                        {isLoading && <LoadingCircles size="xl" variant="primary" />}
                        {error && (
                            <Alert severity="error">{JSON.stringify(error)}</Alert>
                        )}
                    </div>
                    <CartSummary />
                </div>
            </div>
        </IsolateFlowLayout>
    );
};

export const NewPaymentMethodScreen = withTranslationContext(withPaymentMethodContext(withBillingContext(NewPaymentMethodScreenComponent)));
