/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FC } from 'react';
import { OptionCheckboxGroupField } from '../../types/general';
import { Checkbox } from './Checkbox';
import InfiniteScrollWrapper from './InfiniteScrollWrapper';

type OwnProps = {
    options: OptionCheckboxGroupField[];
    onChange: (id: number | string, value: boolean) => void;
    label?: string;
    testId?: string;
    hasMore?: boolean;
    loadMore?: () => Promise<void>;
}

const CheckboxFormGroup: FC<OwnProps> = (props) => {
    const {
        options, onChange, label, testId, hasMore, loadMore,
    } = props;

    const renderCheckboxes = () => {
        if (loadMore) {
            return (
                <InfiniteScrollWrapper
                    hasMore={!!hasMore}
                    requestMore={loadMore}
                    extraClasses="checkbox-form-group__body"
                >
                    {options.map((option) => (
                        <Checkbox
                            key={option.id}
                            checked={option.value}
                            onChange={() => onChange(option.id, !option.value)}
                            name={option.label}
                            label={option.label}
                            size="lg"
                        />
                    ))}
                </InfiniteScrollWrapper>
            );
        }

        return (
            <div className="checkbox-form-group__body">
                {options.map((option) => (
                    <Checkbox
                        key={option.id}
                        checked={option.value}
                        onChange={() => onChange(option.id, !option.value)}
                        name={option.label}
                        label={option.label}
                        size="lg"
                    />
                ))}
            </div>
        );
    };
    
    return (
        <div className="checkbox-form-group" data-testid={testId}>
            <div className="checkbox-form-group__top">
                {label && (
                    <label className="checkbox-form-group__top__label">
                        {label}
                    </label>
                )}
            </div>
            {renderCheckboxes()}
        </div>
    );
};

export { CheckboxFormGroup };
