/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as DraftIcon } from '../../../assets/images/draft.svg';
import { AppRoute } from '../../../constants/routes';
import { ButtonVariant } from '../../../types/general';
import { buildUrl } from '../../../utils/navigation';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { useContractContext } from './ContractContextProvider';

type OwnProps = TranslationContext;

const DraftContractActionBarComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
    } = props;

    const {
        contract,
        placeholderList,
    } = useContractContext();

    const navigate = useNavigate();

    const handleSubmitContract = () => {
        if (contract?.signers.length !== placeholderList.length) {
            toast.error(t('contractCreate.signersWithoutPlaceholder'));
            return;
        }

        navigate(buildUrl(AppRoute.ContractCheckout, { contractId: String(contract?.id) }));
    };

    return (
        <div className="contract-screen__bottom-actions" data-testid="draft-contract-action-bar">
            <Button
                title={t('contractCreate.saveDraft')}
                variant={ButtonVariant.Curved}
                extraClasses="secondary"
                startIcon={<DraftIcon className="no-style" />}
                testId="draft-btn"
                onClick={() => navigate(AppRoute.Contracts)}
            >
                {t('contractCreate.saveDraft')}
            </Button>
            <Button
                title={t('contractCreate.submit')}
                variant={ButtonVariant.Curved}
                extraClasses="primary"
                onClick={handleSubmitContract}
                endIcon={<ArrowRightIcon />}
                testId="submit-btn"
            >
                {t('contractCreate.submit')}
            </Button>
        </div>
    );
};

export const DraftContractActionBar = withTranslationContext(DraftContractActionBarComponent);
