/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';

import { Grid } from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { InnovatorsClubSection } from '../../elements/InnovatorsClubSection';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { ErrorCode, ErrorResponse } from '../../../types/errors';
import {
    Wallet,
} from '../../../types/billing';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { preparePageTitle } from '../../../utils/route';
import { AppRoute } from '../../../constants/routes';
import { WalletUsageCard } from '../../elements/billing/WalletUsageCard';

type BaseProps = TranslationContext & BillingContext;

export const WalletScreenBase: FunctionComponent<BaseProps> = (props) => {
    const {
        t,
        getUsersWallet,
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [wallet, setWallet] = useState<Wallet | null>(null);
    const [error, setError] = useState<null | ErrorResponse>(null);

    useEffect(() => {
        document.title = preparePageTitle(t('walletScreen.title'));
        init();
    }, []);

    useEffect(() => {
        if (error) throw error;
    }, [error]);

    const init = async () => {
        const [personalWalletData, requestedDataError] = await getUsersWallet();
        if (personalWalletData) {
            setWallet(personalWalletData);
        }
        if (requestedDataError) {
            if (String(requestedDataError.errors[0]?.errorCode) === ErrorCode.ENTITY_NOT_FOUND) {
                setIsLoading(false);
                return;
            }
            setError(requestedDataError);
        }
        setIsLoading(false);
    };
    return (
        <div className="wallet-screen">
            <div className="wallet-screen__header">
                <h1>{t('walletScreen.title')}</h1>
            </div>
            <InnovatorsClubSection />
            {isLoading && <LoadingCircles size="m" variant="primary" />}
            {!isLoading && wallet && (
                <div className="wallet-screen__usage">
                    <Grid container spacing={2}>
                        {wallet && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <WalletUsageCard
                                        name="wallet"
                                        title={t('billingDashboard.wallet.title')}
                                        wallet={wallet}
                                        addCreditsUrl={AppRoute.PurchaseCredits}
                                    />
                                </Grid>
                                {wallet.address && (
                                    <Grid item xs={12} md={6}>
                                        <div className="usage-card" data-testid="usage-card-billing-information">
                                            <h2>{t('billingDashboard.billingDetails.title')}</h2>
                                            {wallet.address.addressLine1 && (<div>{wallet.address.addressLine1}</div>)}
                                            {wallet.address.addressLine2 && (<div>{wallet.address.addressLine2}</div>)}
                                            {(wallet.address.postalCode || wallet.address.city) && (<div>{wallet.address.postalCode}{wallet.address.city ? `, ${wallet.address.city}` : ''}</div>)}
                                            {wallet.address.country && (<div>{wallet.address.country}</div>)}
                                        </div>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </div>
            )}
        </div>
    );
};
export const WalletScreen = withTranslationContext(withBillingContext(WalletScreenBase));
