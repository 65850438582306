/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, ReactNode } from 'react';
import { Button as ButtonMUI } from '@mui/material';
import classNames from 'classnames';
import { ButtonVariant } from '../../types/general';

type BaseButtonAttributes = Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'id' | 'title' | 'disabled' | 'name'>

type OwnProps = BaseButtonAttributes & {
    children: ReactNode;
    extraClasses?: string;
    isSubmit?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    testId?: string;
    variant?: ButtonVariant;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    helperText?: string;
}

export const Button: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        id,
        isSubmit,
        children,
        extraClasses,
        onClick,
        testId,
        title,
        disabled,
        variant = ButtonVariant.Basic,
        name,
        startIcon,
        endIcon,
        helperText,
    } = props;

    return (
        <>
            <ButtonMUI
                type={isSubmit ? 'submit' : 'button'}
                onClick={onClick}
                className={classNames(extraClasses,
                    {
                        'basic-btn': variant === ButtonVariant.Basic,
                        'icon-btn': variant === ButtonVariant.IconBtn,
                        'custom-btn primary': variant === ButtonVariant.Curved,
                        'rectangular-filled-btn primary': variant === ButtonVariant.RectangularFilled,
                        'rectangular-stroked-btn primary': variant === ButtonVariant.RectangularStroked,
                        'circle-btn': variant === ButtonVariant.Circle,
                    })}
                startIcon={startIcon}
                endIcon={endIcon}
                data-testid={testId}
                disabled={disabled}
                id={id}
                name={name}
                title={title}
            >
                {children}
            </ButtonMUI>
            {helperText && <div className="button__helper-text">{helperText}</div>}
        </>
    );
};

export default Button;
