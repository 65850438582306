/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import classNames from 'classnames';
import { FunctionComponent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ApprovedIcon, ReactComponent as ReviewIcon } from '../../../../assets/images/check.svg';
import { ReactComponent as RejectIcon } from '../../../../assets/images/closeBtn.svg';
import { ReactComponent as PendingIcon } from '../../../../assets/images/hourglass.svg';
import { AppRoute } from '../../../../constants/routes';
import { ButtonVariant } from '../../../../types/general';
import { DocumentStatus, WorkflowDocument } from '../../../../types/workflows';
import { buildUrl } from '../../../../utils/navigation';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../../controllers/WorkflowContext';
import Button from '../../Button';
import { FormField } from '../../FormField';
import Modal from '../../Modal';
import { RadioSelect } from '../../RadioSelect';
import { Tooltip } from '../../Tooltip';

interface OwnProps extends TranslationContext, WorkflowContext {
    testId?: string;
    document: WorkflowDocument;
}

const ReviewDocumentOptionComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, testId, document, reviewDocument,
    } = props;

    const navigate = useNavigate();

    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<DocumentStatus | null>(null);
    const [notes, setNotes] = useState<string>('');

    const canAdvanceToConfirmModal = useMemo(() => {
        if (!selectedStatus) return false;

        if (selectedStatus === DocumentStatus.APPROVED) return true;

        return notes.length !== 0;
    }, [notes, selectedStatus]);

    const closeReviewModal = () => {
        setSelectedStatus(null);
        setNotes('');
        setIsReviewModalOpen(false);
        setIsConfirmModalOpen(false);
    };

    const renderConfirmText = () => {
        switch (selectedStatus) {
            case DocumentStatus.APPROVED:
                return t('workflows.documents.review.confirmApproveTitle');
            case DocumentStatus.REJECTED:
                return t('workflows.documents.review.confirmRejectTitle');
            case DocumentStatus.PENDING:
            default:
                return t('workflows.documents.review.confirmPendingTitle');
        }
    };

    const handleSubmitDocumentReview = () => {
        if (!selectedStatus) return;

        reviewDocument(String(document.transactionId), String(document.id), selectedStatus, notes)
            .then((responseError) => {
                if (!responseError) {
                    toast.success(t('workflows.documents.review.successMessage'));
                    closeReviewModal();
                    navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId: String(document.transactionId) }));
                    return;
                }

                if (responseError.errors[0]) {
                    toast.error(responseError.errors[0]?.getMessageTranslated(t));
                } else if (responseError.fields?.notes) {
                    let errorMessage = '';
                    errorMessage += `${t('workflows.documents.notes')}: ${t('errors.Size', { min: 0, max: responseError.fields.notes[0].max })}`;
                    toast.error(errorMessage);
                }

                setIsConfirmModalOpen(false);
            });
    };

    return (
        <>
            <Button
                variant={ButtonVariant.IconBtn}
                extraClasses={classNames(
                    'primary',
                    { active: isReviewModalOpen },
                )}
                onClick={() => setIsReviewModalOpen(true)}
                testId={testId}
            >
                <Tooltip title={t('workflows.documents.review.title')} placement="left">
                    <ReviewIcon />
                </Tooltip>
            </Button>
            <Modal
                open={isReviewModalOpen}
                title={t('workflows.documents.review.title')}
                handleClose={closeReviewModal}
            >
                <div className="content-wrap review-document-option">
                    <h3>{t('workflows.documents.review.subtitle')}</h3>
                    <div className="review-document-option__review-options">
                        <RadioSelect
                            name="document-state"
                            options={[
                                {
                                    value: DocumentStatus.APPROVED, label: t(`workflows.documents.review.status.${DocumentStatus.APPROVED}`), icon: <ApprovedIcon />,
                                },
                                {
                                    value: DocumentStatus.PENDING, label: t(`workflows.documents.review.status.${DocumentStatus.PENDING}`), icon: <PendingIcon />,
                                },
                                {
                                    value: DocumentStatus.REJECTED, label: t(`workflows.documents.review.status.${DocumentStatus.REJECTED}`), icon: <RejectIcon />,
                                },
                            ]}
                            value={selectedStatus}
                            onChange={(_name, value) => setSelectedStatus(value as DocumentStatus)}
                        />
                    </div>
                    <FormField
                        label={t('workflows.documents.review.notes')}
                        value={notes}
                        onChange={(_, v) => setNotes(v)}
                        name="notes"
                        isTextArea
                    />
                    <p>{t('workflows.documents.review.notesWarning')}</p>
                    <div className="review-document-option__buttons">
                        <Button
                            variant={ButtonVariant.Curved}
                            extraClasses="secondary"
                            onClick={() => closeReviewModal()}
                        >
                            {t('workflows.documents.review.cancelAction')}
                        </Button>
                        <Button
                            variant={ButtonVariant.Curved}
                            extraClasses="primary"
                            onClick={() => setIsConfirmModalOpen(true)}
                            disabled={!canAdvanceToConfirmModal}
                        >
                            {t('workflows.documents.review.continueAction')}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={isConfirmModalOpen}
                title={t('workflows.documents.review.title')}
                handleClose={() => setIsConfirmModalOpen(false)}
            >
                <div className="content-wrap review-document-confirmation-modal">
                    <h3>{renderConfirmText()}</h3>
                    <p>{t('workflows.documents.review.validateWarning')}</p>
                    <div className="review-document-option__buttons">
                        <Button
                            variant={ButtonVariant.Curved}
                            extraClasses="secondary"
                            onClick={() => setIsConfirmModalOpen(false)}
                        >
                            {t('workflows.documents.review.cancelAction')}
                        </Button>
                        <Button
                            variant={ButtonVariant.Curved}
                            extraClasses="primary"
                            onClick={() => handleSubmitDocumentReview()}
                        >
                            {t('workflows.documents.review.submitAction')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export const ReviewDocumentOption = withTranslationContext(withWorkflowContext(ReviewDocumentOptionComponent));
