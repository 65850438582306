/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    ChangeEventHandler, FunctionComponent, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { ElementSize } from '../../types/design-system';

type InputBaseAttributes = Partial<Pick<HTMLInputElement, 'required'>>

type OwnProps = InputBaseAttributes & {
    id?: string;
    name: string;
    label: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    testId?: string;
    hasError?: boolean;
    size?: ElementSize;
}

export const Checkbox: FunctionComponent<OwnProps> = (props) => {
    const {
        id,
        label,
        name,
        disabled,
        testId,
        checked,
        onChange,
        hasError,
        size = 'md',
        required = false,
    } = props;

    const [value, setValue] = useState(!!checked);

    useEffect(() => {
        setValue(!!checked);
    }, [checked]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.checked);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className={classNames('checkbox', { 'checkbox--error': hasError }, size)}>
            <input
                className={classNames('checkbox__input', size)}
                type="checkbox"
                id={id}
                aria-label={label}
                name={name}
                checked={value}
                aria-checked={checked}
                onChange={handleOnChange}
                disabled={disabled}
                aria-disabled={disabled}
                data-testid={testId}
                required={required}
                aria-required={required}
            />
            <label htmlFor={id} className={`${required && 'required'}`}>
                {label}
            </label>
        </div>
    );
};
