/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useCallback, useEffect, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import ElectronicIDLogo from '../../../../assets/images/logo-electronic-id.png';
import { EsignatureLoadingModal } from '../../../elements/esignatures/common/EsignatureLoadingModal';
import ElectronicIDIcon from '../../../../assets/images/electronic-logo-short.png';
import { ContractSocketEventType } from '../../../../types/websocket';
import { QesSignatureContext, withQesSignatureContext } from '../../../controllers/esignatures/QesSignatureContext';
import { useWebsocketContext } from '../../../elements/websocket/WebsocketContextProvider';

type OwnProps = TranslationContext & QesSignatureContext;

const QesSignatureVideoValidationCheckingScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, navigateToCheckingVideoSignatureFailure, navigateToQesOtp, requestQesVideoVerification,
    } = props;

    const {
        wsError, addHandler, removeHandler, initConnection,
    } = useWebsocketContext();

    const effectRan = useRef(false);
    const { signerId = '' } = useParams();

    useEffect(() => {
        if (wsError) throw wsError;
    }, [wsError]);

    useEffect(() => {
        addHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_REJECTED, handleRejectedVerificationMessage);
        addHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_COMPLETED, handleSuccessfulVerificationMessage);
        addHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_EXCEPTION, handleRejectedVerificationMessage);

        if (!effectRan.current) {
            requestQesVideoVerification().then(() => {
                initConnection(signerId);
            });
        }

        return () => {
            effectRan.current = true;
            removeHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_REJECTED, handleRejectedVerificationMessage);
            removeHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_COMPLETED, handleSuccessfulVerificationMessage);
            removeHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_SIGNATURE_IDENTIFICATION_EXCEPTION, handleRejectedVerificationMessage);
        };
    }, []);

    const handleRejectedVerificationMessage = useCallback(() => {
        navigateToCheckingVideoSignatureFailure();
    }, []);

    const handleSuccessfulVerificationMessage = useCallback(() => {
        navigateToQesOtp();
    }, []);
    
    return (
        <div className="qes-signature-documents">
            <EsignaturesLayout>
                <div className="qes-signature-layout">
                    <div className="qes-signature-banner">
                        <img src={ElectronicIDLogo} alt="Electronic IDentification logo" height={25} width="auto" />
                    </div>
                    <EsignatureLoadingModal
                        open
                        providerIconSrc={ElectronicIDIcon}
                        message={t('qesSignatureVideoValidationCheckingScreen.title')}
                        disclaimer={t('qesSignatureVideoValidationCheckingScreen.disclaimer')}
                        extraClasses="qes-signature-modal"
                        extraElement={<a href={t('qesSignatureVideoValidationCheckingScreen.disclaimerLinkUrl')} target="_blank" rel="noreferrer">{t('qesSignatureVideoValidationCheckingScreen.disclaimerLinkText')}</a>}
                    />
                </div>
            </EsignaturesLayout>
        </div>
    );
};

export const QesSignatureVideoValidationCheckingScreen = withQesSignatureContext(withTranslationContext(QesSignatureVideoValidationCheckingScreenComponent));
