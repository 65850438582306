/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, useEffect } from 'react';
import { BillingContext, withBillingContext } from '../controllers/BillingContext';

import { LoadingScreen } from '../screens/LoadingScreen';
import { useAuthContext } from '../controllers/AuthenticationContext';

type OwnProps = Pick<BillingContext, 'getUsersWallet'> & {
    children: React.ReactNode;
}

/**
 * Protects a component requiring authentication. Forces user to authenticate to access it.
 *
 * @param props
 * @returns
 */
export const RequireAuthBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        getUsersWallet,
    } = props;

    const {
        user,
        isKeycloakReady,
        isAuthenticated,
        redirectToLogin,
    } = useAuthContext();

    useEffect(() => {
        if (isKeycloakReady && isAuthenticated) {
            getUsersWallet();
        }
    }, [user]);
    
    if (!isKeycloakReady) {
        return <LoadingScreen />;
    }

    if (isKeycloakReady && !isAuthenticated) {
        redirectToLogin();
        return;
    }
    
    return <>{children}</>;
};

export const RequireAuth = withBillingContext(RequireAuthBase);
