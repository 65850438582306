/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEventHandler,
    FunctionComponent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataOrError, ErrorResponse } from '../../../types/errors';
import { ButtonVariant, KeyedObject, OptionSelectField } from '../../../types/general';
import {
    LocationPayload,
    MatrixArticleType,
    PropertyType,
    PropertyTypeEnum,
    PropertyTypology,
    PropertyTypologyEnum,
    SellWorkflowStep,
    WorkflowPropertyPayload,
    WorkflowType,
} from '../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';

import { AppRoute } from '../../../constants/routes';
import { Permissions } from '../../../types/permissions';
import { buildUrl } from '../../../utils/navigation';
import { FormValidationError } from '../../../utils/validations';
import Button from '../../elements/Button';
import { FormField } from '../../elements/FormField';
import { FormSelectField } from '../../elements/FormSelectField';
import HasPermission from '../../elements/HasPermission';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';

type OwnProps = TranslationContext & WorkflowContext;

const WorkflowPropertyScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getWorkflow,
        getLocation,
        getPropertyTypes,
        getPropertyTypologies,
        validateCreateWorkflowProperty,
        createWorkflowProperty,
        editWorkflowProperty,
    } = props;

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const propertyDefaults = {
        propertyType: PropertyTypeEnum.APARTMENT,
        typology: PropertyTypologyEnum.T0,
        address: '',
        district: null,
        county: null,
        parish: null,
        zone: '',
        matrixType: MatrixArticleType.URBAN,
        matrixArticle: '',
        matrixFraction: '',
    };

    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingLocation, setIsLoadingLocation] = useState(true);

    const [isEdit, setIsEdit] = useState(false);
    const [property, setProperty] = useState<WorkflowPropertyPayload | undefined>();
    const [workflowType, setWorkflowType] = useState<WorkflowType>();

    const [districtList, setDistrictList] = useState<OptionSelectField<string | number>[]>([]);
    const [countyList, setCountyList] = useState<OptionSelectField<string | number>[]>([]);
    const [parishList, setParishList] = useState<OptionSelectField<string | number>[]>([]);
    const [propertyTypesResult, setPropertyTypesResult] = useState<DataOrError<PropertyType[], ErrorResponse>>([[], null]);
    const [propertyTypologiesResult, setPropertyTypologiesResult] = useState<DataOrError<PropertyTypology[], ErrorResponse>>([[], null]);

    const CreateSellWorkflowSteps = [
        t(`workflows.SELL.steps.${SellWorkflowStep.GENERAL_INFO}`),
        t(`workflows.SELL.steps.${SellWorkflowStep.PROPERTY}`),
        t(`workflows.SELL.steps.${SellWorkflowStep.PARTICIPANTS}`),
    ];

    const MatrixTypeOptions = [
        {
            value: MatrixArticleType.URBAN,
            label: t('workflows.SELL.matrixTypeOptons.urban'),
        },
        {
            value: MatrixArticleType.RUSTIC,
            label: t('workflows.SELL.matrixTypeOptons.rustic'),
        },
    ];

    useEffect(() => {
        getPropertyTypesList();
        prepareProperty();
    }, []);

    /**
     * Translates property types and converts to an options list
     *
     */
    const translatedPropertyTypeList = useMemo(() => {
        const [propertyTypes] = propertyTypesResult;
        return propertyTypes?.map((item) => {
            return {
                value: item.propertyType,
                label: t(`propertyTypes.${item.propertyType}`),
            };
        }) ?? [];
    }, [propertyTypesResult]);
    
    /**
     * Translates property topologies and converts to an options list
     *
     */
    const translatedPropertyTypologies = useMemo(() => {
        const [typologies] = propertyTypologiesResult;
    
        if (!typologies || typologies.length === 0) {
            return [];
        }
    
        const typologyOptions = [{
            value: '',
            label: t('workflows.selectTypology'),
        }];
    
        return typologyOptions.concat(
            typologies
                .sort((a, b) => a.typology.localeCompare(b.typology))
                .map((item) => ({
                    value: item.typology,
                    label: t(`propertyTypologies.${item.typology}`),
                })),
        );
    }, [propertyTypologiesResult]);

    const prepareProperty = async () => {
        try {
            const [response, requestError] = await getWorkflow(workflowId);

            if (response?.transactionType) {
                setWorkflowType(response?.transactionType);
            }

            if (requestError) {
                toast.error(requestError.errors[0].getMessageTranslated(t));
            }

            if (response?.property) {
                const {
                    propertyType,
                    typology,
                    address,
                    district,
                    county,
                    parish,
                    zone,
                    matrixType,
                    matrixArticle,
                    matrixFraction,
                } = response.property;
                setIsEdit(true);
                setProperty({
                    propertyType,
                    typology,
                    address,
                    zone,
                    matrixType,
                    matrixArticle,
                    matrixFraction,
                    district: district?.id ?? null,
                    county: county?.id ?? null,
                    parish: parish?.id ?? null,
                });

                getPropertyTypologiesList(propertyType);
                
                const districtParams = {};
                const countyParams: LocationPayload = {};
                const parishParams: LocationPayload = {};
                
                if (response.property?.district?.id) {
                    countyParams.l1Id = response.property.district.id;

                    if (response.property?.county?.id) {
                        parishParams.l1Id = countyParams.l1Id;
                        parishParams.l2Id = response.property.county.id;
                    }
                }
                
                const hasCountyAndParishValues = Object.hasOwn(countyParams, 'l1Id') && Object.hasOwn(parishParams, 'l1Id') && Object.hasOwn(parishParams, 'l2Id');

                if (!hasCountyAndParishValues) {
                    const districtListResponse = await getLocation(districtParams);
                    
                    if (districtListResponse[0]) {
                        const districtsIntoOptions = [{
                            value: '',
                            label: t('workflows.selectDistrict'),
                        },
                        ...districtListResponse[0].map((location) => ({
                            value: location.id,
                            label: location.name,
                        }))];
                        setDistrictList(districtsIntoOptions);
                    }
                } else {
                    const [districtListResponse, countyListResponse, parishListResponse] = await Promise.all([
                        getLocation(districtParams),
                        getLocation(countyParams),
                        getLocation(parishParams),
                    ]);

                    if (districtListResponse[0]) {
                        const districtsIntoOptions = [{
                            value: '',
                            label: t('workflows.selectDistrict'),
                        },
                        ...districtListResponse[0].map((location) => ({
                            value: location.id,
                            label: location.name,
                        }))];
                        setDistrictList(districtsIntoOptions);
                    }

                    if (countyListResponse[0]) {
                        const countiesIntoOptions = [{
                            value: '',
                            label: t('workflows.selectCouncil'),
                        },
                        ...countyListResponse[0].map((location) => ({
                            value: location.id,
                            label: location.name,
                        }))];
                        setCountyList(countiesIntoOptions);
                    }

                    if (parishListResponse[0]) {
                        const parishIntoOptions = [{
                            value: '',
                            label: t('workflows.selectCouncil'),
                        },
                        ...parishListResponse[0].map((location) => ({
                            value: location.id,
                            label: location.name,
                        }))];
                        setParishList(parishIntoOptions);
                    }
                }
            } else {
                setProperty(propertyDefaults);
                getPropertyTypologiesList(PropertyTypeEnum.APARTMENT);
                filterLocation();
            }
        } finally {
            setIsLoading(false);
            setIsLoadingLocation(false);
        }
    };

    /**
     * Get and set the Property Types List
     *
     * @remarks
     * This list is used for the selectbox options
     */
    const getPropertyTypesList = async () => {
        const res = await getPropertyTypes();
        setPropertyTypesResult(res);
    };

    /**
     * Get and set the Property Typologies List
     *
     * @remarks
     * This list is used for the selectbox options
     */
    const getPropertyTypologiesList = async (propertyType: PropertyTypeEnum, selectFirstTypology?: boolean) => {
        const res = await getPropertyTypologies(propertyType);
        setPropertyTypologiesResult(res);
        if (selectFirstTypology && res[0]) {
            setProperty((prev) => {
                if (!prev) return prev;
                return {
                    ...prev,
                    typology: undefined,
                };
            });
        }
    };

    /**
     * Get and set the District List
     *
     * @remarks
     * getLocation request (with empty params) is called only on page init
     */
    const filterLocation = async () => {
        const params: KeyedObject<number> = {};
        const [locations] = await getLocation(params);

        if (locations) {
            const locationsIntoOptions = [{
                value: '',
                label: t('workflows.selectDistrict'),
            },
            ...locations.map((location) => {
                return {
                    value: location.id,
                    label: location.name,
                };
            })];
            setDistrictList(locationsIntoOptions);
        }
    };

    /**
     * Get and set the Council/County List
     *
     * @remarks
     * getLocation request (with params) is called when a district is selected
     */
    const handleDistrictChange = async (value: string) => {
        if (!property) return;
        const validValue = Number(value) !== 0 ? Number(value) : null;

        setCountyList([]);
        setParishList([]);
        setProperty({
            ...property,
            district: validValue,
        });

        if (validValue) {
            const params: KeyedObject<number> = {};
            params.l1Id = validValue;
            const [locations] = await getLocation(params);

            if (locations) {
                const locationsIntoOptions = [{
                    value: '',
                    label: t('workflows.selectCouncil'),
                },
                ...locations.map((location) => {
                    return {
                        value: location.id,
                        label: location.name,
                    };
                })];

                setCountyList(locationsIntoOptions);
            }
        }
    };

    /**
     * Get and set Parish List
     *
     * @remarks
     *  getLocation request (with params) is called when a council / county is selected
     */
    const handleCouncilChange = async (value: string) => {
        if (!property) return;
        const validValue = Number(value) !== 0 ? Number(value) : null;

        setParishList([]);
        setProperty({
            ...property,
            county: validValue,
        });

        if (validValue) {
            const params: KeyedObject<number> = {};
            params.l1Id = property.district ?? 0;
            params.l2Id = validValue;

            const [locations] = await getLocation(params);

            if (locations) {
                const locationsIntoOptions = [{
                    value: '',
                    label: t('workflows.selectParish'),
                },
                ...locations.map((location) => {
                    return {
                        value: location.id,
                        label: location.name,
                    };
                })];

                setParishList(locationsIntoOptions);
            }
        }
    };

    /**
     * Set Parish on field change
     *
     */
    const handleParishChange = async (value: string) => {
        if (!property) return;
        const validValue = Number(value) !== 0 ? Number(value) : null;
        setProperty({
            ...property,
            parish: validValue,
        });
    };

    /**
     * Submit Workflow Property
     *
     * @remarks
     * Submit property fields
     */
    const onFormSubmit: FormEventHandler = async (e) => {
        e.preventDefault();
        if (!workflowId || !property) return;

        setIsLoading(true);

        const payload = { ...property };
        const errors = validateCreateWorkflowProperty(payload);
        setErrorsForm(errors);
        if (!errors) {
            if (isEdit) {
                editProperty(payload);
            } else {
                createProperty(payload);
            }
        }

        setIsLoading(false);
    };

    /**
     * Update the workflow property field change
     *
     * @remarks
     * Verify the field changed and update in workflow property state
     */
    const onFieldsChange = (name: string, value: string | number) => {
        setProperty((prev) => {
            if (!prev) return prev;
            return {
                ...prev,
                [name]: value,
            };
        });
        if (name === 'propertyType') getPropertyTypologiesList(value as PropertyTypeEnum, true);
    };
    
    /**
     * Create Workflow Property
     *
     * @remarks
     * POST Property request
     */
    const createProperty = async (payload: WorkflowPropertyPayload) => {
        const [newProperty, submittingError] = await createWorkflowProperty(workflowId, payload);

        if (newProperty) {
            navigate(buildUrl(AppRoute.WorkflowParticipantsStep, { workflowId }));

            return;
        }

        if (submittingError) {
            toast.error(submittingError.errors[0].getMessageTranslated(t));
        }
    };
    /**
     * Edit Workflow Property
     *
     * @remarks
     * PATCH Property request
     */
    const editProperty = async (payload: WorkflowPropertyPayload) => {
        const [edittedProperty, submittingError] = await editWorkflowProperty(workflowId, payload);

        if (edittedProperty) {
            const searchQuery = new URLSearchParams();
            searchQuery.append('isEdit', 'true');
            navigate({
                pathname: buildUrl(AppRoute.WorkflowParticipantsStep, { workflowId }),
                search: searchQuery.toString(),
            });

            return;
        }

        if (submittingError) {
            toast.error(submittingError.errors[0].getMessageTranslated(t));
        }
    };

    const submitBtn = (
        <Button
            isSubmit
            variant={ButtonVariant.Curved}
            testId="submit-btn"
        >
            {t('workflows.SELL.nextBtn')}
        </Button>
    );

    const cancelBtn = (
        <Button
            onClick={() => navigate(-1)}
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            testId="cancel-btn"
        >
            {t('workflows.SELL.cancelBtn')}
        </Button>
    );

    return (
        <HasPermission permissions={[Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]}>
            <form
                className="form"
                onSubmit={onFormSubmit}
                data-testid="sell-workflow-info-form"
                autoComplete="off"
            >
                <DefaultLayout primaryBtn={submitBtn} secondaryBtn={cancelBtn} isLoading={isLoading}>
                    <div className="workflow-screen--create">
                        {workflowId && (
                            <div className="workflow-screen--create__property-step">
                                <h1>
                                    {isEdit ? t(`workflows.${workflowType}.editWorkflow`) : t(`workflows.${workflowType}.createWorkflow`)}
                                </h1>
                                <HorizontalStepper
                                    steps={CreateSellWorkflowSteps}
                                    activeStep={1}
                                />
                                <h3 className="step-title">{t('workflows.SELL.property')}</h3>
                                <div className="form__fields">
                                    <FormSelectField
                                        name="propertyType"
                                        label={t('workflows.SELL.propertyType')}
                                        value={property?.propertyType}
                                        options={translatedPropertyTypeList}
                                        errors={errorsForm}
                                        onChange={onFieldsChange}
                                        required
                                    />
                                    {translatedPropertyTypologies.length > 0 && (
                                        <>
                                            <FormSelectField
                                                name="typology"
                                                label={t('workflows.SELL.typology')}
                                                value={property?.typology}
                                                options={translatedPropertyTypologies}
                                                errors={errorsForm}
                                                onChange={onFieldsChange}
                                                required
                                            />
                                            <span className="separator" />
                                        </>
                                    )}
                                    <h3 className="step-title">{t('workflows.SELL.location')}</h3>
                                    <FormField
                                        name="address"
                                        value={property?.address}
                                        label={t('workflows.SELL.address')}
                                        required
                                        errors={errorsForm}
                                        onChange={onFieldsChange}
                                        helperText={t('workflows.SELL.addressHelper')}
                                    />
                                    <FormField
                                        name="zone"
                                        value={property?.zone}
                                        label={t('workflows.SELL.zone')}
                                        errors={errorsForm}
                                        onChange={onFieldsChange}
                                    />

                                    <FormSelectField
                                        name="district"
                                        label={t('workflows.SELL.district')}
                                        required
                                        isLoading={isLoadingLocation}
                                        value={property?.district}
                                        options={districtList}
                                        onChange={(_, value) => handleDistrictChange(String(value))}
                                        errors={errorsForm}

                                    />
                                    <FormSelectField
                                        name="county"
                                        label={t('workflows.SELL.county')}
                                        required
                                        isLoading={isLoadingLocation}
                                        value={property?.county}
                                        options={countyList}
                                        onChange={(_, value) => handleCouncilChange(String(value))}
                                        errors={errorsForm}
                                    />
                                    <FormSelectField
                                        name="parish"
                                        label={t('workflows.SELL.parish')}
                                        required
                                        isLoading={isLoadingLocation}
                                        value={property?.parish}
                                        options={parishList}
                                        onChange={(_, value) => handleParishChange(String(value))}
                                        errors={errorsForm}
                                    />
                                    <FormField
                                        name="matrixArticle"
                                        label={t('workflows.SELL.matrixArticle')}
                                        onChange={onFieldsChange}
                                        value={property?.matrixArticle}
                                        errors={errorsForm}
                                    />
                                    <FormSelectField
                                        name="matrixType"
                                        label={t('workflows.SELL.matrixType')}
                                        value={property?.matrixType}
                                        options={MatrixTypeOptions}
                                        errors={errorsForm}
                                        onChange={onFieldsChange}
                                    />
                                    <FormField
                                        name="matrixFraction"
                                        value={property?.matrixFraction}
                                        label={t('workflows.SELL.matrixFraction')}
                                        onChange={onFieldsChange}
                                        errors={errorsForm}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </DefaultLayout>
            </form>
        </HasPermission>
    );
};

export const WorkflowPropertyScreen = withTranslationContext(withWorkflowContext(WorkflowPropertyScreenBase));
