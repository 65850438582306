/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg';
import { AppRoute } from '../../../../constants/routes';
import { ContractConfigOption } from '../../../../types/contracts';
import { DataOrError, ErrorResponse } from '../../../../types/errors';
import { ButtonVariant } from '../../../../types/general';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import { OrganizationsContext, withOrganizationsContext } from '../../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import Button from '../../Button';
import Modal from '../../Modal';
import { Tooltip } from '../../Tooltip';
import { useContractContext } from '../ContractContextProvider';

interface OwnProps extends TranslationContext, OrganizationsContext, ContractsContext {
}

/**
 *  Button action to delete a contract with confirmation
 * @param props
 */
const DeleteContractOptionBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, deleteContract, organizationSelected } = props;

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteResult, setDeleteResult] = useState<DataOrError<void, ErrorResponse>>();

    const navigate = useNavigate();

    const {
        contract,
    } = useContractContext();

    const onOpenConfirmationModal = () => {
        setIsOpenModal(true);
    };

    const onDeleteSuccess = () => {
        if (organizationSelected) {
            navigate(AppRoute.Contracts);
            return;
        }
        navigate(AppRoute.Contracts);
    };

    const onConfirmDelete = () => {
        setIsDeleting(true);
        deleteContract(String(contract?.id)).then((res) => {
            setDeleteResult(res);
            setIsDeleting(false);

            // If no error detected succeed
            if (res[0] === undefined) {
                onDeleteSuccess();
            }
        });
    };

    const onCancel = () => {
        setIsOpenModal(false);
    };

    function renderError() {
        return deleteResult?.[1]?.errors.map((e) => (
            <p key={e.errorCode} data-testid="error-message" className="field-error">
                {e.getMessageTranslated(t)}
            </p>
        ));
    }

    return (
        <>
            <Button
                variant={ButtonVariant.IconBtn}
                data-step={ContractConfigOption.DeleteContract}
                extraClasses={classNames('danger',
                    { active: isOpenModal })
                }
                onClick={onOpenConfirmationModal}
                testId="delete-btn"
            >
                <Tooltip title={t('contractCreate.deleteContractTitle')} placement="left">
                    <DeleteIcon className="stroked" />
                </Tooltip>
            </Button>
            <Modal
                open={isOpenModal}
                title={t('contractCreate.deleteContractTitle')}
                handleClose={() => onCancel()}
            >
                <div className="content-wrap">
                    <p>{t('contractCreate.deleteContractText')}</p>
                    <p className="warning-msg">{t('contractCreate.deleteContractWarning')}</p>
                    {renderError()}
                    {!isDeleting && (
                        <>
                            <Button
                                onClick={onCancel}
                                variant={ButtonVariant.Curved}
                                extraClasses="secondary"
                                testId="cancel-btn"
                            >
                                {t('contractCreate.deleteContractCancel')}
                            </Button>
                            <Button
                                onClick={onConfirmDelete}
                                variant={ButtonVariant.Curved}
                                extraClasses="danger"
                                testId="confirm-btn"
                            >
                                {t('contractCreate.deleteContractSubmit')}
                            </Button>
                        </>
                    )}
                    {isDeleting && <CircularProgress color="primary" data-testid="loading" />}
                </div>
            </Modal>
        </>
    );
};

export const DeleteContractOption = withTranslationContext(withOrganizationsContext(withContractsContext(DeleteContractOptionBase)));
