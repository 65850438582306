/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FieldValidator } from './validators/Validator';
import { KeyedObject } from '../types/general';

export type FieldValue = string | number | boolean | unknown[];

export interface FormValidator {
    validations?: string[];
    func?: (fieldValue: FieldValue) => boolean;
    regex?: RegExp;
    length?: FormValidatorLength;
    min?: number;
    max?: number;
    decimalPoints?: number;
    size?: number;
}

export interface FormValidatorLength {
    lowerLimit: number;
    upperLimit: number;
}

export type FormValidatorError = {
    errorCode?: number;
    typeOfViolation?: string;
    size?: number;
    min?: number;
    max?: number;
    decimalPoints?: number;
}

export enum FormValidatorErrorType {
    NotBlank = 'NotBlank',
    NotEmpty = 'NotEmpty',
    NotDecimal = 'NotDecimal',
    SizeExact = 'SizeExact',
    Size = 'Size',
    SizeMin = 'SizeMin',
    SizeMax = 'SizeMax',
    Max = 'Max',
    Min = 'Min',
    Pattern = 'Pattern',
    Checked = 'Checked',
    PasswordsDontMatch = 'PasswordsDontMatch',
    NotNumber = 'NotNumber',
    DecimalPoints = 'DecimalPoints',
    FiscalCodeByCountry = 'FiscalCodeByCountry'
}

export enum ValidationType {
    NotBlank = 'NOT_BLANK',
    NotEmpty = 'NOT_EMPTY',
    NotDecimal = 'NOT_DECIMAL',
    Length = 'LENGTH',
    Max = 'MAX',
    Min = 'MIN',
    Regex = 'REGEX',
    Checked = 'CHECKED',
    Function = 'FUNCTION',
    Number = 'NUMBER',
    Decimal = 'DECIMAL',
    SizeExact = 'SIZE_EXACT',
}

export type FormValidationError = {
    fields: {
        [field: string]: FormValidatorError[];
    };
}

export const Regex = {
    Float: /^-?\d+(\.\d+)?$/,
    Decimal: /^-?\d+[.|,]?\d{0,2}$/,
    Email: /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/,
    Coords: /^-?\d+[.]?\d{0,8}$/,
    PostalCode: /^[1-8]\d{3}-\d{3}$/,
    Swift: /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/,
    ExternalId: /^(?:[a-zA-Z0-9_-]+)?$/g,
    Language: /^([a-z]{2,3})/i,
};

export const validateField = (fieldName: string, fieldValue: FieldValue, validator: FormValidator) => {
    return FieldValidator.applyAllForValidations(fieldName, fieldValue, validator);
};

export const validateForm = (data: object, validations: KeyedObject<FormValidator>): KeyedObject<FormValidatorError[]> | null => {
    const errors: KeyedObject<FormValidatorError[]> = {};

    Object.entries(data).forEach((entry) => {
        const [field, fieldValue] = entry;
        
        if (field in validations) {
            if (errors) {
                if (validations[field]) {
                    const error = validateField(field, fieldValue, validations[field]);
                    if (error?.length) {
                        errors[field] = error;
                    }
                }
            }
        }
    });

    if (Object.keys(errors).length === 0) return null;
    return errors;
};

export const getErrorsForField = (field: string, errors?: FormValidationError|null): FormValidatorError[] => {
    return errors?.fields?.[field] ?? [];
};
