/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ButtonVariant, ListResponse } from '../../../types/general';
import {
    OrganizationRoleFieldsName,
    Role,
    RolePayload,
} from '../../../types/roles';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { RolesContext, withRolesContext } from '../../controllers/RolesContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import Button from '../../elements/Button';
import { ButtonWithMenuList } from '../../elements/ButtonWithMenuList';
import { FloatingAddAction } from '../../elements/FloatingAddAction';
import { FormField } from '../../elements/FormField';
import { FormValidationError } from '../../../utils/validations';
import Modal from '../../elements/Modal';
import { buildUrl } from '../../../utils/navigation';
import { preparePageTitle } from '../../../utils/route';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { Permissions } from '../../../types/permissions';

interface OwnProps extends TranslationContext, OrganizationsContext, RolesContext { }

export interface OrganizationFields {
    [OrganizationRoleFieldsName.Name]: string;
    [OrganizationRoleFieldsName.Description]: string;
    [OrganizationRoleFieldsName.OrganizationId]: number;
}

const initialFieldsState: OrganizationFields = {
    [OrganizationRoleFieldsName.Name]: '',
    [OrganizationRoleFieldsName.Description]: '',
    [OrganizationRoleFieldsName.OrganizationId]: 0,
};

const RolesScreenComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        getRoles,
        organizationSelected,
        submitNewOrganizationRole,
        validateCreateOrUpdateRoleForm,
        deleteRole,
    } = props;

    const navigate = useNavigate();

    const [roles, setRoles] = useState<ListResponse<Role>>({
        cursor: '',
        results: [],
        totalAmount: 0,
    });
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [organizationRoleFields, setOrganizationRoleFields] = useState<OrganizationFields>(initialFieldsState);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [openDeleteModalConfirm, setOpenDeleteModalConfirm] = useState(false);
    const [roleBeingDeleted, setRoleBeingDeleted] = useState<Role|null>(null);

    const canManageRoles = useUserHasPermission([Permissions.MANAGE_ROLE]);

    useEffect(() => {
        document.title = preparePageTitle(t('rolesScreen.title'));
        getRolesList();
    }, []);

    const getRolesList = () => {
        getRoles(Number(organizationSelected?.organization.id)).then((rolesData) => {
            if (rolesData) setRoles(rolesData);
        });
    };

    const handleOpenModal = () => {
        setOpenModalConfirm(true);
    };

    const handleCloseModal = () => {
        setOpenModalConfirm(false);
    };

    const onFieldChange = (name: string, value: string) => {
        setOrganizationRoleFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const onCreateSuccess = (newRole: Role) => {
        toast.success(t('rolesScreen.createSuccess'));
        onFieldChange(OrganizationRoleFieldsName.Name, '');
        onFieldChange(OrganizationRoleFieldsName.Description, '');
        handleCloseModal();
        navigate(buildUrl(AppRoute.OrganizationRole, {
            roleId: String(newRole.id),
        }));
    };

    const onRequestFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!organizationSelected) return;

        const fieldsTransformed: RolePayload = {
            [OrganizationRoleFieldsName.Name]: organizationRoleFields[OrganizationRoleFieldsName.Name].trim(),
            [OrganizationRoleFieldsName.Description]: organizationRoleFields[OrganizationRoleFieldsName.Description].trim(),
            [OrganizationRoleFieldsName.OrganizationId]: organizationSelected.organization.id,
        };

        const errors = validateCreateOrUpdateRoleForm({ ...fieldsTransformed });
        setErrorsForm(errors);

        if (errors) return;

        const [data, submitError] = await submitNewOrganizationRole(fieldsTransformed);

        if (submitError) {
            onRequestFailure(submitError.errors[0].getMessageTranslated(t));
        } else {
            onCreateSuccess(data);
        }
    };

    const handleNavigationToRoleScreen = (id: number) => {
        navigate(buildUrl(AppRoute.OrganizationRole, {
            roleId: String(id),
        }));
    };

    // ================== Delete operation

    const handleOpenDeleteModal = (role: Role) => {
        setOpenDeleteModalConfirm(true);
        setRoleBeingDeleted(role);
    };

    const handleCloseModalConfirm = () => {
        setOpenDeleteModalConfirm(false);
        setRoleBeingDeleted(null);
    };

    const handleConfirm = async () => {
        if (!roleBeingDeleted) {
            toast.error(t('rolesScreen.noRoleSelectedError'));
            return;
        }
        
        const deleteRoleError = await deleteRole(roleBeingDeleted?.id);

        if (deleteRoleError) {
            onDeleteFailure(deleteRoleError.errors[0].getMessageTranslated(t));
        } else {
            onDeleteSuccess();
        }
        handleCloseModalConfirm();
    };

    const onDeleteSuccess = () => {
        toast.success(t('rolesScreen.deleteSuccess'));
        getRolesList();
    };

    const onDeleteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    return (
        <div className="roles-screen">
            <div className="roles-screen__header">
                <h1>{t('rolesScreen.title')}</h1>
                {canManageRoles && (
                    <Button
                        variant={ButtonVariant.Curved}
                        extraClasses="large-add-btn shorter-btn"
                        testId="btn-add-role"
                        onClick={handleOpenModal}
                    >
                        {t('rolesScreen.createRole')}
                    </Button>
                )}
            </div>
            <ul data-testid="roles-list">
                {roles.results.map((role) => (
                    <li
                        className="card"
                        onClick={() => handleNavigationToRoleScreen(role.id)}
                        key={`role-${role.id}`}
                        data-testid={`role-${role.id}`}
                    >
                        <div>
                            <h3>{role.name}</h3>
                            <p>{role.description}</p>
                        </div>
                        {canManageRoles && (
                            <ButtonWithMenuList
                                options={[
                                    {
                                        value: t('rolesScreen.editMenuItem'),
                                        action: () => handleNavigationToRoleScreen(role.id),
                                        testId: 'edit-btn',
                                    },
                                    {
                                        value: t('rolesScreen.deleteMenuItem'),
                                        action: () => handleOpenDeleteModal(role),
                                        testId: 'delete-btn',
                                    },
                                ]}
                            />
                        )}
                    </li>
                ))}
            </ul>
            <FloatingAddAction type="button" onClick={handleOpenModal} testId="btn-add-role-mobile" />
            <Modal
                open={openModalConfirm}
                title={t('rolesScreen.createTitle')}
                handleClose={handleCloseModal}
            >
                <form
                    className="form"
                    onSubmit={onFormSubmit}
                >
                    <div className="form__fields">
                        <FormField
                            name={OrganizationRoleFieldsName.Name}
                            value={organizationRoleFields[OrganizationRoleFieldsName.Name]}
                            onChange={onFieldChange}
                            maxLength={250}
                            placeholder={t('rolesScreen.roleName')}
                            errors={errorsForm}
                        />
                        <FormField
                            name={OrganizationRoleFieldsName.Description}
                            value={organizationRoleFields[OrganizationRoleFieldsName.Description]}
                            onChange={onFieldChange}
                            maxLength={250}
                            placeholder={t('rolesScreen.roleDescription')}
                        />
                    </div>
                    <div className="form__bottom">
                        <Button
                            isSubmit
                            variant={ButtonVariant.Curved}
                        >
                            {t('general.create')}
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                open={openDeleteModalConfirm}
                title={t('rolesScreen.deleteConfirmTitle')}
                handleClose={handleCloseModalConfirm}
                handleConfirm={handleConfirm}
            >
                <p data-testid="delete-modal">{t('rolesScreen.deleteConfirmText', {
                    name: roleBeingDeleted?.name,
                })}
                </p>
            </Modal>
        </div>
    );
};

export const RolesScreen = withTranslationContext(withRolesContext(withOrganizationsContext(RolesScreenComponent)));
