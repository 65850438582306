/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { isEqual } from 'lodash';
import {
    FC, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';
import { BillingAction, BillingEvent, BillingEventFilterParams } from '../../../types/billing';
import { OrderOptions } from '../../../types/general';
import { formatToLocalDate } from '../../../utils/date';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Button } from '../../elements/Button';
import { DatePicker } from '../../elements/DatePicker';
import { Drawer } from '../../elements/Drawer';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { ErrorResponse } from '../../../types/errors';

export const SubscriptionHistoryDefaultFilters: BillingEventFilterParams = {
    _cursor: '',
    _limit: '',
    _sort: OrderOptions.DESCENDING,
    billingAction: BillingAction.DEBIT,
};

type OwnProps = TranslationContext & BillingContext;

const OrganizationSubscriptionHistoryScreenBase: FC<OwnProps> = (props) => {
    const {
        t,
        getBillingEvents,
        organizationWallet,
    } = props;

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [filters, setFilters] = useState<BillingEventFilterParams>(SubscriptionHistoryDefaultFilters);
    const [billingEvents, setBillingEvents] = useState<BillingEvent[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [listLoadingError, setListLoadingError] = useState<ErrorResponse>();

    const prevFilters = useRef<BillingEventFilterParams>();

    useEffect(() => {
        const { _cursor, ...rest } = filters;
        const { _cursor: prevCursor, ...prevRest } = prevFilters.current || {};
        
        if (!isEqual(rest, prevRest)) {
            setBillingEvents([]);
            prepareSubscriptionHistory(true);
        }

        prevFilters.current = filters;
    }, [organizationWallet, filters]);

    const prepareSubscriptionHistory = async (reset?: boolean) => {
        if (!organizationWallet) return;
        
        setIsLoading(true);
        setListLoadingError(undefined);

        const [data, error] = await getBillingEvents(String(organizationWallet?.id) || '', { ...filters, _cursor: reset ? '' : filters._cursor });
        setIsLoading(false);

        if (error) {
            setListLoadingError(error);
            return;
        }

        setBillingEvents((prev) => (!reset ? [...prev, ...data.results] : [...data.results]));
        setFilters((prev) => ({
            ...prev,
            _cursor: data.cursor,
        }));
    };

    const renderDescription = (event: BillingEvent) => {
        const billingAction = t(`subscriptionHistory.actions.${event.billingAction}`);
        const billingAsset = t(`subscriptionHistory.assets.${event.billingAsset}`);

        return `${billingAction} ${billingAsset}`;
    };

    const handleTabChange = (_: SyntheticEvent, value: BillingAction) => {
        setFilters((prev) => ({
            ...prev,
            billingAction: value,
        }));
    };

    const handleDateChange = (date: Date | null, field: string) => {
        if (!date || isNaN(date?.getTime())) {
            return;
        }
        setFilters((prev) => ({
            ...prev,
            [field]: date ? date.toISOString() : '',
        }));
    };

    const handleEndDateChange = (date: Date | null, field: string) => {
        if (!date || isNaN(date?.getTime())) {
            return;
        }
        date?.setHours(23, 59, 59, 999);
        handleDateChange(date, field);
    };

    return (
        <section className="subscription-history-screen">
            <div className="subscription-history-screen__header">
                <div className="subscription-history-screen__header__title">
                    <h1>{t('subscriptionHistory.title')}</h1>
                    <Button
                        extraClasses="circle-btn filters-trigger-btn"
                        onClick={() => setIsOpenFilters(true)}
                        testId="mobile-trigger-btn"
                    >
                        <FiltersIcon />
                    </Button>
                </div>
                <TabContext value={String(filters.billingAction)}>
                    <TabList onChange={handleTabChange} data-testid="tab-list">
                        <Tab label={t('subscriptionHistory.tabs.DEBIT')} value={BillingAction.DEBIT} />
                        <Tab label={t('subscriptionHistory.tabs.PURCHASE')} value={BillingAction.PURCHASE} />
                    </TabList>
                </TabContext>
            </div>
            <div className="subscription-history-screen__content">
                <div className="subscription-history-screen__content__filters">
                    <div className="subscription-history-screen__content__filters__filter">
                        <h3>{t('subscriptionHistory.filters.dateRange')}</h3>
                        <DatePicker
                            name="minDate"
                            value={filters.minDate}
                            onChange={handleDateChange}
                            label={t('subscriptionHistory.filters.minDateLabel')}
                        />
                        <DatePicker
                            name="maxDate"
                            value={filters.maxDate}
                            onChange={handleDateChange}
                            label={t('subscriptionHistory.filters.maxDateLabel')}
                        />
                    </div>
                </div>
                <div className="subscription-history-screen__content__header">
                    <h3>{t('subscriptionHistory.labels.date')}</h3>
                    <h3>{t('subscriptionHistory.labels.user')}</h3>
                    <h3>{t('subscriptionHistory.labels.description')}</h3>
                    <h3>{t('subscriptionHistory.labels.value')}</h3>
                </div>
                {isLoading && (<LoadingCircles size="m" variant="primary" />)}
                {!isLoading && billingEvents.length === 0 && (
                    <p>{t('subscriptionHistory.noResultsFound')}</p>
                )}
                {!isLoading && listLoadingError && (
                    <p>{listLoadingError.errors.map((e) => e.getMessageTranslated(t))}</p>
                )}
                <InfiniteScrollWrapper
                    hasMore={!!filters._cursor}
                    requestMore={prepareSubscriptionHistory}
                    extraClasses="subscription-history-screen__content__list"
                >
                    {billingEvents.map((event) => (
                        <article className="subscription-history-screen__content__list__item" key={event.id} data-testid={`billing-event-${event.id}`}>
                            <div className="subscription-history-screen__content__list__item__date">
                                <h4>{t('subscriptionHistory.labels.date')}</h4>
                                <p>{formatToLocalDate(event.createdDate)}</p>
                            </div>
                            <div className="subscription-history-screen__content__list__item__name">
                                <h4>{t('subscriptionHistory.labels.user')}</h4>
                                <p>{event.userFullName}</p>
                            </div>
                            <div className="subscription-history-screen__content__list__item__description">
                                <h4>{t('subscriptionHistory.labels.description')}</h4>
                                <p>{renderDescription(event)}</p>
                            </div>
                            <div className="subscription-history-screen__content__list__item__value">
                                <p>-{event.amount}</p>
                            </div>
                        </article>
                    ))}
                </InfiniteScrollWrapper>
            </div>
            <Drawer
                open={isOpenFilters}
                handleClose={() => setIsOpenFilters(false)}
                title={t('general.filter')}
                testId="filters-drawer"
            >
                <h3>{t('subscriptionHistory.filters.dateRange')}</h3>
                <DatePicker
                    name="minDate"
                    value={filters.minDate}
                    onChange={handleDateChange}
                    label={t('subscriptionHistory.filters.minDateLabel')}
                />
                <DatePicker
                    name="maxDate"
                    value={filters.maxDate}
                    onChange={handleEndDateChange}
                    label={t('subscriptionHistory.filters.maxDateLabel')}
                />
            </Drawer>
        </section>
    );
};

export const OrganizationSubscriptionHistoryScreen = withBillingContext(withTranslationContext(OrganizationSubscriptionHistoryScreenBase));
