/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, SyntheticEvent, useEffect, useState,
} from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Modal from '../Modal';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import Button from '../Button';
import { ButtonVariant, OptionRadioField } from '../../../types/general';
import {
    Participant,
    ParticipantMemberPayload, ParticipantPayload, ParticipantType, WorkflowParticipantRole,
} from '../../../types/workflows';
import { RadioGroupWithSearch } from '../RadioGroupWithSearch';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';

interface OwnProps extends TranslationContext, OrganizationsContext {
    open: boolean;
    onModalClose: () => void;
    onSubmit: (p: Partial<ParticipantPayload>) => void;
    onMemberSubmit: (m: ParticipantMemberPayload) => void;
    participants: Participant[];
}

const AddParticipantModalComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
        getOrganizationMembers,
        open,
        onModalClose,
        onSubmit,
        onMemberSubmit,
        participants,
    } = props;

    const [type, setType] = useState<ParticipantType>(ParticipantType.EXTERNAL);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState<WorkflowParticipantRole>(WorkflowParticipantRole.ADMIN);
    const [memberUserId, setMemberUserId] = useState<string>('');
    const [isLoadingMembers, setIsLoadingMembers] = useState(true);
    const [memberOptions, setMemberOptions] = useState<OptionRadioField<string>[]>([]);

    useEffect(() => {
        if (!open) {
            setEmail('');
            setName('');
            setRole(WorkflowParticipantRole.ADMIN);
        }
    }, [open]);

    useEffect(() => {
        loadMembers();
    }, [participants]);

    /**
     * Get organization members
     *
     * @remarks
     * Get organization members on the first render
     */
    const loadMembers = async () => {
        if (!organizationSelected?.organization?.id) return;
        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id);
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => {
                const isDisabled = participants.some((p) => p.userId === option.userId);
                const disabledText = isDisabled ? ` ${t('workflows.participants.participantAlreadyInWorkflow')}` : '';

                return {
                    value: option.userId,
                    label: `${option.firstName} ${option.lastName}${disabledText}`,
                    disabled: isDisabled,
                };
            });
            setMemberOptions(membersTransformedOptions);
        }
        setIsLoadingMembers(false);
    };

    /**
     * Filter organization members
     *
     * @remarks
     * Filters members using search word
     */
    const filterOrganizationMembers = async (search: string = '') => {
        if (!organizationSelected?.organization?.id) return;
        setIsLoadingMembers(true);

        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id, search);
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => (
                {
                    value: option.userId,
                    label: `${option.firstName} ${option.lastName}`,
                }));
            setMemberOptions(membersTransformedOptions);
        }
        setIsLoadingMembers(false);
    };
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (type === ParticipantType.INTERNAL) {
            if (!memberUserId) return;

            onMemberSubmit({ userId: memberUserId, participantRole: role });
            return;
        }

        onSubmit({
            name,
            email,
            participantRole: role,
        });
    };

    const roleOptions = () => {
        return Object.keys(WorkflowParticipantRole).filter((r) => r !== WorkflowParticipantRole.TRANSACTION_OWNER).map((r) => {
            return {
                value: r,
                label: t(`participantRole.${r}`),
            };
        });
    };

    const handleChangeTab = (_: SyntheticEvent, newValue: ParticipantType) => {
        setType(newValue);
    };

    return (
        <Modal
            open={open}
            title={t('workflows.participants.addParticipant')}
            handleClose={onModalClose}
            extraClasses="add-participant-modal"
        >
            <TabContext value={type}>
                <Box>
                    <TabList onChange={handleChangeTab} data-testid="tab-list">
                        <Tab label={t('workflows.participants.external')} value={ParticipantType.EXTERNAL} />
                        {organizationSelected?.organization?.id && (
                            <Tab label={t('workflows.participants.internal')} value={ParticipantType.INTERNAL} />
                        )}
                    </TabList>
                </Box>
                <TabPanel value={ParticipantType.EXTERNAL}>
                    <form
                        className="form"
                        onSubmit={handleSubmit}
                    >
                        <FormField
                            name="name"
                            maxLength={250}
                            label={t('workflows.participants.labelName')}
                            value={name}
                            onChange={(_, value) => setName(value)}
                        />
                        <FormField
                            name="email"
                            label={t('workflows.participants.labelEmail')}
                            value={email}
                            onChange={(_, value) => setEmail(value)}
                        />
                        <FormSelectField
                            name="role"
                            label={t('workflows.participants.labelRole')}
                            options={roleOptions()}
                            onChange={(_, value) => setRole(value as WorkflowParticipantRole)}
                        />
                        <Button
                            isSubmit
                            variant={ButtonVariant.Curved}
                            extraClasses="primary"
                            testId="submit-btn"
                            disabled={!name || !role || !email}
                        >
                            {t('workflows.participants.addParticipant')}
                        </Button>
                    </form>
                </TabPanel>
                <TabPanel value={ParticipantType.INTERNAL}>
                    <form
                        className="form"
                        onSubmit={handleSubmit}
                    >
                        <RadioGroupWithSearch
                            name="member"
                            label={t('workflows.participants.internal')}
                            options={memberOptions}
                            searchPlaceholder={t('workflows.participants.placeholderMemberSearch')}
                            value={memberUserId}
                            onChange={(_, id) => setMemberUserId(String(id))}
                            onSearch={filterOrganizationMembers}
                            isLoading={isLoadingMembers}
                        />
                        <FormSelectField
                            name="role"
                            label={t('workflows.participants.labelRole')}
                            options={roleOptions()}
                            onChange={(_, value) => setRole(value as WorkflowParticipantRole)}
                        />
                        <Button
                            isSubmit
                            variant={ButtonVariant.Curved}
                            extraClasses="primary"
                            testId="submit-btn"
                            disabled={!memberUserId || !role}
                        >
                            {t('workflows.participants.addParticipant')}
                        </Button>
                    </form>
                </TabPanel>
            </TabContext>
          
        </Modal>
    );
};

export const AddParticipantModal = withTranslationContext(withOrganizationsContext(AddParticipantModalComponent));
