/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    Suspense,
    lazy,
    useEffect,
    useRef,
    useState,
} from 'react';
import Stack from '@mui/material/Stack';
import { VariableSizeList } from 'react-window';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';

import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { DeleteContractOption } from '../../elements/contracts/options/DeleteContractOption';
import { DraftContractActionBar } from '../../elements/contracts/DraftContractActionBar';
import { ErrorResponse } from '../../../types/errors';
import { GeneralConfigOption } from '../../elements/contracts/options/GeneralConfigOption';
import HasPermission from '../../elements/HasPermission';
import { LoadingScreen } from '../LoadingScreen';
import { Permissions } from '../../../types/permissions';
import { SignersOption } from '../../elements/contracts/options/SignersOption';
import { SwapContractOption } from '../../elements/contracts/options/SwapContractOption';
import { useContractContext } from '../../elements/contracts/ContractContextProvider';
import { withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { SignerActionBar } from '../../elements/contracts/SignerActionBar';
import { ContractState, Placeholder } from '../../../types/contracts';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { Banner } from '../../elements/Banner';
import { BannerType } from '../../../types/general';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { DownloadOption } from '../../elements/contracts/options/DownloadOption';

const PdfViewerWrapper = lazy(() => import('../../elements/pdfViewer/PdfViewerWrapper'));

interface OwnProps extends ContractsContext, TranslationContext {
}

const DraftContractScreenBase: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;

    const { contractId = '' } = useParams();

    const pdfPagesListRef = useRef<VariableSizeList>();
    const [isFetchingContract, setIsFetchingContract] = useState(false);
    const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(null);

    const {
        contractPdfFileUrl,
        prepareContract,
        prepareContractPdf,
        getContractTypesList,
        placeholderList,
        updatePlaceholderInList,
        pendingPlaceholder,
        setCurrentPage,
        contract,
        updatePendingPlaceholder,
    } = useContractContext();

    const canManage = useUserHasPermission([Permissions.MANAGE_CONTRACT, Permissions.MANAGE_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]);

    useEffect(() => {
        if (errorResponse) throw errorResponse;
    }, [errorResponse]);

    useEffect(() => {
        init();
    }, [contractId]);

    const init = async () => {
        setIsFetchingContract(true);
        const contractResponse = await prepareContract(contractId);
        if (contractResponse) {
            setErrorResponse(contractResponse);
            setIsFetchingContract(false);
            return;
        }

        const pdfResponse = await prepareContractPdf(contractId);
        if (pdfResponse) {
            setErrorResponse(pdfResponse);
            setIsFetchingContract(false);
            return;
        }

        const contractTypesResponse = await getContractTypesList();
        if (contractTypesResponse) {
            setErrorResponse(contractTypesResponse);
            setIsFetchingContract(false);
            return;
        }

        setErrorResponse(null);
        setIsFetchingContract(false);
    };

    const handleUpdatePlaceholder = (placeholder: Placeholder) => {
        updatePlaceholderInList(placeholder, onUpdatePlaceholderFailure);
    };

    const onUpdatePlaceholderFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    return (
        <Suspense fallback={<LoadingScreen />}>
            <HasPermission permissions={[Permissions.VIEW_CONTRACT, Permissions.MANAGE_CONTRACT, Permissions.MANAGE_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]}>
                <DefaultLayout>
                    <div className="contract-screen contract-screen--create light-gradient-bg information-page">
                        {isFetchingContract && <LoadingScreen />}
                        {!isFetchingContract && contractPdfFileUrl && !errorResponse && (
                            <>
                                <div className="pdf-viewer-max-width-content">
                                    <div className="contract-screen__pdf-viewer pdf-viewer-wrap">
                                        <PdfViewerWrapper
                                            fileUrl={contractPdfFileUrl}
                                            pdfPagesListRef={pdfPagesListRef}
                                            placeholderList={placeholderList}
                                            updatePlaceholderList={handleUpdatePlaceholder}
                                            updateCurrentPage={setCurrentPage}
                                            pendingPlaceholder={pendingPlaceholder || undefined}
                                            isPlaceholderDraggable={contract?.contractState === ContractState.DRAFT}
                                            updatePendingPlaceholder={updatePendingPlaceholder}
                                        />
                                        <div className="contract-screen__pdf-viewer__buttons">
                                            {canManage && !pendingPlaceholder && (
                                                <Stack
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="flex-end"
                                                    spacing={0.5}
                                                    data-testid="contract-options"
                                                >
                                                    <GeneralConfigOption />
                                                    <SignersOption />
                                                    {contractPdfFileUrl && (
                                                        <DownloadOption
                                                            fileUrl={contractPdfFileUrl}
                                                            fileName={contract?.name}
                                                        />
                                                    )}
                                                    <SwapContractOption />
                                                    <DeleteContractOption />
                                                </Stack>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {pendingPlaceholder ? <SignerActionBar /> : <DraftContractActionBar />}
                                {pendingPlaceholder && (
                                    <Banner type={BannerType.INFO} title={t('contractCreate.placeholderBannerTitle')}>
                                        {t('contractCreate.placeholderBannerDescription')}
                                    </Banner>
                                )}
                            </>
                        )}
                    </div>
                </DefaultLayout>
            </HasPermission>
        </Suspense>
    );
};

export const DraftContractScreen = withTranslationContext(withOrganizationsContext(withContractsContext(DraftContractScreenBase)));
