/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    FunctionComponent,
    UIEvent,
    useState,
} from 'react';
import { LoadingCircles } from './LoadingCircles';

interface OwnProps {
    children?: React.ReactNode;
    hasMore: boolean;
    requestMore(): Promise<void>;
    extraClasses?: string;
}

const InfiniteScrollWrapper: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        hasMore,
        requestMore,
        extraClasses,
    } = props;

    const [loading, setLoading] = useState(false);

    const handleScroll = (e: UIEvent) => {
        if (loading || !hasMore) return;

        const { currentTarget: { scrollHeight, scrollTop, clientHeight } } = e;
        const bottom = scrollHeight - (scrollTop + clientHeight);

        if (bottom <= 100) {
            setLoading(true);
            requestMore()
                .catch()
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div
            className={`infinite-scroll-wrapper ${extraClasses}`}
            onScroll={handleScroll}
            data-testid="infinite-scroll-wrapper"
        >
            {children}
            {loading && (
                <LoadingCircles size="xs" variant="primary" />
            )}
        </div>
    );
};

export default InfiniteScrollWrapper;
