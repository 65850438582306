/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { ReactComponent as CancelContractIcon } from '../../../../assets/images/forbidden-sign.svg';
import { ContractConfigOption } from '../../../../types/contracts';
import { DataOrError, ErrorResponse } from '../../../../types/errors';
import { ButtonVariant, KeyedObject } from '../../../../types/general';
import {
    FormValidationError, FormValidatorError, validateForm, ValidationType,
} from '../../../../utils/validations';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import { OrganizationsContext, withOrganizationsContext } from '../../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import Button from '../../Button';
import { FormField } from '../../FormField';
import Modal from '../../Modal';
import { Tooltip } from '../../Tooltip';
import { useContractContext } from '../ContractContextProvider';

type OwnProps = TranslationContext & OrganizationsContext & ContractsContext;

/**
 * Button action to cancel a contract with confirmation
 * @param props
 */
const CancelContractOptionBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, cancelContract } = props;

    const {
        contract,
        prepareContract,
    } = useContractContext();

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [cancelResult, setCancelResult] = useState<DataOrError<void, ErrorResponse>>();

    const [cancelNotes, setCancelNotes] = useState<string>('');
    const [cancelNotesError, setCancelNotesError] = useState<FormValidationError | null>(null);

    const onOpenConfirmationModal = () => {
        setIsOpenModal(true);
    };

    const onCancelContractSuccess = () => {
        handleCloseModal();
        prepareContract(String(contract?.id));
    };

    const onConfirmContractCancelation = () => {
        const formError: KeyedObject<FormValidatorError[]> | null = validateForm({ notes: cancelNotes }, { notes: { validations: [ValidationType.NotBlank] } });

        if (formError && Object.keys(formError).length > 0) {
            setCancelNotesError({ fields: formError });
        } else {
            setIsCanceling(true);

            cancelContract(String(contract?.id), cancelNotes).then((res) => {
                setCancelResult(res);
                setIsCanceling(false);

                // If no error detected succeed
                if (res[0] === undefined) {
                    onCancelContractSuccess();
                }
            });
        }
    };

    const handleCloseModal = () => {
        setIsOpenModal(false);
        setIsCanceling(false);
        setCancelNotes('');
        setCancelNotesError(null);
        setCancelResult(undefined);
    };

    function renderError() {
        return cancelResult?.[1]?.errors.map((e) => (
            <p key={e.errorCode} data-testid="error-message" className="field-error">
                {e.getMessageTranslated(t)}
            </p>
        ));
    }

    return (
        <>
            <Button
                variant={ButtonVariant.IconBtn}
                data-step={ContractConfigOption.CancelContract}
                extraClasses={classNames('danger',
                    { active: isOpenModal })
                }
                onClick={onOpenConfirmationModal}
                testId="cancel-contract-button"
            >
                <Tooltip title={t('contractCreate.cancelContractTitle')} placement="left">
                    <CancelContractIcon className="stroked" />
                </Tooltip>
            </Button>
            <Modal
                open={isOpenModal}
                title={t('contractCreate.cancelContractTitle')}
                handleClose={() => handleCloseModal()}
            >
                <div className="content-wrap">
                    <p>{t('contractCreate.cancelContractText')}</p>
                    {renderError()}
                    <FormField
                        label={t('contractCreate.cancelContractNotes')}
                        value={cancelNotes}
                        onChange={(_, value) => setCancelNotes(value)}
                        name="notes"
                        isTextArea
                        errors={cancelNotesError}
                    />
                    <p className="warning-msg">{t('contractCreate.cancelContractWarning')}</p>
                    {!isCanceling && (
                        <>
                            <Button
                                onClick={handleCloseModal}
                                variant={ButtonVariant.Curved}
                                extraClasses="secondary"
                                testId="cancel-btn"
                            >
                                {t('contractCreate.cancelContractCancel')}
                            </Button>
                            <Button
                                onClick={onConfirmContractCancelation}
                                variant={ButtonVariant.Curved}
                                extraClasses="danger"
                                testId="confirm-btn"
                            >
                                {t('contractCreate.cancelContractSubmit')}
                            </Button>
                        </>
                    )}
                    {isCanceling && (
                        <CircularProgress color="primary" data-testid="loading" />
                    )}
                </div>
            </Modal>
        </>
    );
};

export const CancelContractOption = withTranslationContext(withOrganizationsContext(withContractsContext(CancelContractOptionBase)));
