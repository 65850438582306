/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/arrow-left.svg';
import { Button } from '../../Button';

interface OwnProps{
    children: ReactNode;
    button?: ReactNode;
    goBackPath?: string;
}

const SigningLayout: FunctionComponent<OwnProps> = (props) => {
    const {
        children,
        button,
        goBackPath,
    } = props;

    const navigate = useNavigate();
    const location = useLocation();

    /**
    * Function called when the "Go Back" button is clicked.
    * Uses the navigate function to go back in the navigation history.
    *
    */
    const handleGoBack = () => {
        navigate(goBackPath ?? location.state?.from ?? -1);
    };

    return (
        <>
            <div className="signing-layout" data-testid="signing-layout">
                <header
                    className="signing-layout__header"
                    data-testid="header-signing"
                >
                    <Button
                        extraClasses="signing-layout__header__btn-back"
                        onClick={handleGoBack}
                        testId="back-btn"
                    >
                        <ArrowLeftIcon />
                    </Button>
                    <div className="small-btn signing-layout__header__action-btn-wrap">
                        {button}
                    </div>
                </header>
                <div className="signing-layout__children">
                    {children}
                </div>
            </div>
        </>
    );
};

export { SigningLayout };
