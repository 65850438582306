/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';

export const stringToDecimal = (stringNumber: string): number => {
    return String(stringNumber).includes(',') ? Number(String(stringNumber).replace(',', '.')) : Number(stringNumber);
};

export const objectToParams = (obj?: KeyedObject<unknown>, useQ = true): string => {
    if (obj === undefined) return '';

    const params = new URLSearchParams('');
    Object.entries(obj).filter((entries) => entries[1]).forEach((objEntries) => {
        const key = objEntries[0];
        const value = objEntries[1];
        // Array specific logic
        if (Array.isArray(objEntries[1])) {
            // This may need to be refactored in order to represent array parameters as param[0]=1&param[1]=2, etc.
            objEntries[1].forEach((arrEl) => {
                params.append(key, String(arrEl));
            });
            return;
        }
        // Non array logic
        params.append(key, String(value));
    });

    return `${useQ ? '?' : ''}${params.toString()}`;
};

export const paramsToObject = (searchParams: URLSearchParams, baseObject?: object): KeyedObject<unknown> => {
    const obj: KeyedObject<unknown> = {};

    searchParams.forEach((value, key) => {
        const allValues = searchParams.getAll(key);
        obj[key] = allValues.length > 1 || (baseObject && Array.isArray((baseObject as Record<string, unknown>)[key])) ? allValues : value;
    });
    
    if (baseObject) {
        Object.keys(obj).forEach((key) => {
            if (!Object.keys(baseObject).includes(key)) {
                delete obj[key];
            }
        });
    }

    return obj;
};

export const getInitialsFromFirstAndLastName = (name: string): string => {
    const names = name.trim().split(' ');

    if (names.length === 0) {
        return '';
    }

    if (names.length === 1) {
        // just returns the 1st letter
        return names[0].charAt(0);
    }

    // returns the 1st letter of the first name and the 1st letter of the last name
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
};
