/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { LoadingCircles } from '../../LoadingCircles';
import UnlockitLogo from '../../../../assets/images/unlockit-logo-short-white.svg';

interface OwnProps {
    /**
     * The source for icon representing the provider Unlockit interacts with
     */
    providerIconSrc: string;
    /**
     * Handles modal open state
     */
    open: boolean;
    /**
     * The main message
     */
    message: string;
    /**
     * A disclaimer message
     */
    disclaimer: string;
    extraClasses?: string;
    extraElement?: ReactNode;
}

/**
 * Renders a full screen loading for Chave Móvel Digital signature context
 *
 * @param props props
 * @returns
 */
export const EsignatureLoadingModal: FunctionComponent<OwnProps> = (props) => {
    const {
        open, message, disclaimer, providerIconSrc, extraClasses = '', extraElement,
    } = props;
    return (
        <dialog open={open} id="esignature-loading-modal" className={`esignature-loading-modal ${extraClasses}`} data-testid="esignature-loading-modal">
            <div className="esignature-loading-modal__container">
                <div className="esignature-loading-modal__icons">
                    <img src={UnlockitLogo} height="25px" width="auto" alt="unlockit-logo" data-testid="unlockit-icon" />
                    <LoadingCircles size="xs" variant="neutral" />
                    <img src={providerIconSrc} height="25px" width="auto" alt="provider-icon" data-testid="provider-icon" />
                </div>
                <h2 data-testid="message">{message}</h2>
                <p data-testid="disclaimer">{disclaimer}</p>
                {!!extraElement && extraElement}
            </div>
        </dialog>
    );
};
