/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Avatar } from '@mui/material';
import {
    FunctionComponent,
    useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../constants/routes';
import { ButtonVariant } from '../../../types/general';
import { OrganizationAccess } from '../../../types/organizations';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../../elements/Button';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { RadioSelect } from '../../elements/RadioSelect';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = TranslationContext & OrganizationsContext;

const ChangeOrganizationScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, organizationSelected, getUserOrganizations, selectOrganization,
    } = props;

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [organizationAccesses, setOrganizationAccesses] = useState<OrganizationAccess[]>([]);
    const [cursor, setCursor] = useState<string>('');
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | undefined>(organizationSelected?.organization.id);

    useEffect(() => {
        document.title = preparePageTitle(t('changeOrganization.title'));
        getOrganizationAccessesList();
    }, []);

    const organizationOptions = useMemo(() => {
        return organizationAccesses.map((access) => {
            return {
                value: access.organization.id,
                label: access.organization.name,
                icon: (
                    <Avatar alt={`${access.organization.name} avatar`} src={access.organization.logoUrl ? access.organization.logoUrl : undefined}>
                        {access.organization.name.split(' ')[0][0]}{access.organization.name.split(' ')[1] ? access.organization.name.split(' ')[1][0] : ''}
                    </Avatar>),
            };
        });
    }, [organizationAccesses]);

    const getOrganizationAccessesList = async () => {
        setIsLoading(true);
        const organizationData = await getUserOrganizations(cursor);

        setIsLoading(false);

        setOrganizationAccesses([...organizationAccesses, ...organizationData.results]);
        setCursor(organizationData.cursor);
    };

    const handleChangeOrganization = () => {
        const orgSelected = organizationAccesses.find((org) => org.organization.id === selectedOrganizationId);

        if (!orgSelected) return;

        selectOrganization(orgSelected);
        navigate(AppRoute.Index);
    };

    return (
        <div className="change-organization-screen">
            <h1>{t('changeOrganization.title')}</h1>
            <div className="change-organization-screen__list">
                {isLoading && <LoadingCircles size="m" variant="primary" />}
                {organizationAccesses.length > 0 && (
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={getOrganizationAccessesList}
                    >
                        <RadioSelect
                            name="organization"
                            options={organizationOptions}
                            value={selectedOrganizationId}
                            onChange={(_, v) => setSelectedOrganizationId(v as number)}
                        />
                    </InfiniteScrollWrapper>
                )}
            </div>
            <footer>
                {!isLoading && (
                    <Button
                        disabled={!selectedOrganizationId && isLoading}
                        onClick={handleChangeOrganization}
                        variant={ButtonVariant.Basic}
                        extraClasses="primary"
                        testId="change-organization-submit"
                    >
                        {t('changeOrganization.submitAction')}
                    </Button>
                )}
            </footer>
        </div>
    );
};

export const ChangeOrganizationScreen = withOrganizationsContext(withTranslationContext(ChangeOrganizationScreenComponent));
