/*
*
* @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

import React, { FunctionComponent } from 'react';
import { Modal } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/images/closeBtn.svg';
import Button from './Button';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { ButtonVariant } from '../../types/general';
import { Tooltip } from './Tooltip';

interface OwnProps extends TranslationContext {
    open: boolean;
    title?: string;
    handleClose: () => void;
    handleConfirm?: () => void;
    children: React.ReactNode;
    /**
     * "signed-modal"|
     */
    extraClasses?: string;
}

const ModalComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        open,
        title,
        handleClose,
        handleConfirm,
        t,
        children,
        extraClasses,
    } = props;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={`modal-confirm ${extraClasses}`}
        >
            <div className="modal-confirm__wrapper">
                <div className="modal-confirm__wrapper__header">
                    <h3>{title}</h3>
                    <Tooltip title={t('modalConfirm.close')} placement="bottom">
                        <button type="button" className="modal-confirm__wrapper__header__close-btn" data-testid="modal-close-btn" onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </Tooltip>
                </div>
                <div className="modal-confirm__wrapper__content">
                    {children}
                </div>
                {handleConfirm && (
                    <div className="modal-confirm__wrapper__buttons">
                        <Button
                            onClick={handleConfirm}
                            variant={ButtonVariant.Curved}
                            testId="modal-confirm-btn"
                            extraClasses="primary"
                        >
                            {t('modalConfirm.confirm')}
                        </Button>
                    </div>
                )}
              
            </div>
        </Modal>
    );
};

export default withTranslationContext(ModalComponent);
