/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AesSignatureContext, AesSignatureContextProvider } from './AesSignatureContext';
import { requestSignatureUrl, validateAesUrl } from '../../../services/esignatures/aes';

import { AppRoute } from '../../../constants/routes';
import { ErrorResponse } from '../../../types/errors';
import { buildUrl } from '../../../utils/navigation';
import { useCornerstoneApi } from '../../../api';

type AesSignaturePathParams = {
    contractId: string;
    signerId: string;
}

interface OwnProps {
    children: React.ReactNode;
}

const AesSignatureController: FunctionComponent<OwnProps> = (props) => {
    const { children } = props;
    const params = useParams() as AesSignaturePathParams;
    const navigate = useNavigate();
    const CornerstoneApiInstance = useCornerstoneApi();

    const requestSignature: AesSignatureContext['requestSignature'] = async (payload) => {
        try {
            await CornerstoneApiInstance.post(requestSignatureUrl(params.signerId, payload.otpType), {});
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const validateSignature: AesSignatureContext['validateSignature'] = async (payload) => {
        try {
            await CornerstoneApiInstance.post(validateAesUrl(params.signerId, payload.otpType), { otp: payload.otpCode.toLowerCase() });
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const navigateToContract = () => {
        navigate(buildUrl(AppRoute.ShowContract, params));
    };
    
    const navigateToContractSignedPage = () => {
        const searchQuery = new URLSearchParams();
        searchQuery.append('signSuccess', 'true');
        return navigate({
            pathname: buildUrl(AppRoute.ShowContract, params),
            search: searchQuery.toString(),
        });
    };

    const navigateToAesSignaturePhoneScreen = () => {
        navigate(buildUrl(AppRoute.AesSignaturePhone, params));
    };

    const navigateToAesSignatureEmailScreen = () => {
        navigate(buildUrl(AppRoute.AesSignatureEmail, params));
    };

    const navigateToAesContextScreen = () => {
        navigate(buildUrl(AppRoute.AesSignatureContext, params));
    };

    return (
        <AesSignatureContextProvider
            value={{
                requestSignature,
                validateSignature,
                navigateToContract,
                navigateToContractSignedPage,
                navigateToAesSignaturePhoneScreen,
                navigateToAesSignatureEmailScreen,
                navigateToAesContextScreen,
            }}
        >
            {children}
        </AesSignatureContextProvider>
    );
};

export { AesSignatureController };
