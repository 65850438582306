/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';

import classNames from 'classnames';
import { ReactNode, useMemo, useState } from 'react';
import { OptionRadioField } from '../../types/general';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import Button from './Button';

type RadioAttributes = Partial<Pick<HTMLInputElement, 'required'>>

interface OwnProps<T> extends TranslationContext, RadioAttributes {
    name: string;
    extraTitleElement?: ReactNode;
    label?: string;
    options: OptionRadioField<T>[];
    value?: T;
    onChange: (name: string, value: T) => void;
    isCollapsible?: boolean;
    disabled?: boolean;
    disabledText?: string;
}

const RadioSelectBase = <T, >(props: OwnProps<T>) => {
    const {
        t,
        name,
        extraTitleElement,
        label,
        options,
        value,
        onChange,
        isCollapsible = false,
        required,
        disabled = false,
        disabledText,
    } = props;
    
    const [isCollapsed, setIsCollapsed] = useState(isCollapsible);

    const radioOptions = useMemo(() => {
        return isCollapsed ? options.slice(0, 3) : options;
    }, [options, isCollapsed]);

    const expandOptionsList = () => {
        setIsCollapsed(false);
    };

    return (
        <div className="radio-select" data-testid="radio-select-container">
            <div className="radio-select__top">
                {label && (
                    <label htmlFor={name} className={`radio-select-label ${required && 'required'}`}>
                        {label}
                    </label>
                )}
                {extraTitleElement}
            </div>
            <RadioGroup
                name={name}
                aria-labelledby={name}
                data-testid={`radio-${name}`}
            >
                {radioOptions.map((option) => (
                    <div
                        key={String(option.value)}
                        className={classNames(`radio-select__item ${option.extraClasses}`,
                            {
                                selected: option.value === value,
                                disabled: option.disabled || disabled,
                            })}
                    >
                        <FormControlLabel
                            value={option.disabled || disabled ? 'disabled' : option.value}
                            control={<Radio data-testid={`radio-${option.value}`} />}
                            label={(
                                <div className="radio-select__item__label">
                                    {option.icon}
                                    <div>
                                        {option.label}
                                        {option.disableMessage && option.disabled && <span className="error">{option.disableMessage}</span>}
                                    </div>
                                </div>
                            )}
                            onChange={() => onChange(name, option.value)}
                            disabled={option.disabled || disabled}
                            checked={option.value === value}
                        />
                    </div>
                ))}
            </RadioGroup>
            {disabled && disabledText && (<span className="disable-warning">{disabledText}</span>)}
            {isCollapsed && (
                <Button
                    extraClasses="show-more-btn"
                    onClick={expandOptionsList}
                >
                    {t('general.showMore')}
                </Button>
            )}
        </div>
    );
};

export const RadioSelect = withTranslationContext(RadioSelectBase);
