/**
 *
 * @Copyright 2024 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    useEffect,
    useRef,
    useState,
} from 'react';

import { isEqual } from 'lodash';
import { ElementPosition } from '../types/general';

interface ElementBoundaries {
    x0: number;
    y0: number;
    xf: number;
    yf: number;
}

/**
 * Hook that applies dragging functionality to an HTMLElement
 */
export const useDrag = (callbackFunctions: {
    dragOver?: (position: ElementPosition) => void;
    dragOutsideOfYAxisOfParentElement?: (offsetAxisYOfParentElement: number, position: Partial<ElementPosition>) => void;
    dragOutsideOfXAxisOfParentElement?: (offsetAxisXOfParentElement: number, position: Partial<ElementPosition>) => void;
} = {}) => {
    const { dragOver, dragOutsideOfYAxisOfParentElement, dragOutsideOfXAxisOfParentElement } = callbackFunctions;

    const ref = useRef<HTMLDivElement | null>(null);

    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState<ElementPosition | null>(null);

    let currentX = 0;
    let currentY = 0;
    let lastX = 0;
    let lastY = 0;

    useEffect(() => {
        if (!ref.current) return;

        setPosition({ top: ref.current.offsetTop, left: ref.current.offsetLeft });

        ref.current.addEventListener('mousedown', startMouseDrag);
        ref.current.addEventListener('touchstart', startFingerDrag);

        // remove any event leftovers
        return () => {
            if (ref.current) {
                ref.current.removeEventListener('mousedown', startMouseDrag);
                ref.current.removeEventListener('touchstart', startFingerDrag);
            }

            document.removeEventListener('mouseup', stopDrag);
            document.removeEventListener('mousemove', draggingWithMouse);
            document.removeEventListener('touchend', stopDrag);
            document.removeEventListener('touchmove', draggingWithFinger);
        };
    }, [ref]);

    function checkMultiTouch(event: TouchEvent): boolean {
        return event.touches && event.touches.length > 1;
    }

    function startFingerDrag(e: TouchEvent) {
        e.preventDefault();

        if (checkMultiTouch(e)) {
            // Multi-touch detected, exit dragging
            return;
        }

        setIsDragging(true);

        // Store the initial position and calculate the offset
        lastX = e.touches[0].clientX;
        lastY = e.touches[0].clientY;
        
        document.addEventListener('touchend', stopDrag);
        document.addEventListener('touchmove', draggingWithFinger);
    }

    function startMouseDrag(e: MouseEvent) {
        e.preventDefault();

        setIsDragging(true);

        // Get the mouse cursor position at startup:
        lastX = e.clientX;
        lastY = e.clientY;

        document.addEventListener('mouseup', stopDrag);
        document.addEventListener('mousemove', draggingWithMouse);
    }

    function draggingWithFinger(e: TouchEvent) {
        if (!ref.current) return;

        if (checkMultiTouch(e)) {
            // Multi-touch detected, exit dragging
            stopDrag();
            return;
        }
        
        // calculate the new cursor position:
        currentX = lastX - e.touches[0].clientX;
        currentY = lastY - e.touches[0].clientY;
        lastX = e.touches[0].clientX;
        lastY = e.touches[0].clientY;

        // set the element's new position:
        const top = ref.current.offsetTop - currentY;
        const left = ref.current.offsetLeft - currentX;
        ref.current.style.top = `${top}px`;
        ref.current.style.left = `${left}px`;

        setPosition({ left, top });
    }

    function draggingWithMouse(e: MouseEvent) {
        e.preventDefault();
        if (!ref.current) return;

        // calculate the new cursor position:
        currentX = lastX - e.clientX;
        currentY = lastY - e.clientY;
        lastX = e.clientX;
        lastY = e.clientY;

        // set the element's new position:
        const top = ref.current.offsetTop - currentY;
        const left = ref.current.offsetLeft - currentX;
        ref.current.style.top = `${top}px`;
        ref.current.style.left = `${left}px`;

        setPosition({ left, top });
    }

    function stopDrag() {
        // Stop moving when mouse button is released OR when touch drag has ended
        document.removeEventListener('mouseup', stopDrag);
        document.removeEventListener('mousemove', draggingWithMouse);
        document.removeEventListener('touchend', stopDrag);
        document.removeEventListener('touchmove', draggingWithFinger);

        checkPageBoundariesAndReposition();
    }

    /**
    * Checks for boundaries in an HTML element.
    *
    * @remarks
    * Useful when checking if a dragged element is being dragged
    * outside of the parent container such as the page boundaries.
    */
    function checkPageBoundariesAndReposition() {
        if (!ref.current) return;
        
        const boundaries: ElementBoundaries = {
            x0: ref.current.parentElement?.querySelector('canvas')?.clientLeft ?? 0,
            y0: ref.current.parentElement?.querySelector('canvas')?.clientTop ?? 0,
            xf: ref.current.parentElement?.querySelector('canvas')?.clientWidth ?? 0,
            yf: ref.current.parentElement?.querySelector('canvas')?.clientHeight ?? 0,
        };

        const isOutsideOfBoundariesX = checkXBoundariesAndReposition(boundaries);
        const isOutsideOfBoundariesY = checkYBoundariesAndReposition(boundaries);

        const newPosition = { top: ref.current.offsetTop, left: ref.current.offsetLeft };

        if (dragOver && !isOutsideOfBoundariesX && !isOutsideOfBoundariesY && !isEqual(position, newPosition)) {
            dragOver(newPosition);
        }

        setPosition(newPosition);
        setIsDragging(false);
    }

    /**
     * Checks for boundaries in an HTML element in X axis
     */
    function checkXBoundariesAndReposition(boundaries: ElementBoundaries) {
        if (!ref.current) return false;

        if (ref.current.offsetLeft < boundaries.x0) {
            if (dragOutsideOfXAxisOfParentElement) {
                dragOutsideOfXAxisOfParentElement(ref.current.offsetLeft, { top: ref.current.offsetTop, left: 0 });
            }

            ref.current.style.left = '0px';
            return true;
        }
        const bottomBoundaryOffsetX = ref.current.offsetLeft + ref.current.offsetWidth - boundaries.xf;
        if (bottomBoundaryOffsetX > 0) {
            if (dragOutsideOfXAxisOfParentElement) {
                dragOutsideOfXAxisOfParentElement(bottomBoundaryOffsetX, { top: ref.current.offsetTop, left: ref.current.offsetLeft - bottomBoundaryOffsetX });
            }

            ref.current.style.left = `${ref.current.offsetLeft - bottomBoundaryOffsetX}px`;
            return true;
        }
        
        return false;
    }

    /**
     * Checks for boundaries in an HTML element in Y axis
     */
    function checkYBoundariesAndReposition(boundaries: ElementBoundaries) {
        if (!ref.current) return false;
        
        if (ref.current.offsetTop < boundaries.y0) {
            if (dragOutsideOfYAxisOfParentElement) {
                dragOutsideOfYAxisOfParentElement(ref.current.offsetTop, { left: ref.current.offsetLeft });
            }

            ref.current.style.top = '0px';
            return true;
        }
        const bottomBoundaryOffsetY = ref.current.offsetTop + ref.current.offsetHeight - boundaries.yf;
        if (bottomBoundaryOffsetY > 0) {
            if (dragOutsideOfYAxisOfParentElement) {
                dragOutsideOfYAxisOfParentElement(bottomBoundaryOffsetY, { left: ref.current.offsetLeft });
            }

            ref.current.style.top = `${ref.current.offsetTop - bottomBoundaryOffsetY}px`;

            return true;
        }

        return false;
    }

    return { isDragging, position, ref };
};
