/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNumber } from 'lodash';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import HasPermission from '../../elements/HasPermission';
import { Permissions } from '../../../types/permissions';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import {
    Participant, ParticipantPayload, WorkflowParticipantRole, SellWorkflowStep,
    ParticipantPermissionsList,
    ResourcePermissionsType,
    ParticipantMemberPayload,
} from '../../../types/workflows';
import { ParticipantCard } from '../../elements/participants/ParticipantCard';
import { ReactComponent as AddUserIcon } from '../../../assets/images/add-user.svg';
import { AddParticipantModal } from '../../elements/participants/AddParticipantModal';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { AppRoute } from '../../../constants/routes';
import { buildUrl } from '../../../utils/navigation';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';

type OwnProps = TranslationContext & WorkflowContext;

const WorkflowParticipantsStepScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getWorkflowParticipants,
        addNewParticipant,
        addOrganizationMemberAsParticipant,
        getWorkflowParticipantPermissions,
    } = props;

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const canManage = useUserHasPermission([Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]);

    const { user } = useAuthContext();
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [cursor, setCursor] = useState('');
    const [isModalAddingNewParticipantOpen, setIsModalAddingNewParticipantOpen] = useState(false);
    const [participantPermissions, setParticipantPermissions] = useState<ParticipantPermissionsList | null>(null);

    const canManageOtherParticipantPermissions = useMemo(() => {
        if (canManage) return true;

        if (participantPermissions?.transactionPermissions.some((e) => e === ResourcePermissionsType.MANAGE)) {
            return true;
        }

        return false;
    }, [canManage, participantPermissions]);

    const canTransferOwner = useMemo(() => {
        const ownerParticipant = participants.find((p) => p.participantRole === WorkflowParticipantRole.TRANSACTION_OWNER);

        if (ownerParticipant?.userId === user?.id) return true;

        return false;
    }, [user, participants]);

    useEffect(() => {
        fetchParticipants(true);
    }, []);

    const fetchParticipants = async (reset?: boolean) => {
        const [participantsData] = await getWorkflowParticipants(workflowId, reset ? '' : cursor);
        if (participantsData) {
            if (reset) {
                setParticipants([...participantsData.results || []]);
            } else {
                setParticipants((prev) => [...prev, ...participantsData.results || []]);
            }

            setCursor(participantsData.cursor);
        }

        getWorkflowParticipantPermissions(workflowId).then((permissionsResponse) => {
            const [permissions] = permissionsResponse;

            if (!permissions) {
                return;
            }

            setParticipantPermissions(permissions);
        });
    };

    const goBackBtn = (
        <Button
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={() => navigate(-1)}
            testId="actionBackToViewWorkflowList"
        >
            <span className="btn-content">
                {t('workflows.SELL.cancelBtn')}
            </span>
        </Button>
    );

    const finishBtn = (
        <Button
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            testId="actionGoToWorkflowView"
            onClick={() => navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId }))}
        >
            <span className="btn-content">
                {t('workflows.SELL.concludeBtn')}
            </span>
        </Button>
    );

    const CreateSellWorkflowSteps = [
        t(`workflows.SELL.steps.${SellWorkflowStep.GENERAL_INFO}`),
        t(`workflows.SELL.steps.${SellWorkflowStep.PROPERTY}`),
        t(`workflows.SELL.steps.${SellWorkflowStep.PARTICIPANTS}`),
    ];

    /**
     * Handles new participant submission
     *
     * Calls controller method and tries to add a new participant
     *
     * @param newParticipant
     * @returns
     */
    const handleSubmitParticipant = async (newParticipant: Partial<ParticipantPayload>) => {
        const { name, email, participantRole } = newParticipant;

        const workflowIdValue = Number(workflowId);

        if (!name || !email || !participantRole || !isNumber(workflowIdValue)) {
            return;
        }

        const [addedParticipant, error] = await addNewParticipant(workflowId, {
            name,
            email,
            participantRole,
        });

        if (addedParticipant) {
            setIsModalAddingNewParticipantOpen(false);
            fetchParticipants(true);
            toast.success(t('workflows.participants.participantSuccessfullyAdded'));
        }

        if (error) {
            toast.error(error.errors[0].getMessageTranslated(t));
        }
    };

    /**
     * Handles new participant organization member submission
     *
     * Calls controller method and tries to add a new participant
     *
     * @param newParticipant
     * @returns
     */
    const handleSubmitParticipantMember = async (newParticipant: ParticipantMemberPayload) => {
        const { userId, participantRole } = newParticipant;

        const workflowIdValue = Number(workflowId);

        if (!userId || !participantRole || !isNumber(workflowIdValue)) {
            return;
        }

        const [addedParticipant, error] = await addOrganizationMemberAsParticipant(workflowId, {
            userId,
            participantRole,
        });

        if (addedParticipant) {
            setIsModalAddingNewParticipantOpen(false);
            fetchParticipants(true);
            toast.success(t('workflows.participants.participantSuccessfullyAdded'));
        }

        if (error) {
            toast.error(error.errors[0].getMessageTranslated(t));
        }
    };
    
    const handleRemoveParticipant = (participantId: number) => {
        const participantToRemove = participants.find((p) => p.id === participantId);

        if (!participantToRemove) return;

        setParticipants((prev) => [...prev].filter((p) => p.id !== participantId));
    };

    const handleUpdateParticipant = (updatedParticipant: Participant) => {
        setParticipants((prev) => [...prev].map((p) => {
            if (p.id !== updatedParticipant.id) return p;

            return {
                ...updatedParticipant,
            };
        }));
    };

    const handleTransferOwnership = (newOwnerId: string) => {
        setParticipants((prev) => [...prev].map((p) => {
            if (!p.userId) return p;

            if (p.userId === newOwnerId) {
                return {
                    ...p,
                    participantRole: WorkflowParticipantRole.TRANSACTION_OWNER,
                };
            }

            if (p.userId === user?.id) {
                return {
                    ...p,
                    participantRole: WorkflowParticipantRole.ADMIN,
                };
            }

            return p;
        }));
    };

    return (
        <HasPermission permissions={[Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]}>
            <DefaultLayout primaryBtn={finishBtn} secondaryBtn={goBackBtn}>
                <div className="workflow-screen--create">
                    <div className="workflow-screen--create__participants-step">
                        <h1>
                            {t('workflows.view.participants')}
                        </h1>
                        <HorizontalStepper
                            steps={CreateSellWorkflowSteps}
                            activeStep={2}
                        />
                        <div className="workflow-participants__list">
                            <InfiniteScrollWrapper
                                hasMore={!!cursor}
                                requestMore={fetchParticipants}
                                extraClasses="participant-card-list"
                            >
                                {participants.map((participant) => (
                                    <ParticipantCard
                                        key={participant.id}
                                        participant={participant}
                                        canTransferOwner={canTransferOwner}
                                        canManagePermissions={canManageOtherParticipantPermissions}
                                        onRemoveParticipant={handleRemoveParticipant}
                                        onUpdateParticipant={handleUpdateParticipant}
                                        onTransferOwnership={handleTransferOwnership}
                                    />
                                ))}
                            </InfiniteScrollWrapper>
                        </div>
                        <Button
                            variant={ButtonVariant.RectangularFilled}
                            startIcon={<AddUserIcon />}
                            extraClasses="workflow-participants__add-btn"
                            onClick={() => setIsModalAddingNewParticipantOpen(true)}
                            testId="add-participant-btn"
                        >
                            {t('workflows.participants.addParticipant')}
                        </Button>
                        <AddParticipantModal
                            onModalClose={() => setIsModalAddingNewParticipantOpen(false)}
                            open={isModalAddingNewParticipantOpen}
                            onSubmit={handleSubmitParticipant}
                            onMemberSubmit={handleSubmitParticipantMember}
                            participants={participants}
                        />
                    </div>
                </div>
            </DefaultLayout>
        </HasPermission>
    );
};

export const WorkflowParticipantsStepScreen = withWorkflowContext(withTranslationContext(WorkflowParticipantsStepScreenComponent));
