/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '../../elements/DatePicker';
import {
    CommissionType, CurrencyInfo, SellWorkflowStep, WorkflowPayload,
} from '../../../types/workflows';
import { FormValidationError } from '../../../utils/validations';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { FormField } from '../../elements/FormField';
import { ButtonVariant, DatePickerQuickButtons, FormFieldType } from '../../../types/general';
import { calculateCommission } from '../../../utils/workflows';
import { addMonthsToDate } from '../../../utils/date';
import Button from '../../elements/Button';
import { LoadingScreen } from '../LoadingScreen';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { CurrencyInput } from '../../elements/CurrencyInput';
import { FormSelectField } from '../../elements/FormSelectField';
import { formatNumberToLocale } from '../../../utils/number';
import availableCurrencies from '../../../assets/data/available-currencies.json';

interface OwnProps extends TranslationContext {
    onFormSubmit: (e: FormEvent<HTMLFormElement>, workflow: WorkflowPayload) => void;
    isLoading?: boolean;
    errorsForm: FormValidationError | null;
    workflowDefaults: WorkflowPayload;
    title?: string;
}

const WorkflowRentalInfoScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        onFormSubmit,
        isLoading = false,
        errorsForm = null,
        workflowDefaults,
        title = t('workflows.RENTAL.createWorkflow'),
    } = props;

    const navigate = useNavigate();

    const [workflow, setWorkflow] = useState<WorkflowPayload>(workflowDefaults);
    const [totalComission, setTotalComission] = useState<number>(0);
    const [descriptionLength, setDescriptionLength] = useState<number>(workflow.description.length);

    const CreateRentalWorkflowSteps = [
        t(`workflows.RENTAL.steps.${SellWorkflowStep.GENERAL_INFO}`),
        t(`workflows.RENTAL.steps.${SellWorkflowStep.PROPERTY}`),
        t(`workflows.RENTAL.steps.${SellWorkflowStep.PARTICIPANTS}`),
    ];

    const ComissionTypes = [
        {
            value: CommissionType.RENT,
            label: t('workflows.RENTAL.comissionTypes.RENT'),
        },
        {
            value: CommissionType.PERCENTAGE,
            label: t('workflows.RENTAL.comissionTypes.PERCENTAGE'),
        },
        {
            value: CommissionType.FIXED,
            label: t('workflows.RENTAL.comissionTypes.FIXED'),
        },
        {
            value: CommissionType.NONE,
            label: t('workflows.RENTAL.comissionTypes.NONE'),
        },
    ];

    const dateQuickButtons: DatePickerQuickButtons[] = [
        {
            key: 6,
            value: t('datePicker.quickButtons.6'),
        },
        {
            key: 9,
            value: t('datePicker.quickButtons.9'),
        },
        {
            key: 12,
            value: t('datePicker.quickButtons.12'),
        },
    ];

    useEffect(() => {
        setTotalComission(
            calculateCommission(
                workflow.comissionType,
                workflow.comissionValue ?? 0,
                workflow.transactionValue ?? 0,
            ),
        );
    }, [workflow]);

    /**
    * Handle Date Change Function
    *
    * @remarks
    * Handle Date Picker component date value change
    */
    const handleDateChange = (date: Date | null) => {
        if (!date || isNaN(date.getTime())) return;
        setWorkflow({
            ...workflow,
            expiresAt: date.toISOString(),
        });
    };

    /**
     * Handle Click Function
     *
     * @remarks
     * Handle Click on Date Picker quick buttons (6, 9 and 12 months)
     */
    const handleQuickButtonClick = (months: number) => {
        const updatedDate = addMonthsToDate(months);
        updatedDate.setUTCHours(23, 59, 59, 0);
        setWorkflow({
            ...workflow,
            expiresAt: updatedDate.toISOString(),
        });
    };

    const submitBtn = (
        <Button
            isSubmit
            variant={ButtonVariant.Curved}
            testId="submit-btn"
        >
            {t('workflows.RENTAL.nextBtn')}
        </Button>
    );

    const cancelBtn = (
        <Button
            onClick={() => navigate(-1)}
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            testId="cancel-btn"
        >
            {t('workflows.RENTAL.cancelBtn')}
        </Button>
    );

    /**
     * Update the workflow field change
     *
     * @remarks
     * Verify the field changed and update in workflow state
     */
    const onFieldsChange = (name: string, value: string | number) => {
        setWorkflow((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /**
    * Handle Description Change
    *
    * @remarks
    * Set description value on workflow state, count and sets description length
    */
    const handleDescription = (value: string) => {
        setDescriptionLength(value.length);
        onFieldsChange('description', value);
    };

    return (
        <form
            className="form"
            onSubmit={(e) => onFormSubmit(e, workflow)}
            data-testid="create-sell-workflow-info-form"
            autoComplete="off"
        >
            <DefaultLayout primaryBtn={submitBtn} secondaryBtn={cancelBtn}>
                <div className="workflow-screen--create">
                    {isLoading && <LoadingScreen />}
                    {!isLoading && (
                        <div className="workflow-screen--create__info-step">
                            <h1>{title}</h1>
                            <HorizontalStepper
                                steps={CreateRentalWorkflowSteps}
                                activeStep={0}
                            />
                            <h3 className="step-title">{t('workflows.RENTAL.details')}</h3>
                            <div className="form__fields">
                                <div className="value-and-id-row">
                                    <div className="externalId-wrapper">
                                        <FormField
                                            name="externalId"
                                            label={t('workflows.RENTAL.labelId')}
                                            value={workflow.externalId}
                                            onChange={onFieldsChange}
                                            errors={errorsForm}
                                            extraClasses="workflow-externalId"
                                        />
                                    </div>
                                    <CurrencyInput
                                        name="transactionValue"
                                        label={t('workflows.RENTAL.labelValue')}
                                        required
                                        errors={errorsForm}
                                        testId="currency-input"
                                        valueAmount={workflow.transactionValue ?? undefined}
                                        currencySelected={workflow.transactionCurrency}
                                        onCurrencyChange={(value) => onFieldsChange('transactionCurrency', value)}
                                        onValueAmountChange={(value) => onFieldsChange('transactionValue', value)}
                                        extraClasses="workflow-value"
                                    />

                                </div>
                                <div>
                                    <FormSelectField
                                        name="frequencyType"
                                        value={workflow.frequencyType}
                                        label={t('workflows.RENTAL.labelFrequencyType')}
                                        errors={errorsForm}
                                        options={[
                                            {
                                                value: 'MONTHLY',
                                                label: t('workflows.RENTAL.frequencyTypes.MONTHLY'),
                                            },
                                            {
                                                value: 'YEARLY',
                                                label: t('workflows.RENTAL.frequencyTypes.YEARLY'),
                                            },
                                        ]}
                                        onChange={onFieldsChange}
                                        extraClasses="workflow-frequency"
                                    />
                                </div>
                                <div className="grid-2-columns comission-type-and-value-row">
                                    <div>
                                        <FormSelectField
                                            name="comissionType"
                                            value={workflow.comissionType}
                                            label={t('workflows.RENTAL.labelComissionType')}
                                            required
                                            errors={errorsForm}
                                            options={ComissionTypes}
                                            onChange={onFieldsChange}
                                            extraClasses="workflow-comision-type"
                                        />
                                    </div>
                                    <div>
                                        <FormField
                                            name="comissionValue"
                                            type={FormFieldType.Number}
                                            value={workflow.comissionValue ?? undefined}
                                            onChange={(name, value) => onFieldsChange(name, Number(value))}
                                            label={`${t('workflows.RENTAL.labelComissionValue')} ${workflow.comissionType === CommissionType.RENT ? t('workflows.RENTAL.labelNumberOfRents') : ''}*`}
                                            errors={errorsForm}
                                            extraClasses="workflow-comision-value"
                                        />
                                    </div>
                                </div>
                                <div className="comission-value-wrapper">
                                    {`${t('workflows.RENTAL.totalComission')}: ${formatNumberToLocale(totalComission)}${(availableCurrencies as { [index: string]: CurrencyInfo })[workflow.transactionCurrency].symbol}`}
                                </div>
                                <DatePicker
                                    name="expiresAt"
                                    label={t('workflows.RENTAL.labelExpirationDate')}
                                    required
                                    format="DD/MM/YYYY"
                                    value={workflow.expiresAt ? String(workflow.expiresAt) : undefined}
                                    onChange={handleDateChange}
                                    quickButtons={dateQuickButtons}
                                    onClickButtonChange={handleQuickButtonClick}
                                    extraClasses="full-width workflow-expiresAt"
                                    errors={errorsForm}
                                />
                                <FormField
                                    name="title"
                                    value={workflow.title}
                                    label={t('workflows.RENTAL.labelName')}
                                    onChange={onFieldsChange}
                                    errors={errorsForm}
                                    extraClasses="workflow-title"
                                />
                                <label htmlFor="description">
                                    {t('workflows.RENTAL.labelDescription')}
                                </label>
                                <textarea
                                    name="description"
                                    className="description"
                                    data-testid="textarea-description"
                                    rows={2}
                                    value={workflow.description}
                                    onChange={(e) => handleDescription(e.target.value)}
                                    maxLength={4000}
                                />
                                <span className="description__max-length">{descriptionLength}/4000</span>
                                <FormField
                                    name="cmi"
                                    label={t('workflows.RENTAL.labelCMI')}
                                    value={workflow.cmi}
                                    onChange={onFieldsChange}
                                    errors={errorsForm}
                                    extraClasses="workflow-cmi"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </DefaultLayout>
        </form>
    );
};

export const WorkflowRentalInfoScreen = withTranslationContext(WorkflowRentalInfoScreenComponent);
