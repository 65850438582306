/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { OrganizationPayload } from './organizations';
import { PermissionList } from './permissions';

export interface Role {
    id: number;
    name: string;
    description: string;
    organization: OrganizationPayload;
    permissions: PermissionList;
    roleType: RoleType;
}

export enum OrganizationRoleFieldsName {
    Name = 'name',
    Description = 'description',
    OrganizationId = 'organizationId',
}

export interface RolePayload {
    name: string;
    description: string;
    organizationId: number;
}

export type Permission = string;

export type GroupedRolePermission = {
    groupedPermission: string;
    permissions: Permission[];
}
export type GroupedRolePermissions = GroupedRolePermission[];

export interface RoleMappingPayload {
    permissions: PermissionList;
}

export interface UpdateRolePayload {
    name: string;
    description: string;
}

export interface RolesMemberPayload {
    roleIds: number[];
}

export enum RoleType {
    OWNER = 'OWNER',
    BROKER = 'BROKER',
    INTERNAL_TEAM = 'INTERNAL_TEAM',
    AGENT = 'AGENT',
    LAWYER = 'LAWYER',
    SOLICITOR = 'SOLICITOR',
    CUSTOM = 'CUSTOM',
}
