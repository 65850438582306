/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

export const participantUrl = (workflowId: string, participantId?: string) => {
    const participantIdQuery = participantId ? `/${participantId}` : '';
    
    return `/transaction/${workflowId}/participant${participantIdQuery}`;
};

export const participantRoleUrl = (workflowId: string, participantId: string) => {
    return `/transaction/${workflowId}/participant/${participantId}/role`;
};

export const participantWorkflowsUrl = (params?: KeyedObject<unknown>) => {
    const queryStr = objectToParams(params);
    return `/participant/transactions${queryStr}`;
};

export const participantAssignUrl = (participantToken: string) => {
    return `/participant/${participantToken}/assign`;
};

export const participantPermissionsUrl = (workflowId: string, participantId: string) => {
    return `/transaction/${workflowId}/participant/${participantId}/permissions`;
};
